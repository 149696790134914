import React, { useState } from "react";

import RegisterForm from "components/reports/auth/RegisterForm";
import VerifyOTP from "components/reports/auth/VerifyOTP";
import SuccessPage from "components/reports/auth/SuccessPage";
import SnackBar from "utils/SnackBar";

import API from "services/api";
import StateForm from "components/reports/auth/StateForm";
import SetPasswordForm from "components/reports/auth/SetPasswordForm";

function DenominatorsRegister() {
  const [activeStep, setActiveStep] = useState(0);

  const [selectedState, setSelectedState] = useState("");
  const [registrationData, setRegistrationData] = useState();
  const [verifyCodeID, setVerifyCodeID] = useState(null);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const text = "Registration Successful!";

  const handleVerify = async () => {
    try {
      if (registrationData.emailId !== "") {
        const otpResponse = await API.generateOTP(registrationData.emailId);
        if (otpResponse.statusCode === 200) {
          setVerifyCodeID(otpResponse.data.verifyCodeId);
          setActiveStep(2);
        }
      }
    } catch (error) {}
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  switch (activeStep) {
    case 0:
      return (
        <>
          <SnackBar
            message={message?.text}
            open={show}
            handleClose={handleClose}
            type={message?.type}
          />
          <div className="container mx-auto">
            <StateForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setShow={setShow}
              setMessage={setMessage}
              onChange={handleStateChange}
              selectedState={selectedState}
            />
          </div>
        </>
      );

    case 1:
      return (
        <>
          <SnackBar
            message={message?.text}
            open={show}
            handleClose={handleClose}
            type={message?.type}
          />
          <div className="container mx-auto">
            <RegisterForm
              activeStep={activeStep}
              selectedState={selectedState}
              handleOnSubmit={handleVerify}
              registrationData={registrationData}
              setRegistrationData={setRegistrationData}
            />
          </div>
        </>
      );

    case 2:
      return (
        <VerifyOTP
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          email={registrationData.emailId}
          verifyCodeId={verifyCodeID}
          registrationData={registrationData}
          selectedState={selectedState}
          onResend={handleVerify}
        />
      );

    case 3:
      return (
        <SetPasswordForm
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          selectedState={selectedState}
          setShow={setShow}
          setMessage={setMessage}
        />
      );

    case 4:
      return <SuccessPage activeStep={activeStep} text={text} />;

    default:
  }
}

export default DenominatorsRegister;
