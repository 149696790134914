import React, { useState } from "react";

import styles from "./Register.module.css";

import { Button, TextField } from "@mui/material";

import {
  Autorenew,
  AccountCircle,
  AlternateEmailRounded,
  BadgeRounded,
  MarkEmailReadRounded,
} from "@mui/icons-material";

import ProgressSteps from "./ProgressSteps";
import TextInput from "utils/TextInput";
import PasswordInput from "utils/PasswordInput";

import InputMask from "react-input-mask";

function RegisterForm({
  activeStep,
  registrationData,
  onSubmit,
  onReset,
  onChange,
  isValid,
  isValidPhone,
  isComplete,
  isEqual,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  return (
    <div className={styles.container}>
      <div className={styles.formCard}>
        <ProgressSteps step={activeStep} />

        <form onSubmit={onSubmit} className={styles.registrationForm}>
          <div className={styles.subGroup}>
            <div className={styles.formGroup}>
              <TextInput
                label="Full Name"
                name="name"
                placeholder="Enter Full Name"
                icon={<AccountCircle style={{ color: "#069edb" }} />}
                value={registrationData.name}
                onChange={onChange}
              />
            </div>

            <div className={styles.formGroup}>
              <TextInput
                label="Email ID"
                name="email"
                placeholder="example@example.com"
                error={!isValid}
                icon={<AlternateEmailRounded style={{ color: "#069edb" }} />}
                value={registrationData.email}
                onChange={onChange}
              />
            </div>
          </div>

          <div className={styles.subGroup}>
            <div className={styles.formGroup}>
              <TextInput
                label="Employee ID"
                name="empId"
                placeholder="Enter Employee ID"
                icon={<BadgeRounded style={{ color: "#069edb" }} />}
                value={registrationData.empId}
                onChange={onChange}
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label}>
                Phone Number
                <span style={{ color: "red" }}> *</span>
              </label>
              <InputMask
                alwaysShowMask={false}
                mask="9999999999"
                maskPlaceholder={null}
                disabled={false}
                value={registrationData.phoneNumber}
                onChange={onChange}
              >
                <TextField
                  fullWidth
                  size="small"
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="e.g. 9999999999"
                  error={!isValidPhone}
                  InputProps={{
                    startAdornment: (
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/color/48/india.png"
                        alt="india"
                        style={{ marginRight: 8 }}
                      />
                    ),
                  }}
                />
              </InputMask>
            </div>
          </div>

          <div className={styles.subGroup}>
            <div className={styles.formGroup}>
              <PasswordInput
                label="Password"
                name="password"
                placeholder="Enter password"
                showPassword={showPassword}
                onShowPassword={handleClickShowPassword}
                value={registrationData.password}
                onChange={onChange}
              />
            </div>

            <div className={styles.formGroup}>
              <PasswordInput
                label="Confirm Password"
                name="confirmPassword"
                placeholder="Enter confirm password"
                showPassword={showConfirmPassword}
                onShowPassword={handleClickShowConfirmPassword}
                value={registrationData.confirmPassword}
                onChange={onChange}
                error={!isEqual}
              />
            </div>
          </div>

          <div className={styles.registrationFooter}>
            <div className={styles.buttonGroup}>
              <Button
                variant="primary"
                startIcon={<MarkEmailReadRounded />}
                onClick={onSubmit}
                disabled={!(isValid && isComplete && isValidPhone)}
                className={
                  !(isValid && isComplete && isValidPhone)
                    ? styles.disabledButton
                    : styles.button
                }
              >
                Send OTP & Verify
              </Button>

              <Button
                variant="primary"
                className={styles.button}
                startIcon={<Autorenew />}
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;
