import React from "react";
import { NavLink } from "react-router-dom";

import "./errorPage.css";
import { useEffect } from "react";
import API from "services/api";

const ErrorPage = () => {
  useEffect(() => {
    async function CountStatic() {
      try {
        await API.getCountStatic();
      } catch (error) {}
    }
    CountStatic();
  }, []);

  return (
    <>
      <div className="error-container">
        <div className="center-content">
          <NavLink to="/">
            <img
              className="error-image"
              src="https://res.cloudinary.com/daxilgrvn/image/upload/v1693561686/Project_A/Error-01_etyoyl.png"
              alt="Page not found"
            />
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
