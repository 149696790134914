import React, { useEffect, useState } from "react";
import SnackBar from "utils/SnackBar";

import styles from "./edit.module.css";

import { Button } from "@mui/material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";

import API from "services/api";
import PinField from "react-pin-field";
import { useAuth } from "context/AuthContext";

function VerifyOTP({
  editedUserDetails,
  setActiveStep,
  verifyCodeID,
  onResend,
}) {
  const { stateToken, removeStateToken } = useAuth();
  const [verificationCode, setVerificationCode] = useState("");

  const [counter, setCounter] = useState(30);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleVerificationCodeChange = (code) => {
    if (code.length <= 6) {
      setVerificationCode(code);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCounter((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }, 1000);
  }, [counter]);

  const handleVerifyOTP = async () => {
    try {
      const response = await API.verifyOTP(
        editedUserDetails.emailId,
        verificationCode,
        verifyCodeID
      );
      if (response.success) {
        const response = await API.updateStateUserPassword({
          ...editedUserDetails,
          state: stateToken,
        });

        if (response.success) {
          setShow(true);
          setMessage({
            text: response.message,
            type: "success",
          });
          setActiveStep(3);
          setTimeout(() => {
            removeStateToken();
          }, 2000);
        }
      } else {
        setShow(true);
        setMessage({
          text: "OTP not verified, please enter correct OTP!",
          type: "error",
        });
      }
    } catch (error) {}
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <div className={styles.formContainer}>
        <div className="d-flex gap-1  flex-wrap flex-row w-full">
          <b>OTP sent at: </b> <div>{editedUserDetails?.emailId}</div>
        </div>

        <form onSubmit={handleVerifyOTP} className={styles.otpForm}>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              <b>Verification Code</b>
              <span style={{ color: "red" }}> *</span>
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
                width: "100%",
              }}
            >
              <PinField
                onChange={(code) => handleVerificationCodeChange(code)}
                length={6}
                className={styles.otpInput}
              />
            </div>
          </div>

          <Button
            variant="primary"
            style={{ backgroundColor: "#069edb", color: "white" }}
            startIcon={<MarkEmailReadRoundedIcon />}
            onClick={onResend}
            disabled={counter === 0}
            className={counter === 0 ? styles.disabledButton : styles.button}
          >
            Resend OTP {counter !== 0 && `(${counter})`}
          </Button>

          <Button
            variant="primary"
            style={{ backgroundColor: "#069edb", color: "white" }}
            startIcon={<VerifiedRoundedIcon />}
            onClick={handleVerifyOTP}
            disabled={!verificationCode}
            className={
              !verificationCode ? styles.disabledButton : styles.button
            }
          >
            Verify & Edit
          </Button>
        </form>
      </div>
    </>
  );
}

export default VerifyOTP;
