import React from "react";
import styles from "styles/form-styles.module.css";
import { Button } from "@mui/material";
import { InfoRounded, AutorenewRounded } from "@mui/icons-material";

function ViewButton({ onView, onReset }) {
  return (
    <>
      <div className={styles.buttonGroup}>
        <Button
          variant="contained"
          startIcon={<InfoRounded />}
          onClick={onView}
          style={{ backgroundColor: "#105bab" }}>
          View Details
        </Button>

        <Button
          variant="contained"
          startIcon={<AutorenewRounded />}
          onClick={onReset}
          style={{ backgroundColor: "#105bab" }}>
          Reset Form
        </Button>
      </div>
    </>
  );
}

export default ViewButton;
