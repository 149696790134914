import { useAuth } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import API from "services/api";

import styles from "./denominators.module.css";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

import { AccountBoxRounded as AccountBoxRoundedIcon } from "@mui/icons-material";

import Denominators from "../raw-data/denominators/Denominators";
// import Numerator from "../raw-data/numerator/Numerator";
// import DrugRequirements from "../raw-data/drug-requirement/DrugRequirement";
import ChangePassword from "components/reports/auth/ChangePassword";
import EditUserDetails from "components/reports/auth/EditUser/EditUserDetails";
import { Link } from "react-router-dom";

const drawerWidth = 230;

const DenominatorsHome = () => {
  const { stateToken, removeStateToken } = useAuth();
  const [userDetails, setUserDetails] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [visible, setVisible] = useState(false);
  const [editFormVisible, setEditFormVisible] = useState(false);

  const [activeStep, setActiveStep] = useState(1);

  const handleShowChangePassword = () => {
    setVisible(!visible);
    setAnchorEl(null);
  };

  const toggleEditForm = () => {
    setEditFormVisible(!editFormVisible);
    setAnchorEl(null);
    setActiveStep(1);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchStateUserDetails = async () => {
      try {
        const userDetails = await API.fetchStateUserDetails(stateToken);

        setUserDetails(userDetails?.data);
      } catch (error) {}
    };

    fetchStateUserDetails();
  }, [stateToken]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CssBaseline />

        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { width: `${drawerWidth}px` },
            backgroundColor: "#069edb",
            zIndex: 1,
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              State User
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleMenu}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountBoxRoundedIcon sx={{ fontSize: 28 }} />
              </IconButton>

              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  flexGrow: 1,
                  display: "block",
                  textTransform: "capitalize",
                }}
              >
                {userDetails?.name}
              </Typography>
            </Box>
          </Toolbar>

          <Menu
            id="logout-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                removeStateToken();
              }}
            >
              Logout
            </MenuItem>

            <MenuItem onClick={handleShowChangePassword}>
              Change Password
            </MenuItem>

            <MenuItem onClick={toggleEditForm}>Edit User Details</MenuItem>
          </Menu>
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 1 }}
          aria-label="upload items"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
              zIndex: 1,
            }}
            open
          >
            <div className={styles.leftContainer}>
              <Link to="/" className={styles.image}>
                <img
                  width={60}
                  height={60}
                  src={require("assets/img/LogoAmb1.png")}
                  alt="Anemia Mukt Bharat"
                />
              </Link>
              <Divider style={{ backgroundColor: "white", width: "100%" }} />

              <span>
                <b>Name: </b>
                {userDetails?.name}
              </span>

              <Divider style={{ backgroundColor: "white", width: "100%" }} />
              <span>
                <b>Phone:</b> {userDetails?.phoneNumber}
              </span>

              <Divider style={{ backgroundColor: "white", width: "100%" }} />
              <span>
                <b>Email:</b> {userDetails?.emailId}
              </span>

              <Divider style={{ backgroundColor: "white", width: "100%" }} />
              <span>
                <b>Location:</b> {stateToken}
              </span>
            </div>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          }}
        >
          <Toolbar />
          <div className={styles.forms}>
            <div className={styles.subHeading}>Denominators</div>
            <Denominators />

            {/* <div className={styles.subHeading}>Numerators</div>
            <Numerator />

            <div className={styles.subHeading}>Drug Requirements</div>
            <DrugRequirements /> */}
          </div>
        </Box>
      </Box>
      <ChangePassword
        visible={visible}
        showChangePassword={handleShowChangePassword}
      />

      <EditUserDetails
        visible={editFormVisible}
        showEditUserDetails={toggleEditForm}
        userDetails={userDetails}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </>
  );
};

export default DenominatorsHome;
