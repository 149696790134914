import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import DMSans from "assets/fonts/DMSans-Medium.ttf";

Font.register({
  family: "DMSans",
  src: DMSans,
});

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    marginVertical: 5,
  },
  tableRow: {
    flexDirection: "row",
    fontFamily: "DMSans",
    justifyContent: "center",
    padding: 2,
  },
  tableCell: {
    width: "7.5%",
    borderColor: "#069edb",
    borderWidth: 2,
    borderRadius: 5,
    color: "#069edb",
    padding: 2,
    paddingVertical: 5,
    alignItems: "stretch",
    height: "100%",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "DMSans",
    fontSize: 10,
    backgroundColor: "#ececec50",
  },
  dataCell: {
    fontFamily: "DMSans",
    fontWeight: "bold",
    fontSize: 10,
    borderColor: "#069edb",
    color: "#069edb",
    borderWidth: 2,
    borderRadius: 5,
    marginHorizontal: 1.05,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#ececec50",
  },
  noDataCell: {
    fontFamily: "DMSans",
    fontSize: 10,
    width: "6%",
    borderWidth: 1,
    color: "white",
    marginHorizontal: 1.05,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#d3d3d3",
    borderColor: "#808080",
  },
  colorVeryLowCell: {
    fontFamily: "DMSans",
    fontSize: 10,
    width: "6%",
    borderWidth: 1,
    color: "white",
    marginHorizontal: 1.05,
    textAlign: "center",
    justifyContent: "center",
    borderColor: "#105bab",
    backgroundColor: "#105babd5",
  },
  colorLowCell: {
    fontFamily: "DMSans",
    fontSize: 10,
    width: "6%",
    borderWidth: 1,
    color: "white",
    marginHorizontal: 1.05,
    textAlign: "center",
    justifyContent: "center",
    borderColor: "#105bab",
    backgroundColor: "#40c8f4",
  },
  colorMediumCell: {
    fontFamily: "DMSans",
    fontSize: 10,
    width: "6%",
    borderWidth: 1,
    color: "black",
    marginHorizontal: 1.05,
    textAlign: "center",
    justifyContent: "center",
    borderColor: "#fdd225",
    backgroundColor: "#ffe06a",
  },
  colorHighCell: {
    fontFamily: "DMSans",
    fontSize: 10,
    width: "6%",
    borderWidth: 1,
    color: "white",
    marginHorizontal: 1.05,
    textAlign: "center",
    justifyContent: "center",
    borderColor: "#228a05",
    backgroundColor: "#60bb46",
  },
  colorNormalizedCell: {
    fontFamily: "DMSans",
    fontSize: 10,
    width: "6%",
    borderWidth: 1,
    color: "white",
    marginHorizontal: 1.05,
    textAlign: "center",
    justifyContent: "center",
    borderColor: "#222222",
    backgroundColor: "#000000",
  },
});

function MonthlyTable({ stocksData }) {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[styles.tableCell, { width: 75 }]}>
          <Text>Indicators</Text>
        </View>

        <View style={[styles.tableCell, { width: 75 }]}>
          <Text>Year</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Apr</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>May</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Jun</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Jul</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Aug</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Sept</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Oct</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Nov</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Dec</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Jan</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Feb</Text>
        </View>

        <View style={styles.tableCell}>
          <Text>Mar</Text>
        </View>
      </View>

      {stocksData?.map((data) => {
        return (
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, { width: 62 }]}>
              <Text>{data.indicator}</Text>
            </View>
            <View style={[styles.dataCell, { width: 62 }]}>
              <Text>{data.year} </Text>
            </View>
            {data?.months?.map((month) => {
              let colorClass;
              if (!month?.value) {
                colorClass = "noDataCell";
              } else if (month?.value > 0 && month?.value < 25) {
                colorClass = "colorVeryLowCell";
              } else if (month?.value >= 25 && month?.value < 50) {
                colorClass = "colorLowCell";
              } else if (month?.value >= 50 && month?.value < 75) {
                colorClass = "colorMediumCell";
              } else if (month?.value >= 75) {
                colorClass = "colorHighCell";
              }
              return (
                <View
                  style={[
                    styles[colorClass],
                    {
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  ]}
                >
                  <Text key={month.index}>{month?.value}</Text>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}

export default MonthlyTable;
