import React, { useEffect, useState } from "react";

import API from "services/api";
import "pages/resources/Resources.css";
import Loader from "utils/Loader";

export default function LongMenu({ setCategory }) {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = (category) => {
    setCategory(category);
  };

  useEffect(() => {
    async function fetchCategories() {
      try {
        setLoading(true);
        const categories = await API.getAllCategories();

        if (categories) {
          setCategoryOptions(categories);
          setCategory("Operational Guideline" || categories[0]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    fetchCategories();
  }, [setCategory]);

  return (
    <>
      <div className="module-text">
        <h4 className="module-title">CATEGORIES</h4>

        <div id="resourceMenu">
          {loading ? (
            <Loader loading={loading} />
          ) : (
            categoryOptions?.map((category) => {
              return (
                <a
                  key={category}
                  className="nav-link__resources"
                  href={`#${category}`}
                  onClick={() => handleClick(category)}
                >
                  <span className="bullet">•</span> {category}
                </a>
              );
            })
          )}
        </div>

        <p>&nbsp;</p>
      </div>
    </>
  );
}
