import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

function SelectYear({ onYearChange, ...props }) {
  return (
    <>
      <DatePicker
        defaultValue={dayjs()}
        views={["year"]}
        onChange={(newValue) => onYearChange(newValue)}
        minDate={dayjs("2017")}
        maxDate={dayjs()}
        {...props}
      />
    </>
  );
}

export default SelectYear;
