import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import styles from "./countAdmin.module.css";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const CountAdminTables = ({ indiaStatesData, otherCountriesData, loading }) => {
  const columnsIndia = [
    { field: "state", headerName: "State", width: 300 },
    {
      field: "allCount",
      headerName: "All Count",
      width: 150,
    },
    {
      field: "staticCount",
      headerName: "Static Count",
      width: 150,
    },
    {
      field: "adminCount",
      headerName: "Admin Count",
      width: 150,
    },
    {
      field: "downloadCount",
      headerName: "Download Count",
      width: 150,
    },
    {
      field: "rawDataCount",
      headerName: "Raw Data Count",
      width: 150,
    },
    {
      field: "kpiCount",
      headerName: "KPI Count",
      width: 150,
    },
    {
      field: "rankingCount",
      headerName: "Ranking Count",
      width: 150,
    },
    {
      field: "stockCount",
      headerName: "Stock Count",
      width: 150,
    },
    {
      field: "mnCount",
      headerName: "Maternal Nutrition Count",
      width: 200,
    },
  ];

  const columnsOtherCountries = [
    { field: "country", headerName: "Country", width: 200 },
    { field: "state", headerName: "State", width: 200 },
    {
      field: "allCount",
      headerName: "All Count",
      width: 150,
    },
    {
      field: "staticCount",
      headerName: "Static Count",
      width: 150,
    },
    {
      field: "adminCount",
      headerName: "Admin Count",
      width: 150,
    },
    {
      field: "downloadCount",
      headerName: "Download Count",
      width: 150,
    },
    {
      field: "rawDataCount",
      headerName: "Raw Data Count",
      width: 150,
    },
    {
      field: "kpiCount",
      headerName: "KPI Count",
      width: 150,
    },
    {
      field: "rankingCount",
      headerName: "Ranking Count",
      width: 150,
    },
    {
      field: "stockCount",
      headerName: "Stock Count",
      width: 150,
    },
    {
      field: "mnCount",
      headerName: "Maternal Nutrition Count",
      width: 200,
    },
  ];

  return (
    <div className={styles.tables}>
      <div className={styles.tableContainer}>
        <h3 className={styles.heading}>India State-wise Data</h3>
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          loading={loading}
          rows={indiaStatesData}
          columns={columnsIndia}
          pageSize={10}
          slots={{
            toolbar: CustomToolbar,
          }}
          isRowSelectable={false}
        />
      </div>

      <div className={styles.tableContainer}>
        <h3 className={styles.heading}>Other Countries Overall Data</h3>
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          loading={loading}
          rows={otherCountriesData}
          columns={columnsOtherCountries}
          pageSize={10}
          slots={{
            toolbar: CustomToolbar,
          }}
          isRowSelectable={false}
        />
      </div>
    </div>
  );
};

export default CountAdminTables;
