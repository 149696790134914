import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

function SelectMonthYear({ onDateChange, ...props }) {
  return (
    <DatePicker
      sx={{ width: "100%" }}
      views={["month", "year"]}
      onChange={(newValue) => onDateChange(newValue)}
      minDate={dayjs("2017-01")}
      maxDate={dayjs()}
      {...props}
    />
  );
}

export default SelectMonthYear;
