const currentYear = new Date().getFullYear();
const currentYears = [];

for (let year = currentYear + 1; year >= 2018; year--) {
  const governmentYearLabel = `${year - 1}-${year}`;
  const governmentYear = {
    id: currentYear - year + 1,
    label: governmentYearLabel,
    value: year - 1,
  };
  currentYears.push(governmentYear);
}

export const years = currentYears;

export const Categories = [
  {
    id: 1,
    category: { name: "Picture", extensions: "jpg, png, gif" },
  },
  { id: 2, category: { name: "Video", extensions: "mp4, mov, avi" } },
  { id: 3, category: { name: "Audio", extensions: "mp3, wav, flac" } },
  {
    id: 4,
    category: { name: "Document", extensions: "doc, docx, pdf, txt" },
  },
  { id: 5, category: { name: "Presentation", extensions: "ppt, pptx" } },
  {
    id: 6,
    category: { name: "Spreadsheet", extensions: "xls, xlsx, csv" },
  },
  { id: 7, category: { name: "Archive", extensions: "zip, rar, tar" } },
  {
    id: 8,
    category: { name: "Code", extensions: "js, java, py, html, css" },
  },
  { id: 9, category: { name: "Other", extensions: "" } },
];

export const QUARTERS = [
  {
    label: "Quarter-1 (Apr-Jun)",
    value: "1",
  },
  {
    label: "Quarter-2 (Jul-Sep)",
    value: "2",
  },
  {
    label: "Quarter-3 (Oct-Dec)",
    value: "3",
  },
  {
    label: "Quarter-4 (Jan-Mar)",
    value: "4",
  },
];

export const KPI_DATA_NAMES = [
  {
    key: "months6_59IfaPercentO",
    value: "% of children 6-59 months provided 8-10 doses (1ml) of IFA syrup",
    pdfName: "Children (6-59 months)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 01.jpg"),
  },

  {
    key: "years6_9IfaPercentP",
    value:
      "% of children covered under WIFS JUNIOR (5-9 years) provided 4-5 Pink IFA tablets (In schools + out of school)",
    pdfName: "Children (5-9 yrs)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 02.jpg"),
  },
  {
    key: "adols10_15IfaPercentH",
    value:
      "% of Adolescents (10-19 Years) provided 4 Blue IFA tablets in schools",
    pdfName: "Adolescents (10-19 years)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 03.png"),
  },
  {
    key: "pregWomenIfaPercentC",
    value: "% of pregnant women (PW) given 180 Red IFA tablets",
    pdfName: "Pregnant Women",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 05.jpg"),
  },
  {
    key: "motherIfaPercentF",
    value: "% of lactating mothers given Red IFA tablets",
    pdfName: "Lactating Mothers",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 06.jpg"),
  },
];

export const RANKING_DATA_NAMES_STATE = [
  {
    key: "months6_59IfaPercentO",
    value: "HMIS: 9.9-\nPercentage of children (6-59 months)",
    pdfName: "Children (6-59 months)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 01.jpg"),
  },
  {
    key: "years6_9IfaPercentP",
    value: "HMIS: 23.1 & 23.3-\nPercentage of Children (6-9 yrs)",
    pdfName: "Children (5-9 yrs)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 02.jpg"),
  },
  {
    key: "adols10_15IfaPercentH",
    value: "HMIS: 22.1.1 & 22.1.3-\nPercentage of adolescents (10-19 years)",
    pdfName: "Adolescents (10-19 years)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 03.png"),
  },
  {
    key: "pregWomenIfaPercentC",
    value: "HMIS: 1.2.4-\nPercentage of Pregnant Women",
    pdfName: "Pregnant Women",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 05.jpg"),
  },
  {
    key: "motherIfaPercentF",
    value: "HMIS: 6.3-\nPercentage of mothers",
    pdfName: "Lactating Mothers",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 06.jpg"),
  },
  {
    key: "indexValue",
    value: "Index Value (%)",
    pdfName: "AMB Index",
  },
  { key: "rankDist", value: "Rank", pdfName: "Rank" },
];

export const RANKING_DATA_NAMES_COUNTRY = [
  {
    key: "months6_59IfaPercentO",
    value: "HMIS: 9.9-\nPercentage of children (6-59 months)",
    pdfName: "Children (6-59 months)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 01.jpg"),
  },
  {
    key: "years6_9IfaPercentP",
    value: "HMIS: 23.1 & 23.3-\nPercentage of Children (6-9 yrs)",
    pdfName: "Children (5-9 yrs)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 02.jpg"),
  },
  {
    key: "adols10_15IfaPercentH",
    value: "HMIS: 22.1.1 & 22.1.3-\nPercentage of adolescents (10-19 years)",
    pdfName: "Adolescents (10-19 years)",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 03.png"),
  },
  {
    key: "pregWomenIfaPercentC",
    value: "HMIS: 1.2.4-\nPercentage of Pregnant Women",
    pdfName: "Pregnant Women",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 05.jpg"),
  },
  {
    key: "motherIfaPercentF",
    value: "HMIS: 6.3-\nPercentage of lactating mothers",
    pdfName: "Lactating Mothers",
    imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Benf 06.jpg"),
  },
  {
    key: "indexValue",
    value: "Index Value (%)",
    pdfName: "AMB Index",
  },
  { key: "rankState", value: "Rank", pdfName: "Rank" },
];

export const RANKING_DATA_NAMES_BAR = [
  {
    key: "months6_59IfaPercentO",
    value: "HMIS: 9.9-Pecentage of children (6-59 months)",
  },
  {
    key: "years6_9IfaPercentP",
    value: "HMIS: 23.1+23.3-Pecentage of Children (6-9 yrs)",
  },
  {
    key: "adols10_15IfaPercentH",
    value: "HMIS: 22.1.1+22.1.3-Pecentage of adolescents (10-19 years)",
  },
  {
    key: "pregWomenIfaPercentC",
    value: "HMIS: 1.2.4-Pecentage of Pregnant Women",
  },
  {
    key: "motherIfaPercentF",
    value: "HMIS: 6.3-Pecentage of mothers",
  },
  {
    key: "indexValue",
    value: "Index Value (%)",
  },
  { key: "rankState", value: "Rank" },
];

export const RANKING_CUMULATIVE_DATA_NAMES = [
  {
    key: "months6_59IfaPercentO",
    value: "HMIS: 9.9-Pecentage of children (6-59 months)",
  },
  {
    key: "years6_9IfaPercentP",
    value: "HMIS: 23.1+23.3-Pecentage of Children (6-9 yrs)",
  },

  {
    key: "adols10_15IfaPercentH",
    value: "HMIS: 22.1.1+22.1.3-Pecentage of adolescents (10-19 years)",
  },
  {
    key: "pregWomenIfaPercentC",
    value: "HMIS: 1.2.4-Pecentage of Pregnant Women",
  },

  {
    key: "motherIfaPercentF",
    value: "HMIS: 6.3-Pecentage of mothers",
  },
  {
    key: "indexValue",
    value: "Index Value (%)",
  },
  { key: "rankState", value: "State Rank" },
  { key: "rankDist", value: "District Rank" },
];

export const MAP_DATA = [
  {
    id: 1,
    code: "IND",
    name: "INDIA",
    mapName: "India",
  },
  {
    id: 2,
    code: "IND035",
    name: "ANDAMAN AND NICOBAR ISLANDS",
    codeMap: "IN-AN",
    mapName: "Andaman & Nicobar Island",
  },
  {
    id: 3,
    code: "IND028",
    name: "ANDHRA PRADESH",
    codeMap: "IN-AP",
    mapName: "Andhra Pradesh",
  },
  {
    id: 4,
    code: "IND012",
    name: "ARUNACHAL PRADESH",
    codeMap: "IN-AR",
    mapName: "Arunachal Pradesh",
  },
  {
    id: 5,
    code: "IND018",
    name: "ASSAM",
    codeMap: "IN-AS",
    mapName: "Assam",
  },
  {
    id: 6,
    code: "IND010",
    name: "BIHAR",
    codeMap: "IN-BR",
    mapName: "Bihar",
  },
  {
    id: 7,
    code: "IND004",
    name: "CHANDIGARH",
    codeMap: "IN-CH",
    mapName: "Chandigarh",
  },
  {
    id: 8,
    code: "IND022",
    name: "CHHATTISGARH",
    codeMap: "IN-CT",
    mapName: "Chhattisgarh",
  },
  {
    id: 9,
    code: "IND038",
    name: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
    codeMap: "IN-DN",
    mapName: "Dadara & Nagar Haveli",
  },
  {
    id: 10,
    code: "IND007",
    name: "DELHI",
    codeMap: "IN-DL",
    mapName: "Delhi",
  },
  {
    id: 11,
    code: "IND030",
    name: "GOA",
    codeMap: "IN-GA",
    mapName: "Goa",
  },
  {
    id: 12,
    code: "IND024",
    name: "GUJARAT",
    codeMap: "IN-GJ",
    mapName: "Gujarat",
  },
  {
    id: 13,
    code: "IND006",
    name: "HARYANA",
    codeMap: "IN-HR",
    mapName: "Haryana",
  },
  {
    id: 14,
    code: "IND002",
    name: "HIMACHAL PRADESH",
    codeMap: "IN-HP",
    mapName: "Himachal Pradesh",
  },
  {
    id: 15,
    code: "IND001",
    name: "JAMMU AND KASHMIR",
    codeMap: "IN-JK",
    mapName: "Jammu & Kashmir",
  },
  {
    id: 16,
    code: "IND020",
    name: "JHARKHAND",
    codeMap: "IN-JH",
    mapName: "Jharkhand",
  },
  {
    id: 17,
    code: "IND029",
    name: "KARNATAKA",
    codeMap: "IN-KA",
    mapName: "Karnataka",
  },
  {
    id: 18,
    code: "IND032",
    name: "KERALA",
    codeMap: "IN-KL",
    mapName: "Kerala",
  },
  {
    id: 19,
    code: "IND037",
    name: "LADAKH",
    codeMap: "IN-LA",
    mapName: "Ladakh",
  },
  {
    id: 20,
    code: "IND031",
    name: "LAKSHADWEEP",
    codeMap: "IN-LD",
    mapName: "Lakshadweep",
  },
  {
    id: 21,
    code: "IND023",
    name: "MADHYA PRADESH",
    codeMap: "IN-MP",
    mapName: "Madhya Pradesh",
  },
  {
    id: 22,
    code: "IND027",
    name: "MAHARASHTRA",
    codeMap: "IN-MH",
    mapName: "Maharashtra",
  },
  {
    id: 23,
    code: "IND014",
    name: "MANIPUR",
    codeMap: "IN-MN",
    mapName: "Manipur",
  },
  {
    id: 24,
    code: "IND017",
    name: "MEGHALAYA",
    codeMap: "IN-ML",
    mapName: "Meghalaya",
  },
  {
    id: 25,
    code: "IND015",
    name: "MIZORAM",
    codeMap: "IN-MZ",
    mapName: "Mizoram",
  },
  {
    id: 26,
    code: "IND013",
    name: "NAGALAND",
    codeMap: "IN-NL",
    mapName: "Nagaland",
  },
  {
    id: 27,
    code: "IND021",
    name: "ODISHA",
    codeMap: "IN-OR",
    mapName: "Odisha",
  },
  {
    id: 28,
    code: "IND034",
    name: "PUDUCHERRY",
    codeMap: "IN-PY",
    mapName: "Puducherry",
  },
  {
    id: 29,
    code: "IND003",
    name: "PUNJAB",
    codeMap: "IN-PB",
    mapName: "Punjab",
  },
  {
    id: 30,
    code: "IND008",
    name: "RAJASTHAN",
    codeMap: "IN-RJ",
    mapName: "Rajasthan",
  },
  {
    id: 31,
    code: "IND011",
    name: "SIKKIM",
    codeMap: "IN-SK",
    mapName: "Sikkim",
  },
  {
    id: 32,
    code: "IND033",
    name: "TAMIL NADU",
    codeMap: "IN-TN",
    mapName: "Tamil Nadu",
  },
  {
    id: 33,
    code: "IND036",
    name: "TELANGANA",
    mapName: "Telangana",
  },
  {
    id: 34,
    code: "IND016",
    name: "TRIPURA",
    codeMap: "IN-TR",
    mapName: "Tripura",
  },
  {
    id: 35,
    code: "IND009",
    name: "UTTAR PRADESH",
    codeMap: "IN-UP",
    mapName: "Uttar Pradesh",
  },
  {
    id: 36,
    code: "IND005",
    name: "UTTARAKHAND",
    codeMap: "IN-UT",
    mapName: "Uttarakhand",
  },
  {
    id: 37,
    code: "IND019",
    name: "WEST BENGAL",
    codeMap: "IN-WB",
    mapName: "West Bengal",
  },
  {
    id: 38,
    code: "IND0100",
    name: "DADRA AND NAGAR HAVELI DISTRICT",
    codeMap: "IN-DN",
    mapName: "Dadara & Nagar Haveli District",
  },
  {
    id: 39,
    code: "IND0101",
    name: "DAMAN DISTRICT",
    codeMap: "IN-DN",
    mapName: "DAMAN DISTRICT",
  },
  {
    id: 40,
    code: "IND0102",
    name: "DIU DISTRICT",
    codeMap: "IN-DN",
    mapName: "DIU DISTRICT",
  },
  {
    id: 41,
    code: "IND0103",
    name: "DIU DISTRICT",
    codeMap: "IN-DN",
    mapName: "DIU DISTRICT",
  },
  {
    id: 42,
    code: "IND0104",
    name: "KARGIL DISTRICT",
    codeMap: "IN-DN",
    mapName: "KARGIL DISTRICT",
  },
  {
    id: 43,
    code: "IND0105",
    name: "LEH LADAKH DISTRICT",
    codeMap: "IN-DN",
    mapName: "LEH LADAKH DISTRICT",
  },
];

export const SituationIndicatorsIndia = [
  { indicatorKey: "child6_59", indicatorValue: "Children 6-59 Months" },
  { indicatorKey: "nonPreg15_49", indicatorValue: "Non-Pregnant 15-49 Years" },
  { indicatorKey: "preg15_59", indicatorValue: "Pregnant Women" },
  { indicatorKey: "allWomen15_49", indicatorValue: "All Women 15-49 Years" },
  { indicatorKey: "allWomen15_19", indicatorValue: "All Women 15-19 Years" },
  { indicatorKey: "men15_49", indicatorValue: "All Men 15-49 Years" },
  { indicatorKey: "men15_19", indicatorValue: "All Men 15-19 Years" },
];

export const SituationIndicatorsGlobal = [
  { indicatorKey: "child6_59", indicatorValue: "Children 6-59 Months" },
  { indicatorKey: "nonPreg15_49", indicatorValue: "Non-Pregnant 15-49 Years" },
  { indicatorKey: "preg15_59", indicatorValue: "Pregnant Women" },
  { indicatorKey: "allWomen15_49", indicatorValue: "All Women 15-49 Years" },
];

export const NFHSs = [{ key: "5", value: "NHFS-5 (2019-21)" }];

export const country = [
  {
    id: 1,
    code: "IND",
    name: "INDIA",
    mapName: "India",
  },
];
export const states = [
  {
    id: 3,
    code: "IND028",
    name: "ANDHRA PRADESH",
    codeMap: "IN-AP",
    mapName: "Andhra Pradesh",
  },
  {
    id: 4,
    code: "IND012",
    name: "ARUNACHAL PRADESH",
    codeMap: "IN-AR",
    mapName: "Arunachal Pradesh",
  },
  {
    id: 5,
    code: "IND018",
    name: "ASSAM",
    codeMap: "IN-AS",
    mapName: "Assam",
  },
  {
    id: 6,
    code: "IND010",
    name: "BIHAR",
    codeMap: "IN-BR",
    mapName: "Bihar",
  },

  {
    id: 8,
    code: "IND022",
    name: "CHHATTISGARH",
    codeMap: "IN-CT",
    mapName: "Chhattisgarh",
  },

  { id: 11, code: "IND030", name: "GOA", codeMap: "IN-GA", mapName: "Goa" },
  {
    id: 12,
    code: "IND024",
    name: "GUJARAT",
    codeMap: "IN-GJ",
    mapName: "Gujarat",
  },
  {
    id: 13,
    code: "IND006",
    name: "HARYANA",
    codeMap: "IN-HR",
    mapName: "Haryana",
  },
  {
    id: 14,
    code: "IND002",
    name: "HIMACHAL PRADESH",
    codeMap: "IN-HP",
    mapName: "Himachal Pradesh",
  },
  {
    id: 16,
    code: "IND020",
    name: "JHARKHAND",
    codeMap: "IN-JH",
    mapName: "Jharkhand",
  },
  {
    id: 17,
    code: "IND029",
    name: "KARNATAKA",
    codeMap: "IN-KA",
    mapName: "Karnataka",
  },
  {
    id: 18,
    code: "IND032",
    name: "KERALA",
    codeMap: "IN-KL",
    mapName: "Kerala",
  },

  {
    id: 21,
    code: "IND023",
    name: "MADHYA PRADESH",
    codeMap: "IN-MP",
    mapName: "Madhya Pradesh",
  },
  {
    id: 22,
    code: "IND027",
    name: "MAHARASHTRA",
    codeMap: "IN-MH",
    mapName: "Maharashtra",
  },
  {
    id: 23,
    code: "IND014",
    name: "MANIPUR",
    codeMap: "IN-MN",
    mapName: "Manipur",
  },
  {
    id: 24,
    code: "IND017",
    name: "MEGHALAYA",
    codeMap: "IN-ML",
    mapName: "Meghalaya",
  },
  {
    id: 25,
    code: "IND015",
    name: "MIZORAM",
    codeMap: "IN-MZ",
    mapName: "Mizoram",
  },
  {
    id: 26,
    code: "IND013",
    name: "NAGALAND",
    codeMap: "IN-NL",
    mapName: "Nagaland",
  },
  {
    id: 27,
    code: "IND021",
    name: "ODISHA",
    codeMap: "IN-OR",
    mapName: "Odisha",
  },

  {
    id: 29,
    code: "IND003",
    name: "PUNJAB",
    codeMap: "IN-PB",
    mapName: "Punjab",
  },
  {
    id: 30,
    code: "IND008",
    name: "RAJASTHAN",
    codeMap: "IN-RJ",
    mapName: "Rajasthan",
  },
  {
    id: 31,
    code: "IND011",
    name: "SIKKIM",
    codeMap: "IN-SK",
    mapName: "Sikkim",
  },
  {
    id: 32,
    code: "IND033",
    name: "TAMIL NADU",
    codeMap: "IN-TN",
    mapName: "Tamil Nadu",
  },
  {
    id: 33,
    code: "IND036",
    name: "TELANGANA",
    mapName: "Telangana",
  },
  {
    id: 34,
    code: "IND016",
    name: "TRIPURA",
    codeMap: "IN-TR",
    mapName: "Tripura",
  },
  {
    id: 35,
    code: "IND009",
    name: "UTTAR PRADESH",
    codeMap: "IN-UP",
    mapName: "Uttar Pradesh",
  },
  {
    id: 36,
    code: "IND005",
    name: "UTTARAKHAND",
    codeMap: "IN-UT",
    mapName: "Uttarakhand",
  },
  {
    id: 37,
    code: "IND019",
    name: "WEST BENGAL",
    codeMap: "IN-WB",
    mapName: "West Bengal",
  },
];

export const unionTerritories = [
  {
    id: 2,
    code: "IND035",
    name: "ANDAMAN AND NICOBAR ISLANDS",
    codeMap: "IN-AN",
    mapName: "Andaman & Nicobar Island",
  },
  {
    id: 4,
    code: "IND004",
    name: "CHANDIGARH",
    codeMap: "IN-CH",
    mapName: "Chandigarh",
  },
  {
    id: 7,
    code: "IND010",
    name: "DELHI",
    codeMap: "IN-DL",
    mapName: "Delhi",
  },
  {
    id: 35,
    code: "IND035",
    name: "DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
    codeMap: "IN-DN",
    mapName: "Puducherry",
  },

  {
    id: 15,
    code: "IND001",
    name: "JAMMU AND KASHMIR",
    codeMap: "IN-JK",
    mapName: "Jammu & Kashmir",
  },
  {
    id: 26,
    code: "IND002",
    name: "LADAKH",
    codeMap: "IN-LA",
    mapName: "Ladakh",
  },
  {
    id: 29,
    code: "IND034",
    name: "PUDUCHERRY",
    codeMap: "IN-PY",
    mapName: "Puducherry",
  },
];

export const districts = [
  {
    id: 38,
    code: "IND0100",
    name: "DADRA AND NAGAR HAVELI DISTRICT",
    codeMap: "IN-DN",
    mapName: "Dadara & Nagar Haveli District",
  },
  {
    id: 39,
    code: "IND0101",
    name: "DAMAN DISTRICT",
    codeMap: "IN-DN",
    mapName: "DAMAN DISTRICT",
  },
  {
    id: 40,
    code: "IND0102",
    name: "DIU DISTRICT",
    codeMap: "IN-DN",
    mapName: "DIU DISTRICT",
  },

  {
    id: 42,
    code: "IND0104",
    name: "KARGIL DISTRICT",
    codeMap: "IN-LA",
    mapName: "KARGIL DISTRICT",
  },
  {
    id: 43,
    code: "IND0105",
    name: "LEH LADAKH DISTRICT",
    codeMap: "IN-LA",
    mapName: "LEH LADAKH DISTRICT",
  },
];

export const indicators = [
  {
    id: 1,
    indicator: "HMIS 19.6: IFA Red (Adult)",
  },
  {
    id: 2,
    indicator: "HMIS 19.7: IFA Blue (10-19 yr)",
  },
  {
    id: 3,
    indicator: "HMIS 19.8: IFA Pink (6-10 yr)",
  },
  {
    id: 4,
    indicator: "HMIS 19.9: IFA Syrup (Paediatric)",
  },
  {
    id: 5,
    indicator: "HMIS 19.15: Albendazole Tablets",
  },
];

export const menuItems = [
  {
    label: "Upload Resources",
    path: "/admin/upload-resources",
  },
  {
    label: "Upload Denominator",
    path: "/admin/upload-denominators",
  },
  {
    label: "Upload Numerator",
    path: "/admin/upload-numerator",
  },
  {
    label: "Upload Drug Requirements",
    path: "/admin/upload-drug-requirements",
  },
  {
    label: "Delete Uploaded Excel Sheets",
    path: "/admin/delete-uploads",
  },
  {
    label: "Count Admin",
    path: "/admin/count-admin",
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const quarters = [
  "April - June",
  "July - September",
  "October - December",
  "January - March",
];
