import React, { useEffect, useState } from "react";
import styles from "./QueryFormQuarterly.module.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import SelectYearLabel from "utils/SelectYearLabel";
import SelectLocation from "utils/SelectLocation";
import SelectDistrict from "utils/SelectDistrict";
import ViewButton from "utils/ViewButton";

import API from "services/api";

function QueryFormQuarterly({ setQueryData, setShow, setMessage }) {
  const [selectedYear, setSelectedYear] = useState("");

  const [stateList, setStateList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("INDIA");

  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const handleReset = () => {
    setSelectedYear("");
    setSelectedLocation("");
    setSelectedDistrict("");
  };

  const handleLabelYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  useEffect(() => {
    const getStateList = async () => {
      try {
        const states = await API.getAllStates(selectedYear);

        setStateList(states);
      } catch (error) {}
    };
    getStateList();
  }, [selectedYear]);

  const handleDistrictChange = (e) => {
    setSelectedDistrict(e?.target.value);
  };

  const handleStateChange = async (e) => {
    setSelectedLocation(e?.target.value);
    try {
      const responseDistricts = await API.getAllDistrictsByState(
        e?.target.value,
        selectedYear
      );
      const responseData = await responseDistricts.data;
      if (Object.keys(responseData).length !== 0) {
        setDistrictList(responseData);
      } else {
        setDistrictList([]);
      }
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedDistrict && selectedDistrict !== "") {
      setQueryData({
        location: selectedDistrict,
        selectedState: selectedLocation,
        selectedDistrict: selectedDistrict,
        startYear: selectedYear,
      });
    } else {
      setQueryData({
        location: selectedLocation,
        startYear: selectedYear,
      });
    }

    setShow(true);
    setMessage({
      text: "Form Submitted! Loading the data...",
      type: "success",
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        <div className={styles.formGroupContainer}>
          <div className={styles.formGroup}>
            <label htmlFor="year" className={styles.formLabel}>
              Select Year
            </label>

            <SelectYearLabel
              onYearLabelChange={handleLabelYearChange}
              selectedYearLabel={selectedYear}
            />
          </div>

          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Select Location</label>

            <SelectLocation
              stateList={stateList}
              selectedState={selectedLocation}
              onLocationChange={handleStateChange}
            />
          </div>

          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Select District</label>

            <SelectDistrict
              districtList={districtList}
              selectedDistrict={selectedDistrict}
              onLocationChange={handleDistrictChange}
            />
          </div>
        </div>

        <div className={styles.centerRow}>
          <ViewButton onView={handleSubmit} onReset={handleReset} />
        </div>
      </form>
    </LocalizationProvider>
  );
}

export default QueryFormQuarterly;
