import React, { useState } from "react";
import styles from "./ContactQueryCard.module.css";
import * as Yup from "yup";
import { Button, MenuItem, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import API from "services/api";
import SnackBar from "utils/SnackBar";

const ContactQueryCard = () => {
  const validationSchema = Yup.object({
    queryType: Yup.string().trim().required("Please select one"),
    name: Yup.string().trim().required(" Name is required"),
    email: Yup.string()
      .trim()
      .email("Invalid email address")
      .required(" Email is required"),
    message: Yup.string().trim().required("Message is required"),
  });

  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState({ text: "", type: "" });

  const handleFormSubmit = async (values) => {
    try {
      await API.contactUs(values);

      setShow(true);
      setModalText({
        text: "Query sent successfully!",
        type: "success",
      });
      setShow(false);
    } catch (error) {}
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <SnackBar
        message={modalText.text}
        open={show}
        handleClose={handleClose}
        type={modalText.type}
      />

      <div className={styles.container}>
        <h4 className={styles.heading}>If any queries</h4>

        <Formik
          initialValues={{
            queryType: "2",
            name: "",
            email: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <Form className={styles.form} autoComplete="off">
              <div className={styles.row}>
                <div className={styles.formGroup}>
                  <label htmlFor="queryType" className={styles.label}>
                    Query Type <span className={styles.requiredStar}>*</span>
                  </label>
                  <TextField
                    select
                    size="small"
                    name="queryType"
                    id="queryType"
                    required
                    value={values.queryType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.queryType && Boolean(errors.queryType)}
                    helperText={touched.queryType && errors.queryType}
                    className={styles.formInput}
                  >
                    <MenuItem disabled value="">
                      Please select one
                    </MenuItem>
                    <MenuItem value="1">Programme Related</MenuItem>
                    <MenuItem value="2">Dashboard Related</MenuItem>
                  </TextField>
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.label} htmlFor="name">
                    Name <span className={styles.requiredStar}>*</span>
                  </label>
                  <TextField
                    size="small"
                    className={styles.formInput}
                    type="text"
                    name="name"
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    required
                  />
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.label} htmlFor="email">
                    Email <span className={styles.requiredStar}>*</span>
                  </label>
                  <TextField
                    size="small"
                    className={styles.formInput}
                    type="email"
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    required
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.formGroup}>
                  <label className={styles.label} htmlFor="message">
                    Message <span className={styles.requiredStar}>*</span>
                  </label>
                  <TextField
                    size="small"
                    multiline
                    maxRows={10}
                    className={styles.formInput}
                    name="message"
                    id="message"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    required
                  />
                </div>
              </div>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit Query
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ContactQueryCard;
