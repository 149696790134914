import React from "react";
import styles from "components/reports/DataQueryForm.module.css";
import { Select, MenuItem } from "@mui/material";
import { QUARTERS } from "constants";

function SelectQuarter({ selectedQuarter, onQuarterChange }) {
  return (
    <>
      <Select
        fullWidth
        type="text"
        className={styles["form-input"]}
        name="quarter"
        id="quarter"
        value={selectedQuarter}
        onChange={onQuarterChange}
        inputProps={{ "aria-label": "Without label" }}
        displayEmpty
        required
      >
        <MenuItem disabled value="">
          Select Quarter
        </MenuItem>
        {QUARTERS.map((quarter, index) => (
          <MenuItem className="dropdown-item" key={index} value={quarter.value}>
            {quarter.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default SelectQuarter;
