import React, { useEffect } from "react";
import "./App.css";
import Home from "./pages/homepage/Home";

import {
  LinearScale,
  CategoryScale,
  PointElement,
  LineController,
  LineElement,
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  Filler,
} from "chart.js";

import API from "services/api";

ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  Filler
);

const App = () => {
  useEffect(() => {
    async function CountStatic() {
      try {
        await API.getCountStatic();
      } catch (error) {}
    }
    CountStatic();
  }, []);

  return (
    <div className="App">
      <Home />
    </div>
  );
};

export default App;
