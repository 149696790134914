import React, { useEffect, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import SelectQuarter from "utils/SelectQuarter";
import SnackBar from "utils/SnackBar";
import SelectLocation from "utils/SelectLocation";
import ViewButton from "utils/ViewButton";
import SelectYearLabel from "utils/SelectYearLabel";

import API from "services/api";

import styles from "./DataQueryForm.module.css";

const DataQueryFormCumulative = ({ setQueryData }) => {
  const [quarter, setQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const [stateList, setStateList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState({ text: "", type: "" });

  const handleClose = () => {
    setShow(false);
  };

  const handleReset = () => {
    setSelectedYear("");
    setQuarter("");
    setSelectedLocation("");
  };

  const handleLabelYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  useEffect(() => {
    const getStateList = async () => {
      try {
        const states = await API.getAllStates(selectedYear);

        setStateList(states);
      } catch (error) {}
    };

    getStateList();
  }, [selectedYear]);

  const handleStateChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleQuarterChange = (e) => {
    setQuarter(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!selectedLocation) {
      setShow(true);
      setModalText({
        text: "Select a location to continue!",
        type: "warning",
      });

      return;
    }

    if (quarter === "1") {
      setQueryData({
        stateCountry: selectedLocation.toUpperCase(),
        startYear: selectedYear,
        startMonth: 4,
        endYear: selectedYear,
        endMonth: 6,
        normalized: "TRUE",
      });
      setShow(true);
      setModalText({
        text: "Form Submitted! Loading the data...",
        type: "success",
      });
    } else if (quarter === "2") {
      setQueryData({
        stateCountry: selectedLocation.toUpperCase(),
        startYear: selectedYear,
        startMonth: 7,
        endYear: selectedYear,
        endMonth: 9,
        normalized: "TRUE",
      });
      setShow(true);
      setModalText({
        text: "Form Submitted! Loading the data...",
        type: "success",
      });
    } else if (quarter === "3") {
      setQueryData({
        stateCountry: selectedLocation.toUpperCase(),
        startYear: selectedYear,
        startMonth: 10,
        endYear: selectedYear,
        endMonth: 12,
        normalized: "TRUE",
      });
      setShow(true);
      setModalText({
        text: "Form Submitted! Loading the data...",
        type: "success",
      });
    } else if (quarter === "4") {
      setQueryData({
        stateCountry: selectedLocation.toUpperCase(),
        startYear: selectedYear + 1,
        startMonth: 1,
        endYear: selectedYear + 1,
        endMonth: 3,
        normalized: "TRUE",
      });
      setShow(true);
      setModalText({
        text: "Form Submitted! Loading the data...",
        type: "success",
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackBar
        message={modalText.text}
        open={show}
        handleClose={handleClose}
        type={modalText.type}
      />

      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={(e) => handleFormSubmit(e)}>
          <div className={styles.quarterlyFormGroupContainer}>
            <div className={styles.formGroup}>
              <label htmlFor="" className={styles.formLabel}>
                Select Year
              </label>
              <SelectYearLabel
                onYearLabelChange={handleLabelYearChange}
                selectedYearLabel={selectedYear}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="" className={styles.formLabel}>
                Select Quarter
              </label>
              <SelectQuarter
                selectedQuarter={quarter}
                onQuarterChange={handleQuarterChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="" className={styles.formLabel}>
                Select Location
              </label>
              <SelectLocation
                stateList={stateList}
                selectedState={selectedLocation}
                onLocationChange={handleStateChange}
              />
            </div>
          </div>

          <div className={`${styles.formGroup} ${styles.centerRow}`}>
            <ViewButton onView={handleFormSubmit} onReset={handleReset} />
          </div>
        </form>
      </div>
    </LocalizationProvider>
  );
};

export default DataQueryFormCumulative;
