import React, { useEffect, useState } from "react";

import Loader from "utils/Loader";
import PageHeader from "utils/PageHeader";

import IndiaMap from "components/situation/IndiaMap";
import { colorScaleLegend } from "components/situation/colorScale";
import styles from "components/situation/map.module.css";

import API from "services/api";

import { Button, ButtonGroup, MenuItem, Select } from "@mui/material";
import { DownloadRounded } from "@mui/icons-material";

import { SituationIndicatorsIndia, NFHSs } from "constants";
import { Modal } from "react-bootstrap";
import { ResourceContainer } from "components";
import SituationTable from "components/situation/SituationTable";

const IndiaSituation = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [mapData, setMapData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedNFHS, setSelectedNFHS] = useState(5);
  const [selectedIndicator, setSelectedIndicator] = useState("allWomen15_19");

  const [openNHFS, setOpenNHFS] = useState(false);
  const [openCNNS, setOpenCNNS] = useState(false);

  const toggleNHFS = () => {
    setOpenNHFS(!openNHFS);
  };

  const toggleCNNS = () => {
    setOpenCNNS(!openCNNS);
  };

  const handleIndicatorChange = (e) => {
    setSelectedIndicator(e.target.value);
  };

  const handleNFHSChange = (e) => {
    setSelectedNFHS(e.target.value);
  };

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        setLoadingStatus(true);
        const response = await API.fetchAnemiaSituationIndia(selectedNFHS);

        if (response.success) {
          setTableData(response.data);
          let mapDataCalculated = {};

          response.data?.forEach((data) => {
            mapDataCalculated[data.state ? data.state : "No State"] =
              data[selectedIndicator];
          });

          setMapData(mapDataCalculated);
        }
      } catch (error) {
      } finally {
        setLoadingStatus(false);
      }
    };

    fetchMapData();
  }, [selectedNFHS, selectedIndicator]);

  const columnDefs = [
    {
      field: "state",
      headerName: "State",
      width: 300,
      headerClassName: "table-header",
      renderCell: (params) => {
        return (
          <div className={params.field === "state" && styles.firstColumn}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "nfhs",
      headerName: "NFHS",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "child6_59",
      headerName: "Children 6-59 Months",
      width: 220,
      headerClassName: "table-header",
    },

    {
      field: "nonPreg15_49",
      headerName: "Non-Pregnant Women",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "preg15_59",
      headerName: "Pregnant Women",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "allWomen15_49",
      headerName: "Women 15-49 Yrs",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "allWomen15_19",
      headerName: "Women 15-19 Yrs",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "men15_49",
      headerName: "Men 15-49 Yrs",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "men15_19",
      headerName: "Men 15-19 Yrs",
      width: 220,
      headerClassName: "table-header",
    },
  ];

  return (
    <>
      <PageHeader text="Anemia Situation - India" />

      <div className={`container-fluid ${styles.situationContainer}`}>
        <div className={styles.filters}>
          <Select
            disabled
            size="small"
            sx={{ width: "100%" }}
            name="Select NFHS"
            id="select-nhfs"
            value={selectedNFHS}
            onChange={handleNFHSChange}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            required
          >
            <MenuItem disabled value="">
              Select Indicator
            </MenuItem>

            {NFHSs.map((indicator) => (
              <MenuItem key={indicator.key} value={indicator.key}>
                {indicator.value}
              </MenuItem>
            ))}
          </Select>

          <Select
            size="small"
            sx={{ width: "100%" }}
            name="Select Indicator"
            id="select-indicator"
            value={selectedIndicator}
            onChange={handleIndicatorChange}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            required
          >
            <MenuItem disabled value="">
              Select Indicator
            </MenuItem>

            {SituationIndicatorsIndia.map((indicator) => (
              <MenuItem
                key={indicator.indicatorKey}
                value={indicator.indicatorKey}
              >
                {indicator.indicatorValue}
              </MenuItem>
            ))}
          </Select>
        </div>
        {loadingStatus ? (
          <Loader loading={loadingStatus} />
        ) : (
          <div className={styles.mapContainer} style={{ padding: "10px" }}>
            <div className={styles.mapContent}>
              <IndiaMap mapDataCalculated={mapData} />
            </div>

            <div>
              <div className={styles.row}>
                <div className={styles.colorScaleContainer}>
                  {colorScaleLegend.map((item, index) => (
                    <div className={styles.colorScale} key={index}>
                      <div
                        className={styles.colorScaleDots}
                        style={{
                          border: "1px solid black",
                          backgroundColor: item.color,
                        }}
                      />
                      <span>{item.label}</span>
                    </div>
                  ))}
                </div>

                <ButtonGroup>
                  <Button
                    variant="contained"
                    startIcon={<DownloadRounded />}
                    onClick={toggleNHFS}
                  >
                    NFHS-5
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<DownloadRounded />}
                    onClick={toggleCNNS}
                  >
                    CNNS
                  </Button>
                </ButtonGroup>
              </div>

              <p>
                <b>Source:</b> National Family Health Survey (NFHS)
              </p>
            </div>

            <Modal show={openNHFS} onHide={toggleNHFS}>
              <Modal.Header closeButton>
                <Modal.Title>NFHS-5</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                1. Adolescent boys Anemia-Prevalence:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="modal-link"
                  href="https://resources.anemiamuktbharat.info/NFHS_5/Adolesent-boys-Anemia-Prevelnace-NFHS_5-1.pptx"
                >
                  Link
                </a>
                <br />
                2. ⁠Adolescent girls Anemia-Prevalence:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="modal-link"
                  href="https://resources.anemiamuktbharat.info/NFHS_5/Adolesent-girls-Anemia-Prevelnace-NFHS_5-1.pptx"
                >
                  Link
                </a>
                <br />
                3. ⁠Children 6-59 months Anemia-Prevalence:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="modal-link"
                  href="https://resources.anemiamuktbharat.info/NFHS_5/Children-6-59-months-Anemia-Prevelnace-NFHS_5-1-Copy.pptx"
                >
                  Link
                </a>
                <br />
                4. ⁠Pregnant Women Consumed 100 IFA Anemia-Prevalence:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="modal-link"
                  href="https://resources.anemiamuktbharat.info/NFHS_5/PW-100-IFA-NFHS_5.pptx"
                >
                  Link
                </a>
                <br />
                5. ⁠⁠Pregnant Women Consumed 180 IFA Anemia-Prevalence:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="modal-link"
                  href="https://resources.anemiamuktbharat.info/NFHS_5/PW-180-IFA-NFHS_5.pptx"
                >
                  Link
                </a>
                <br />
                6. ⁠⁠⁠⁠Pregnant Women 15-49 years Anemia-Prevalence:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="modal-link"
                  href="https://resources.anemiamuktbharat.info/NFHS_5/PW-Anemia-Prevelnace-NFHS_5-1.pptx"
                >
                  Link
                </a>
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="contained" onClick={toggleNHFS}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal fullscreen show={openCNNS} onHide={toggleCNNS}>
              <Modal.Header closeButton>
                <Modal.Title>CNNS</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ResourceContainer category="CNNS" />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="contained" onClick={toggleCNNS}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}

        <SituationTable
          columns={columnDefs}
          loading={loadingStatus}
          tableData={tableData}
          getRowId={(row) => {
            return row.state;
          }}
        />
      </div>
    </>
  );
};

export default IndiaSituation;
