import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

import DMSans from "assets/fonts/DMSans-Medium.ttf";
import DMSansSemiBold from "assets/fonts/DMSans-SemiBold.ttf";

Font.register({
  family: "DMSans",
  src: DMSans,
});

Font.register({
  family: "DMSansSemiBold",
  src: DMSansSemiBold,
});

const styles = StyleSheet.create({
  tableRow: {
    fontFamily: "DMSans",
    padding: 2,
    marginVertical: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  legendText: { fontSize: 8 },
  legendTableRow: {
    flexDirection: "row",
    fontFamily: "DMSans",
    paddingHorizontal: 2,
    paddingVertical: 3,
    justifyContent: "center",
    gap: 5,
  },
  tableCell: {
    width: "50%",
    color: "black",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "DMSans",
    fontSize: 8,
    padding: 5,
    backgroundColor: "#fcfcfc",
  },
  dataCell: {
    width: 10,
    height: 10,
    fontFamily: "DMSans",
    borderRadius: 2,
    color: "white",
    padding: 3,
    marginHorizontal: 1,
    textAlign: "center",
  },
  noDataCell: {
    borderColor: "#808080",
    backgroundColor: "#d3d3d380",
  },
  colorVeryLowCell: {
    borderColor: "#105bab",
    backgroundColor: "#105babd5",
  },
  colorLowCell: {
    borderColor: "#105bab",
    backgroundColor: "#40c8f4",
  },
  colorMediumCell: {
    borderColor: "#fdd225",
    backgroundColor: "#ffe06a",
  },
  colorHighCell: {
    borderColor: "#228a05",
    backgroundColor: "#60bb46",
  },
  colorNormalizedCell: {
    borderColor: "#082a01",
    backgroundColor: "#025418",
  },
});

const Legend = () => {
  return (
    <View style={styles.tableRow}>
      <View style={styles.legendTableRow}>
        <View style={[styles.noDataCell, styles.dataCell]} />
        <Text style={styles.legendText}>No data</Text>
      </View>

      <View style={styles.legendTableRow}>
        <View style={[styles.colorVeryLowCell, styles.dataCell]} />
        <Text style={styles.legendText}>0-24.9%</Text>
      </View>

      <View style={styles.legendTableRow}>
        <View style={[styles.colorLowCell, styles.dataCell]} />
        <Text style={styles.legendText}>25-49.9%</Text>
      </View>

      <View style={styles.legendTableRow}>
        <View style={[styles.colorMediumCell, styles.dataCell]} />
        <Text style={styles.legendText}>50-74.9%</Text>
      </View>

      <View style={styles.legendTableRow}>
        <View style={[styles.colorHighCell, styles.dataCell]} />
        <Text style={styles.legendText}>Above 75%</Text>
      </View>

      <View style={styles.legendTableRow}>
        <View style={[styles.colorNormalizedCell, styles.dataCell]} />
        <Text style={styles.legendText}>Above 95%</Text>
      </View>
    </View>
  );
};

export default Legend;
