import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import React from "react";

import DMSans from "assets/fonts/DMSans-Medium.ttf";

Font.register({
  family: "DMSans",
  src: DMSans,
});

const styles = StyleSheet.create({
  legendTableRow: {
    flexDirection: "row",
    fontFamily: "DMSans",
    paddingHorizontal: 2,
    paddingVertical: 3,
    justifyContent: "center",
    gap: 5,
    fontSize: 12,
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
    fontFamily: "DMSans",
    padding: 2,
    justifyContent: "center",
  },
  tableCell: {
    width: "6.5%",
    borderColor: "#069edb",
    borderWidth: 2,
    borderRadius: 5,
    padding: 5,
    color: "#069edb",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "DMSans",
    fontSize: 10,
    backgroundColor: "#ececec50",
  },
  dataCell: {
    fontFamily: "DMSans",
    fontWeight: "bold",
    fontSize: 10,
    borderColor: "#069edb",
    color: "#069edb",
    width: "20%",
    borderWidth: 2,
    borderRadius: 5,
    padding: 5,
    marginHorizontal: 1,
    textAlign: "center",
    backgroundColor: "#ececec50",
  },
  noDataCell: {
    width: "20%",
    borderWidth: 1,
    color: "black",
    padding: 5,
    marginHorizontal: 1,
    textAlign: "center",
    fontFamily: "DMSans",
    borderColor: "#808080",
    backgroundColor: "#80808080",
  },
  rankDataCell: {
    fontFamily: "DMSans",
    width: "20%",
    borderWidth: 1,
    padding: 5,
    marginHorizontal: 1,
    textAlign: "center",
    backgroundColor: "#f0f0f0",
  },
  colorVeryLowCell: {
    fontFamily: "DMSans",
    width: "20%",
    borderWidth: 1,
    color: "white",
    padding: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#105bab",
    backgroundColor: "#105babd5",
  },
  colorLowCell: {
    fontFamily: "DMSans",
    width: "20%",
    borderWidth: 1,
    color: "white",
    padding: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#105bab",
    backgroundColor: "#40c8f4",
  },
  colorMediumCell: {
    fontFamily: "DMSans",
    width: "20%",
    borderWidth: 1,
    color: "#000",
    padding: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#fdd225",
    backgroundColor: "#ffe06a",
  },
  colorHighCell: {
    fontFamily: "DMSans",
    width: "20%",
    borderWidth: 1,
    color: "white",
    padding: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#228a05",
    backgroundColor: "#60bb46",
  },
  colorNormalizedCell: {
    fontFamily: "DMSans",
    width: "20%",
    borderWidth: 1,
    color: "white",
    padding: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#222222",
    backgroundColor: "#000000",
  },
});

function ColorTable() {
  return (
    <View style={[styles.table, { paddingBottom: 10 }]}>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.tableRow,
            {
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            },
          ]}
        >
          <View style={styles.legendTableRow}>
            <View style={[styles.noDataCell, { fontFamily: "DMSans" }]} />
            <Text style={{ textAlign: "center" }}>No data</Text>
          </View>

          <View style={styles.legendTableRow}>
            <View style={[styles.colorVeryLowCell, { fontFamily: "DMSans" }]} />
            <Text style={{ textAlign: "center" }}>0-24.9%</Text>
          </View>

          <View style={styles.legendTableRow}>
            <View style={[styles.colorLowCell, { fontFamily: "DMSans" }]} />
            <Text style={{ textAlign: "center" }}>25-49.9%</Text>
          </View>

          <View style={styles.legendTableRow}>
            <View style={[styles.colorMediumCell, { fontFamily: "DMSans" }]} />
            <Text style={{ textAlign: "center" }}>50-74.9%</Text>
          </View>

          <View style={styles.legendTableRow}>
            <View style={[styles.colorHighCell, { fontFamily: "DMSans" }]} />
            <Text style={{ textAlign: "center" }}>75 to 100%</Text>
          </View>

          <View style={styles.legendTableRow}>
            <View
              style={[styles.colorNormalizedCell, { fontFamily: "DMSans" }]}
            />
            <Text style={{ textAlign: "center" }}>Error</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default ColorTable;
