import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import DMSans from "assets/fonts/DMSans-Medium.ttf";

Font.register({
  family: "DMSans",
  src: DMSans,
});

const styles = StyleSheet.create({
  image: {
    width: 100,
    height: 100,
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 15,
    color: "#069edb",
    fontFamily: "DMSans",
    fontWeight: "bold",
    textAlign: "center",
    alignSelf: "center",
  },
  subTitle: {
    fontSize: 12,
    fontFamily: "DMSans",
    fontWeight: "bold",
    textAlign: "center",
    alignSelf: "center",
  },
});

function HeaderPDF({ queryData }) {
  return (
    <>
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            padding: 7.5,
            gap: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            source={{
              uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1691680981/Project_A/LogoAmb1_syna7x.png",
            }}
            style={{ width: 50, height: 50 }}
          />
        </View>

        <View
          style={{
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          <Text style={styles.sectionTitle}>
            Maternal Nutrition in Percentage
          </Text>
          <Text style={styles.sectionTitle}>
            {queryData.startYear ? (
              queryData.startYear === queryData.endYear ? (
                `(${queryData?.startYear})`
              ) : (
                `(${queryData?.startYear} - ${queryData?.endYear})`
              )
            ) : (
              <></>
            )}
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            padding: 5,
            gap: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            source={{
              uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1691682278/poshan_gkolhm.png",
            }}
            style={{ width: 70, height: 70 }}
          />
          <Image
            source={{
              uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1694057789/NHM-logo_tenbxg.png",
            }}
            style={{ width: 60, height: 45 }}
          />
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 2,
          borderColor: "#069edb",
          marginBottom: 10,
        }}
      />
    </>
  );
}

export default HeaderPDF;
