import React, { useEffect, useState } from "react";

import { Button, MenuItem, Select } from "@mui/material";
import {
  AlternateEmailRounded,
  MarkEmailReadRounded,
} from "@mui/icons-material";

import styles from "./ForgotPassword.module.css";

import ProgressSteps from "components/auth/ProgressSteps";
import TextInput from "utils/TextInput";
import API from "services/api";

function ForgotPasswordForm({
  email,
  activeStep,
  onOTPGenerate,
  onEmailChange,
  selectedState,
  onChange,
}) {
  const [statesList, setStatesList] = useState([]);
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchStatesList = async () => {
      try {
        const statesList = await API.getAllStates(2023);
        setStatesList(statesList);
      } catch (error) {}
    };
    fetchStatesList();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.formCard}>
          <ProgressSteps
            step={activeStep}
            steps={["Enter Email", "Verify OTP", "Reset Password", "Login"]}
          />

          <form onSubmit={onOTPGenerate} className={styles.loginForm}>
            <div className={styles.formGroup}>
              <Select
                fullWidth
                size="small"
                type="text"
                name="state"
                id="state"
                placeholder="Select State"
                value={selectedState}
                onChange={onChange}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                required
              >
                <MenuItem disabled value="">
                  Select State
                </MenuItem>
                {statesList?.map((state) => (
                  <MenuItem
                    className="dropdown-item"
                    key={state.id}
                    value={state.name}
                  >
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className={styles.formGroup}>
              <TextInput
                label="Email ID"
                name="email"
                placeholder="example@example.com"
                icon={<AlternateEmailRounded style={{ color: "#069edb" }} />}
                value={email}
                onChange={onEmailChange}
              />
            </div>

            <Button
              variant="primary"
              style={{ backgroundColor: "#069edb", color: "white" }}
              startIcon={<MarkEmailReadRounded />}
              onClick={onOTPGenerate}
            >
              Send OTP
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordForm;
