import React, { Suspense } from "react";

import AdminPanelLayout from "components/admin/AdminPanelLayout";
import { UploadResource } from "components";

import ScrollToTop from "utils/ScrollToTop";

import styles from "./ResourceAdmin.module.css";

const ResourcesTable = React.lazy(() =>
  import("components/admin/ResourceAdmin/ResourcesTable")
);

function ResourceAdmin() {
  return (
    <AdminPanelLayout>
      <div className={styles.container}>
        <UploadResource />
        <Suspense fallback={<div>Loading</div>}>
          <ResourcesTable />
        </Suspense>
      </div>
      <ScrollToTop />
    </AdminPanelLayout>
  );
}

export default ResourceAdmin;
