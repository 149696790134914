import React from "react";
import { View, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  footerContainer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    marginHorizontal: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    fontSize: 10,
    color: "black",
    fontWeight: "normal",
  },
  link: {
    color: "blue",
  },
  logo: {
    width: 60,
    height: 60,
    marginRight: 10,
  },
  unicefLogo: {
    width: 150,
    height: 50,
  },
});

const Footer = () => (
  <View style={styles.footerContainer}>
    <Image
      style={styles.logo}
      source={require("assets/img/Institute-of-Economic-Growth.png")}
    />
    <Image
      style={styles.unicefLogo}
      source={require("assets/img/UNICEFLogo.png")}
    />
  </View>
);

export default Footer;
