import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";

import india from "./topojsons/india.json";

import styles from "./map.module.css";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const MapChart = ({
  setStateName,
  setShowDistrict,
  setPosition,
  position,
  mapDataCalculated,
  queryData,
}) => {
  const [tooltipContent, setTooltipContent] = useState("");

  const colorComponent = (value) => {
    let percentValue = value;
    let colorIndex = 1;
    if (!percentValue) colorIndex = 0;
    if (percentValue >= 25 && percentValue < 50) colorIndex = 2;
    else if (percentValue >= 50 && percentValue < 75) colorIndex = 3;
    else if (percentValue >= 75) colorIndex = 4;

    if (queryData && queryData.normalized && percentValue >= 95) {
      colorIndex = 5;
      percentValue = 95;
    }

    return colorIndex;
  };

  function handleMoveEnd(position) {
    setPosition(position);
  }

  const handleMouseEnter = (geo) => {
    const { ST_NM } = geo.properties;

    if (ST_NM) {
      const currState = ST_NM.toUpperCase();
      if (mapDataCalculated) {
        const value = mapDataCalculated[currState]?.value;
        const stateData = value ? value.toFixed(2) : null;

        if (stateData) {
          setTooltipContent(`${ST_NM} - ${stateData}`);
        } else {
          setTooltipContent(`${ST_NM} - No Data`);
        }
      } else {
        alert("No Data Available!");
      }
    }
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
  };

  const colorScale = [
    "#d3d3d380", //No Data 0
    "#105bab", //Color Very Low 1
    "#40c8f4", //Color Low 2
    "#ffe06a", //Color Medium 3
    "#60bb46", //Color High 4
    "#333333", //Color Normalized 5
  ];

  return (
    <>
      <ComposableMap
        projection="geoMercator"
        width={150}
        height={150}
        projectionConfig={{ scale: 220 }}
        className={styles.map}
      >
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={handleMoveEnd}
        >
          <Geographies geography={india}>
            {({ geographies }) =>
              geographies?.map((geo) => {
                const { ST_NM } = geo.properties;
                const currState = ST_NM?.toUpperCase();
                const value = mapDataCalculated[currState]?.value;
                const stateData = value ? value.toFixed(2) : null;

                const fillColor = stateData
                  ? colorScale[colorComponent(stateData)]
                  : "#d3d3d380";

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={(event) => handleMouseEnter(geo, event)}
                    onMouseLeave={handleMouseLeave}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={tooltipContent}
                    onClick={() => {
                      setStateName(`${ST_NM}`);
                      stateData && setShowDistrict(true);
                    }}
                    style={{
                      default: {
                        fill: fillColor,
                        outline: "none",
                        stroke: "white",
                        strokeWidth: "0.1px",
                        strokeOpacity: "50%",
                      },
                      hover: {
                        fill: "#04458a",
                        stroke: "white",
                        strokeWidth: "0.3px",
                        strokeOpacity: "50%",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#04458a",
                        outline: "none",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <Tooltip id="my-tooltip" />
    </>
  );
};

export default MapChart;
