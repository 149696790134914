import React, { useState } from "react";

import styles from "./ForgotPassword.module.css";

import { Button } from "@mui/material";
import { Check } from "@mui/icons-material";

import PasswordInput from "utils/PasswordInput";
import ProgressSteps from "components/auth/ProgressSteps";
import API from "services/api";

function ChangePassword({
  activeStep,
  setActiveStep,
  selectedState,
  setShow,
  setMessage,
}) {
  const [password, setPassword] = useState();

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleResetPassword = async () => {
    try {
      if (password) {
        const response = await API.setStateUserPassword({
          state: selectedState,
          password: password,
        });

        if (response?.success) {
          setActiveStep(3);
        } else {
          setShow(true);
          setMessage({
            text: response.message,
            type: "error",
          });
        }
      }
    } catch (error) {
      setShow(true);
      setMessage({
        text: error.message,
        type: "error",
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formCard}>
        <ProgressSteps
          step={activeStep}
          steps={["Enter Email", "Verify OTP", "Reset Password", "Login"]}
        />

        <form onSubmit={handleResetPassword} className={styles.loginForm}>
          <div className={styles.formGroup}>
            <PasswordInput
              label="Password"
              name="password"
              placeholder="Enter password"
              showPassword={showPassword}
              onShowPassword={handleClickShowPassword}
              value={password}
              onChange={handlePasswordChange}
            />
          </div>

          <Button
            variant="primary"
            className={styles.button}
            startIcon={<Check />}
            onClick={handleResetPassword}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
