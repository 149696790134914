import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import DMSans from "assets/fonts/DMSans-Medium.ttf";
import DMSansSemiBold from "assets/fonts/DMSans-SemiBold.ttf";
import Legend from "./Legend";
import { getPDFColorClass } from "utils/getPDFColorClass";
import getPDFHeading from "utils/getPDFHeading";
import AboutSection from "./AboutSection";

Font.register({
  family: "DMSans",
  src: DMSans,
});

Font.register({
  family: "DMSansSemiBold",
  src: DMSansSemiBold,
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "DMSans",
    fontSize: 12,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  headingLeft: {
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    textAlign: "left",
  },
  image: {
    width: 100,
    height: 100,
    marginBottom: 20,
  },
  container: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 15,
    color: "#069edb",
    fontFamily: "DMSansSemiBold",
  },
  subTitle: {
    fontSize: 12,
    fontFamily: "DMSans",
  },
  date: {
    fontSize: 9,
    fontFamily: "DMSans",
    fontWeight: "medium",
    textAlign: "right",
    alignSelf: "flex-end",
  },
  tableHeaderName: {},
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "row",
    fontFamily: "DMSans",
    paddingVertical: 1,
  },
  headerIcon: { height: 30, aspectRatio: 1 / 1 },
  legendTableRow: {
    flexDirection: "row",
    fontFamily: "DMSans",
    paddingHorizontal: 2,
    paddingVertical: 3,
    justifyContent: "center",
    gap: 5,
  },
  tableCell: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "50%",
    color: "black",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "DMSans",
    fontSize: 9,
    paddingHorizontal: 5,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
  },
  column: { flexDirection: "column" },
  dataCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "DMSans",
    fontSize: 9,
    width: "20%",
    height: "100%",
    marginHorizontal: 1,
    textAlign: "center",
    backgroundColor: "#fcfcfc",
  },
  noDataCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    height: "100%",
    borderWidth: 1,
    color: "black",
    paddingHorizontal: 5,
    marginHorizontal: 1,
    textAlign: "center",
    fontFamily: "DMSans",
    borderColor: "#d3d3d3",
    backgroundColor: "#d3d3d380",
    fontSize: 9,
  },
  rankDataCell: {
    fontFamily: "DMSans",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    height: "100%",
    borderWidth: 1,
    borderColor: "#d3d3d3",
    marginHorizontal: 1,
    textAlign: "center",
    backgroundColor: "#fcfcfc",
    fontSize: 9,
  },
  colorVeryLowCell: {
    fontFamily: "DMSans",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    height: "100%",
    borderWidth: 1,
    color: "white",
    paddingHorizontal: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#105bab",
    backgroundColor: "#105babd5",
    fontSize: 9,
  },
  colorLowCell: {
    fontFamily: "DMSans",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    height: "100%",
    borderWidth: 1,
    color: "white",
    paddingHorizontal: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#105bab",
    backgroundColor: "#40c8f4",
    fontSize: 9,
  },
  colorMediumCell: {
    fontFamily: "DMSans",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    height: "100%",
    borderWidth: 1,
    color: "#000",
    paddingHorizontal: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#fdd225",
    backgroundColor: "#ffe06a",
    fontSize: 9,
  },
  colorHighCell: {
    fontFamily: "DMSans",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    height: "100%",
    borderWidth: 1,
    color: "white",
    paddingHorizontal: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#228a05",
    backgroundColor: "#60bb46",
    fontSize: 9,
  },
  colorNormalizedCell: {
    fontFamily: "DMSans",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    height: "100%",
    borderWidth: 1,
    color: "white",
    paddingHorizontal: 5,
    marginHorizontal: 1,
    textAlign: "center",
    borderColor: "#002f07",
    backgroundColor: "#024f0c",
    fontSize: 9,
  },
  info: { fontFamily: "DMSans", fontSize: 6, textAlign: "center" },
});

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const quarters = ["Apr - Jun", "Jul - Sept", "Oct - Dec", "Jan - March"];

export function PdfDocument({
  kpiDataTable,
  queryData,
  dataName,
  currentComponent,
  dataAsOnDate,
}) {
  const isMonthlyRanking = currentComponent === "Ranking_Monthly";

  const startMonth = months[queryData.startMonth - 1];
  const endMonth = months[queryData.endMonth - 1];

  const heading = getPDFHeading(
    queryData,
    isMonthlyRanking,
    startMonth,
    endMonth
  );

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.container}>
          {(currentComponent === "Ranking_Monthly" ||
            currentComponent === "Ranking_Quarterly") && (
            <>
              <View style={styles.headingLeft}>
                <View style={styles.row}>
                  <Image
                    source={{
                      uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1691680981/Project_A/LogoAmb1_syna7x.png",
                    }}
                    style={{ width: 50, height: 50 }}
                  />
                  <View>
                    <Text style={styles.amb}>ANEMIA MUKT BHARAT</Text>
                    <Text style={styles.sectionTitle}>
                      SCORECARD,{" "}
                      {queryData.startYear !== queryData.endYear
                        ? queryData.startYear + "-" + queryData.endYear
                        : queryData.startYear}
                    </Text>
                    <Text style={styles.subTitle}>
                      IFA Coverage - Key Performance Indicators, HMIS
                    </Text>
                    <Text style={styles.heading}>({heading})</Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    padding: 5,
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1691682278/poshan_gkolhm.png",
                    }}
                    style={{ width: 70, height: 70 }}
                  />
                  <Image
                    source={{
                      uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1694057789/NHM-logo_tenbxg.png",
                    }}
                    style={{ width: 60, height: 45 }}
                  />
                </View>
              </View>
              <View
                style={{
                  borderBottomWidth: 2,
                  borderColor: "#069edb",
                  marginBottom: 10,
                }}
              />
            </>
          )}

          {currentComponent !== "Ranking_Monthly" &&
          currentComponent !== "Ranking_Quarterly" ? (
            <>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    padding: 7.5,
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1691680981/Project_A/LogoAmb1_syna7x.png",
                    }}
                    style={{ width: 50, height: 50 }}
                  />
                </View>

                <View
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                  }}
                >
                  <Text style={styles.sectionTitle}>
                    {currentComponent === "KPI_Quarterly"
                      ? `AMB KPI Score Card \n (${
                          quarters[queryData?.quarter - 1]
                        } ${queryData?.year})`
                      : `AMB KPI Score Card \n (${
                          months[queryData?.month - 1]
                        } ${queryData?.year})`}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    padding: 5,
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1691682278/poshan_gkolhm.png",
                    }}
                    style={{ width: 70, height: 70 }}
                  />
                  <Image
                    source={{
                      uri: "https://res.cloudinary.com/daxilgrvn/image/upload/v1694057789/NHM-logo_tenbxg.png",
                    }}
                    style={{ width: 60, height: 45 }}
                  />
                </View>
              </View>
              <View
                style={{
                  borderBottomWidth: 2,
                  borderColor: "#069edb",
                  marginBottom: 10,
                }}
              />
            </>
          ) : (
            <></>
          )}

          <Text style={styles.date}>Data as on date: {dataAsOnDate}</Text>

          <View style={styles.table}>
            <View
              style={{
                ...styles.tableRow,
                borderBottomWidth: 1,
                borderBottomColor: "#A9A9A9",
              }}
            >
              <Text style={styles.tableCell}>Location</Text>
              {dataName &&
                dataName.map((name, index) =>
                  queryData?.stateCountry === "INDIA" &&
                  name.key !== "rankDist" ? (
                    <View
                      style={{
                        ...styles.dataCell,
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      {name.imgSrc && (
                        <Image source={name.imgSrc} style={styles.headerIcon} />
                      )}
                      <Text key={index} style={styles.tableHeaderName}>
                        {name.pdfName}
                      </Text>
                    </View>
                  ) : queryData?.stateCountry !== "INDIA" &&
                    name.key !== "rankState" ? (
                    <View
                      style={{ ...styles.dataCell, flexDirection: "column" }}
                    >
                      {name.imgSrc && (
                        <Image source={name.imgSrc} style={styles.headerIcon} />
                      )}
                      <Text key={index} style={styles.tableHeaderName}>
                        {name.pdfName}
                      </Text>
                    </View>
                  ) : (
                    <View></View>
                  )
                )}
            </View>

            {kpiDataTable &&
              kpiDataTable?.map((state, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={[styles.tableCell, { fontFamily: "DMSans" }]}>
                    {state.stateDistrict}
                  </Text>
                  {dataName &&
                    dataName?.map((name, index) => {
                      let percentValue =
                        name.key === "rankState" ||
                        name.key === "stateRank" ||
                        name.key === "rankDist"
                          ? 0
                          : state[name.key];
                      if (
                        percentValue === 0 &&
                        !(
                          name.key === "rankState" ||
                          name.key === "stateRank" ||
                          name.key === "rankDist"
                        )
                      )
                        return (
                          <Text style={styles.noDataCell} key={index}>
                            0.0
                          </Text>
                        );
                      if (
                        percentValue === 0 &&
                        (name.key === "rankState" ||
                          name.key === "stateRank" ||
                          name.key === "rankDist")
                      )
                        return name.key !== "rankDist" ? (
                          <Text style={styles.rankDataCell} key={index}>
                            {state[name.key] !== 0 && state[name.key]}
                          </Text>
                        ) : name.key !== "rankState" ||
                          name.key !== "stateRank" ? (
                          <Text style={styles.rankDataCell} key={index}>
                            {state[name.key] !== 0 && state[name.key]}
                          </Text>
                        ) : (
                          <View></View>
                        );

                      const colorClass = getPDFColorClass(
                        queryData?.normalized,
                        percentValue
                      );

                      return (
                        <View style={styles[colorClass]} key={index}>
                          <Text>
                            {percentValue?.toFixed(1)}
                            {percentValue >= 95 && <Text>*</Text>}
                          </Text>
                        </View>
                      );
                    })}
                </View>
              ))}
          </View>

          <Legend />
        </View>
      </Page>

      <Page style={styles.page} size="A4">
        <AboutSection />
      </Page>
    </Document>
  );
}
