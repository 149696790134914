import styles from "./StockTable.module.css";

function StockTableMonthly({ stocksData }) {
  return (
    <div className="table-sub-container">
      <small className="text-right">*figures in lakhs</small>
      <table className={`${styles.table} table table-responsive`}>
        <thead style={{ position: "sticky", top: "0" }}>
          <tr className="text-center">
            <th style={{ width: "300px " }} scope="row">
              Indicators
            </th>
            <th>Year</th>
            <th>April</th>
            <th>May</th>
            <th>June</th>
            <th>July</th>
            <th>August</th>
            <th>September</th>
            <th>October</th>
            <th>November</th>
            <th>December</th>
            <th>January</th>
            <th>February</th>
            <th>March</th>
          </tr>
        </thead>
        <tbody>
          {stocksData?.map((data) => {
            return (
              <tr
                key={`${data?.indicator}${data?.year}${data?.stockType}${
                  data?.jan
                }${Math.random()}`}
              >
                <th className={styles.stocksFirstColumn}>
                  <b>{data.indicator}</b> <br />
                  {data.description}
                </th>
                <td>{data.year}</td>
                {data?.months?.map((month) => {
                  return <td key={month.month}>{month?.value}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default StockTableMonthly;
