import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

import styles from "./Login.module.css";
import "App.css";

import { Button, MenuItem, Select } from "@mui/material";
import { Autorenew, LoginRounded } from "@mui/icons-material";

import PasswordInput from "utils/PasswordInput";
import API from "services/api";

function DenominatorsLoginForm({
  loginData,
  captchaRef,
  onChange,
  onSubmit,
  onReset,
  showPassword,
  onShowPassword,
}) {
  const [statesList, setStatesList] = useState([]);

  useEffect(() => {
    const fetchStatesList = async () => {
      try {
        const statesList = await API.getAllStates(2023);
        setStatesList(statesList);
      } catch (error) {}
    };
    fetchStatesList();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.formCard}>
        <form onSubmit={onSubmit} className={styles.loginForm}>
          <div className={styles.formGroup}>
            <Select
              fullWidth
              size="small"
              type="text"
              className={styles["form-input"]}
              name="state"
              id="state"
              value={loginData.state}
              onChange={onChange}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              required
            >
              <MenuItem disabled value="">
                Select State
              </MenuItem>
              {statesList?.map((state) => (
                <MenuItem
                  className="dropdown-item"
                  key={state.id}
                  value={state.name}
                >
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className={styles.formGroup}>
            <PasswordInput
              label="Password"
              name="password"
              placeholder="Enter password"
              showPassword={showPassword}
              onShowPassword={onShowPassword}
              value={loginData.password}
              onChange={onChange}
            />
          </div>

          <div className={styles.loginFooter}>
            <div className={styles.row}>
              <Link
                className={styles.forgotPassword}
                to="/reports/denominators/forgotPassword"
              >
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#fafeff",
                    },
                  }}
                >
                  Forgot Password?
                </Button>
              </Link>

              <Link
                className={styles.forgotPassword}
                to="/reports/denominators/get-credentials"
              >
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#fafeff",
                    },
                  }}
                >
                  Get Credentials
                </Button>
              </Link>
            </div>

            <div className={styles.captcha}>
              <ReCAPTCHA
                type="image"
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                style={{ width: "100%", fontFamily: "Poppins" }}
              />
            </div>

            <div className={styles.buttonGroup}>
              <Button
                variant="primary"
                className={styles.button}
                endIcon={<LoginRounded />}
                onClick={onSubmit}
              >
                Login
              </Button>

              <Button
                variant="primary"
                className={styles.button}
                startIcon={<Autorenew />}
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DenominatorsLoginForm;
