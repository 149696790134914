import React from "react";
import styles from "./ContactInfoCard.module.css";
import { FaEnvelope } from "react-icons/fa";
const ContactInfoCard = () => {
  return (
    <div className={styles["card-container"]}>
      <h4 className={styles["card-heading"]}>Contact Info</h4>
      <div className={styles["card-content"]}>
        <div className={styles["mail-box"]}>
          <h2>Nutrition Division:</h2>
          <div>
            <h3>Ministry of Health & Family Welfare, Government of India</h3>
            <a href="mailto:amb.mohfw@gmail.com">
              <FaEnvelope /> amb.mohfw@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoCard;
