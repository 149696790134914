import React from "react";
import "./footer.css";

import unicef from "assets/img/UNICEFLogo.png";
import nutrition from "assets/img/nutritionlogo.png";
import ieg from "assets/img/IELogo.png";

import { useLocation } from "react-router-dom";
import FooterNCEAR from "./FooterNCEAR";

const Footer = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/contact/partners/ncear-a" ? (
        <FooterNCEAR />
      ) : (
        <footer className="amb-footer">
          <a href="https://iegindia.org/" target="blank">
            <img
              className="amb-logo-ieg"
              src={ieg}
              alt="Institute of Economic Growth"
            />
          </a>
          {/*<a href="https://healthnutritionindia.in/" target="blank">*/}
          {/*  <img*/}
          {/*    className="amb-logo-nutrition"*/}
          {/*    src={nutrition}*/}
          {/*    alt="Nutrition"*/}
          {/*  />*/}
          {/*</a>*/}
          <a href="https://www.unicef.org/india/" target="blank">
            <img className="amb-logo-unicef" src={unicef} alt="unicef" />
          </a>
        </footer>
      )}
    </>
  );
};

export default Footer;
