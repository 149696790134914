import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

import DMSans from "assets/fonts/DMSans-Medium.ttf";
import DMSansSemiBold from "assets/fonts/DMSans-SemiBold.ttf";

Font.register({
  family: "DMSans",
  src: DMSans,
});

Font.register({
  family: "DMSansSemiBold",
  src: DMSansSemiBold,
});

const styles = StyleSheet.create({
  headerText: {
    width: "100%",
    backgroundColor: "#069edb",
    color: "white",
    padding: 5,
    fontSize: 16,
    fontFamily: "DMSans",
    textAlign: "center",
  },
});

const AboutSection = () => {
  return (
    <>
      <Text style={styles.headerText}>
        About Anemia Mukt Bharat (AMB) Score Card
      </Text>

      <View style={{ gap: 10, marginVertical: 10 }}>
        <Text style={{ color: "black", fontWeight: "normal", fontSize: 10 }}>
          i. AMB Index: The Index is calculated as the simple mean of the five
          selected coverage key performance indicators.
          {"\n"}
          ii. Numerators are from HMIS Standard Report 2023-24 (up to December
          2023). The Denominators are from Anemia Mukt Bharat – Dashboard
          2023-24.
          {"\n"}
          The numerators and denominators account for monthly consumption
          frequency for all related indicators.
          {"\n"}
          iii. States are ranked based on the AMB index with States having
          highest index value ranked first and so on.
          {"\n"}
          iv. Status as on 15th January, 2024.
          {"\n"}
          v. * 95% is considered as the ceiling value for HMIS coverage for
          States and UTs
          {"\n"}
          {"\n"}# The HMIS data item code as per standard report is as follows:
          {"\n"}
          {"\n"}
          <Text style={{ marginLeft: 5 }}>
            1. HMIS 5.1.2: Children (6-59 months) provided 8-10 doses (1ml) of
            IFA syrup (Bi weekly)
            {"\n"}
            2. HMIS 5.1.3.a + 5.1.3.b: Children covered under WIFS Junior (5-9
            years) provided 4-5 IFA tablets (In schools + out of school) per
            month
            {"\n"}
            3. HMIS 12.2.1.a + 12.2.1.b + 12.2.3: Adolescent (10-19 years) girls
            & boys provided 4 IFA tablets in schools & out of school girls
            provided at AWC per month
            {"\n"}
            4. HMIS 1.2.4: Pregnant women (PW) given 180 IFA tablets.
            {"\n"}
            5. HMIS 6.3: Mothers provided full course of 180 IFA tablets after
            delivery.
          </Text>
        </Text>
      </View>

      <View style={{ gap: 10, marginVertical: 12 }}>
        <Text style={{ color: "black", fontWeight: "normal", fontSize: 10 }}>
          The Ministry of Health and Family Welfare (MoHFW), Government of India
          has launched the Anemia Mukt Bharat Program for accelerating decline
          in anemia prevalence and to achieve the POSHAN Abhiyaan (2018-2020)
          target of reducing prevalence of anemia among children 6-59 months,
          adolescents and women of reproductive age 15-49 years by 3 percentage
          points per year. The AMB aims to provide preventive and curative
          mechanisms through a 6x6x6 strategy including six target
          beneficiaries, six interventions and six institutional mechanisms, for
          all stakeholders to implement the strategy.
        </Text>
        <Text style={{ color: "black", fontWeight: "normal", fontSize: 10 }}>
          The AMB Score Card is developed with an objective to provide
          information on coverage of IFA supplementation across selected target
          beneficiaries to support assessment of progress and performance of the
          program. The AMB Score Card is based on data from Health Management
          Information System (HMIS) and AMB Dashboard. The rankings are
          calculated as the mean coverage of IFA supplementation across selected
          target beneficiaries. State/UT are ranked in descending order of the
          mean coverage whereby State/UT with best performance is ranked first.
        </Text>

        <Text style={{ color: "black", fontWeight: "normal", fontSize: 10 }}>
          This scorecard is prepared by AMB TST of Institute of Economic Growth
          in collaboration with UNICEF.
        </Text>
      </View>

      <Text
        style={{
          textAlign: "center",
          color: "black",
          fontWeight: "normal",
          fontSize: 10,
        }}
      >
        Anemia Mukt Bharat Dashboard:{" "}
        <Text style={{ color: "blue" }}>https://anemiamuktbharat.info</Text>
      </Text>
    </>
  );
};

export default AboutSection;
