import React from "react";
import { FileUploader } from "react-drag-drop-files";
import styles from "./fileDropResource.module.css";

function FileDrop({ onFileChange, fileTypes, required, disabled }) {
  return (
    <FileUploader
      handleChange={onFileChange}
      name="file"
      types={fileTypes}
      required={required}
      hoverTitle="Drop file here!"
      dropMessageStyle={{
        backgroundColor: "#069edb",
        opacity: 0.9,
        color: "white",
      }}
      disabled={disabled}
      classes={styles.layout}
    />
  );
}

export default FileDrop;
