import React, { useState } from "react";

import SuccessPage from "components/reports/auth/SuccessPage";
import ForgotPasswordForm from "components/reports/auth/forgot-password/ForgotPassword";
import VerifyForgotOTP from "components/reports/auth/forgot-password/VerifyForgotOTP";
import ChangePassword from "components/reports/auth/forgot-password/ChangePassword";

import SnackBar from "utils/SnackBar";

import API from "services/api";

function DenominatorsForgotPassword() {
  const [activeStep, setActiveStep] = useState(0);

  const [selectedState, setSelectedState] = useState("");

  const [email, setEmail] = useState("");
  const [verifyCodeID, setVerifyCodeID] = useState(null);

  const [isValid, setIsValid] = useState(true);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const text = "Password has been reset!";

  const handleClose = () => {
    setShow(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(email);

    setIsValid(isValidEmail);
  };

  const handleEmailChange = (e) => {
    validateEmail(e.target.value);
    setEmail(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleOTPGenerate = async () => {
    try {
      if (isValid) {
        const response = await API.generateOTP(email);
        if (response.statusCode === 200) {
          setVerifyCodeID(response.data.verifyCodeId);
          setActiveStep(1);
        } else {
          setShow(true);
          setMessage({
            text: "OTP Generation Failed! Please try again...",
            type: "error",
          });
        }
      }
    } catch (error) {}
  };

  switch (activeStep) {
    case 0:
      return (
        <div className="container mx-auto">
          <SnackBar
            message={message?.text}
            open={show}
            handleClose={handleClose}
            type={message?.type}
          />

          <ForgotPasswordForm
            activeStep={activeStep}
            email={email}
            onEmailChange={handleEmailChange}
            onOTPGenerate={handleOTPGenerate}
            selectedState={selectedState}
            onChange={handleStateChange}
          />
        </div>
      );

    case 1:
      return (
        <VerifyForgotOTP
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          email={email}
          verifyCodeId={verifyCodeID}
        />
      );

    case 2:
      return (
        <>
          <SnackBar
            message={message?.text}
            open={show}
            handleClose={handleClose}
            type={message?.type}
          />
          <ChangePassword
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            selectedState={selectedState}
            setShow={setShow}
            setMessage={setMessage}
          />
        </>
      );

    case 3:
      return <SuccessPage activeStep={activeStep} text={text} />;

    default:
  }
}

export default DenominatorsForgotPassword;
