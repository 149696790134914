import React from "react";
import styles from "./DataTable.module.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "components/ranking/pdf-generator/PDFTemplate";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";

const headersRanking = [
  { label: "Location", key: "stateDistrict" },
  {
    label: "HMIS: 9.9- Percentage of children (6-59 months)",
    key: "months6_59IfaPercentO",
  },
  {
    label: "HMIS: 23.1 & 23.3- Percentage of Children (5-9 yrs)",
    key: "years6_9IfaPercentP",
  },
  {
    label: "HMIS: 22.1.1 & 22.1.3- Percentage of adolescents (10-19 years)",
    key: "adols10_15IfaPercentH",
  },
  {
    label: "HMIS: 1.2.4- Percentage of Pregnant Women",
    key: "pregWomenIfaPercentC",
  },
  {
    label: "HMIS: 6.3- Percentage of lactating mothers",
    key: "motherIfaPercentF",
  },
  {
    label: "Index Value (%)",
    key: "indexValue",
  },
  {
    label: "State Rank",
    key: "rankState",
  },
  {
    label: "District Rank",
    key: "rankDist",
  },
];

const RankingHeader = ({
  queryData,
  months,
  dataAsOnDate,
  kpiDataTable,
  dataName,
  currentComponent,
}) => {
  const isMonthlyRanking = currentComponent === "Ranking_Monthly";
  const fileName = isMonthlyRanking
    ? `AMB Score Card (Monthly) - ${queryData?.stateCountry}_${
        queryData.startYear === queryData.endYear
          ? queryData?.startYear
          : `${queryData?.startYear} - ${queryData?.endYear}`
      }`
    : `AMB Score Card (Cumulative) - ${queryData?.stateCountry}_${
        queryData.startYear === queryData.endYear
          ? queryData?.startYear
          : `${queryData?.startYear} - ${queryData?.endYear}`
      }`;

  return (
    <>
      {isMonthlyRanking ? (
        <div className={styles.headerTableSection}>
          AMB Score Card - India (
          {queryData?.length !== 0 &&
            (queryData.startYear === queryData.endYear
              ? queryData.startMonth === queryData.endMonth
                ? `${months[queryData.startMonth - 1]} ${queryData.startYear}`
                : `${months[queryData.startMonth - 1]} - ${
                    months[queryData.endMonth - 1]
                  } ${queryData.startYear}`
              : `${months[queryData.startMonth - 1]} ${queryData.startYear} - ${
                  months[queryData.endMonth - 1]
                } ${queryData.endYear}`)}
          )<p className={styles.dataAsOnDate}>Data as on {dataAsOnDate}*</p>
        </div>
      ) : (
        <div className={styles.headerTableSection}>
          State-wise Cumulative List of IFA Indicators (
          {queryData?.length !== 0 ? (
            `${months[queryData?.startMonth - 1]}
                  ${queryData?.startYear}
                 - ${months[queryData?.endMonth - 1]} ${queryData?.endYear}`
          ) : (
            <span></span>
          )}
          )<p className={styles.dataAsOnDate}>Data as on {dataAsOnDate}*</p>
        </div>
      )}

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <p id={styles["amb__ranking-monthly-perf_title"]}>
          IFA Coverage – Key Performance Indicators, HMIS
        </p>
        <hr style={{ border: "2px solid #069edb" }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: 5,
          }}
        >
          {kpiDataTable && kpiDataTable?.length !== 0 && (
            <>
              <PDFDownloadLink
                document={
                  <PdfDocument
                    dataAsOnDate={dataAsOnDate}
                    dataName={dataName}
                    kpiDataTable={kpiDataTable}
                    queryData={queryData}
                    currentComponent={currentComponent}
                  />
                }
                fileName={fileName}
                style={{
                  textDecoration: "none",
                  padding: 5,
                  fontSize: 16,
                  textAlign: "right",
                  alignSelf: "flex-end",
                  color: "#069edb",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                }}
              >
                {({ loading }) =>
                  loading ? (
                    <Button
                      style={{ borderColor: "#105bab", color: "#069edb" }}
                      startIcon={<PictureAsPdfRoundedIcon />}
                      variant="outlined"
                    >
                      Loading PDF...
                    </Button>
                  ) : (
                    <Button
                      style={{ borderColor: "#105bab", color: "#069edb" }}
                      startIcon={<PictureAsPdfRoundedIcon />}
                      variant="outlined"
                    >
                      Download PDF
                    </Button>
                  )
                }
              </PDFDownloadLink>
              <CSVLink
                data={kpiDataTable}
                headers={headersRanking}
                filename={fileName}
              >
                <Button
                  startIcon={<AttachFileRoundedIcon />}
                  style={{ borderColor: "#105bab", color: "#069edb" }}
                  variant="outlined"
                >
                  Export CSV
                </Button>
              </CSVLink>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RankingHeader;
