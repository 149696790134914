import { useState } from "react";

import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import styles from "pages/admin/uploadSheet.module.css";

import API from "services/api";

import SelectYear from "utils/SelectYear";
import FileDrop from "utils/FileDrop";
import SnackBar from "utils/SnackBar";

function DrugRequirementSheet() {
  const [excelSheet, setExcelSheet] = useState();
  const [selectedDate, setSelectedDate] = useState(null);

  const [isUploading, setIsUploading] = useState(false);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleExcelSheetUpload = (file) => {
    setExcelSheet(file);
  };

  const handleReset = () => {
    setExcelSheet(null);
    setSelectedDate(null);
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleUploadClick = async (e) => {
    e.preventDefault();

    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", excelSheet, excelSheet.name);

    const selectedYear = parseInt(selectedDate.format("YYYY"));

    try {
      const response = await API.uploadDrugRequirements(selectedYear, formData);
      if (response.statusCode === 200) {
        setIsUploading(false);
        setShow(true);
        setMessage({
          text: `Drug Requirements File for year: ${selectedYear} uploaded successfully!`,
          type: "success",
        });
      } else if (response.success === true) {
        setIsUploading(false);
        setShow(true);
        setMessage({
          text: `${response.message} File not uploaded!`,
          type: "warning",
        });
      } else {
        setIsUploading(false);
        setShow(true);
        setMessage({
          text: `File not uploaded!`,
          type: "error",
        });
      }
    } catch (error) {
      setIsUploading(false);
      setShow(true);
      setMessage({
        text: `File not uploaded! Something went wrong...`,
        type: "error",
      });
    }
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.row}>
          <h6 className={styles.subHeading}>
            Upload Denominator Sheet for Drug Requirements
          </h6>
          <form onSubmit={handleUploadClick} className={styles.form}>
            <div className={styles.rowInputFile}>
              <FileDrop onFileChange={handleExcelSheetUpload} />
              <a
                className="sampleLink"
                href="https://nceara.anemiamuktbharat.info/Sample+Excels/Denominator_Drug_Requirement_20XX_20XX.xlsx">
                Sample Drug Requirement File
              </a>
            </div>

            <div className={styles.calendarRow}>
              <label className={styles.label}> Select Year</label>
              <SelectYear
                value={selectedDate}
                onYearChange={handleDateChange}
                fullWidth={true}
              />
            </div>

            <div className="button-group">
              <Button
                style={{ backgroundColor: "#069edb" }}
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                onClick={handleUploadClick}
                disabled={!selectedDate || !excelSheet || isUploading}
                className={
                  !selectedDate || !excelSheet || isUploading
                    ? styles.disabledButton
                    : styles.button
                }>
                {isUploading ? "Uploading..." : "Upload"}
              </Button>

              <Button
                variant="contained"
                onClick={handleReset}
                style={{ backgroundColor: "#069edb" }}
                startIcon={<AutorenewIcon />}>
                Reset
              </Button>
            </div>
          </form>
        </div>
      </LocalizationProvider>
    </>
  );
}

export default DrugRequirementSheet;
