import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";
import {
  About,
  Beneficiaries,
  IFAStock,
  AMBRanking,
  KPI,
  ResourceAdmin,
  ResourcePage,
  ContactUs,
  ErrorPage,
  AdminPanel,
  UploadDenominators,
  UploadNumerators,
  DrugRequirements,
  DeleteExcelUploads,
  Login,
  Register,
  ForgotPassword,
  CountAdmin,
  StrategyPage,
} from "./pages";

import { HomeLayout, AdminLayout } from "./containers/Layout";
import AuthProvider, { useAuth } from "./context/AuthContext";
import DenominatorsLogin from "pages/reports/denominators/DenominatorsLogin";
import DenominatorsRegister from "pages/reports/denominators/DenominatorsRegister";
import DenominatorsForgotPassword from "pages/reports/denominators/DenominatorsForgotPassword";
import DenominatorsHome from "pages/reports/denominators/DenominatorsHome";
import DenominatorsLayout from "containers/DenominatorsLayout";
import WorldSituation from "pages/situation/WorldSituation";
import IndiaSituation from "pages/situation/IndiaSituation";
import StateSituation from "pages/situation/StateSituation";

const parseJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const rawBytes = new TextDecoder().decode(
      Uint8Array.from(atob(base64), (c) => c.charCodeAt(0))
    );
    return JSON.parse(rawBytes);
  } catch (e) {
    return null;
  }
};

function AppRouter() {
  const { token, removeToken, stateToken } = useAuth();

  useEffect(() => {
    const handleLogout = () => {
      removeToken();
    };

    if (token) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt?.exp * 1000 < Date.now()) {
        handleLogout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const ProtectedRoute = ({ children }) => {
    if (!token) {
      return <Navigate to="/auth/login" replace={true} />;
    }

    return children;
  };

  const StateUserRoute = ({ children }) => {
    if (!stateToken && stateToken !== "") {
      return <Navigate to="/reports/denominators/login" replace={true} />;
    }

    return children;
  };

  return (
    <>
      <Router basename="/">
        <Routes>
          <Route exact path="/">
            <Route
              index
              element={
                <HomeLayout>
                  <App />
                </HomeLayout>
              }
            />
            <Route path="programme">
              <Route
                index
                path="about"
                element={
                  <HomeLayout>
                    <About />
                  </HomeLayout>
                }
              />
              <Route
                path="6x6x6-strategy"
                element={
                  <HomeLayout>
                    <StrategyPage />
                  </HomeLayout>
                }
              />
              <Route
                path="beneficiaries"
                element={
                  <HomeLayout>
                    <Beneficiaries />
                  </HomeLayout>
                }
              />
            </Route>

            <Route path="anemia-situation">
              <Route
                path="global"
                element={
                  <HomeLayout>
                    <WorldSituation />
                  </HomeLayout>
                }
              />

              <Route
                path="india"
                element={
                  <HomeLayout>
                    <IndiaSituation />
                  </HomeLayout>
                }
              />

              <Route
                path="state"
                element={
                  <HomeLayout>
                    <StateSituation />
                  </HomeLayout>
                }
              />
            </Route>

            <Route path="reports">
              <Route
                path="key-performance-indicators"
                element={
                  <HomeLayout>
                    <KPI />
                  </HomeLayout>
                }
              />
              <Route
                path="ranking"
                element={
                  <HomeLayout>
                    <AMBRanking />
                  </HomeLayout>
                }
              />
              <Route
                path="stock"
                element={
                  <HomeLayout>
                    <IFAStock />
                  </HomeLayout>
                }
              />
              <Route path="denominators">
                <Route
                  path="login"
                  element={
                    <HomeLayout>
                      <DenominatorsLogin />
                    </HomeLayout>
                  }
                />
                <Route
                  path="get-credentials"
                  element={
                    <HomeLayout>
                      <DenominatorsRegister />
                    </HomeLayout>
                  }
                />
                <Route
                  path="home"
                  element={
                    <StateUserRoute>
                      <DenominatorsLayout>
                        <DenominatorsHome />
                      </DenominatorsLayout>
                    </StateUserRoute>
                  }
                />
                <Route
                  path="forgot-password"
                  element={
                    <HomeLayout>
                      <DenominatorsForgotPassword />
                    </HomeLayout>
                  }
                />
              </Route>
            </Route>

            <Route
              path="resources/home"
              element={
                <HomeLayout>
                  <ResourcePage />
                </HomeLayout>
              }
            />

            <Route
              path="contact/home"
              element={
                <HomeLayout>
                  <ContactUs />
                </HomeLayout>
              }
            />

            <Route path="auth" element={<HomeLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="forgotPassword" element={<ForgotPassword />} />
            </Route>

            <Route path="*" element={<ErrorPage />} />
          </Route>

          <Route
            exact
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              path="home"
              element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              }
            />
            <Route
              path="upload-resources"
              element={
                <ProtectedRoute>
                  <ResourceAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              path="upload-drug-requirements"
              element={
                <ProtectedRoute>
                  <DrugRequirements />
                </ProtectedRoute>
              }
            />
            <Route
              path="upload-denominators"
              element={
                <ProtectedRoute>
                  <UploadDenominators />
                </ProtectedRoute>
              }
            />
            <Route
              path="upload-numerator"
              element={
                <ProtectedRoute>
                  <UploadNumerators />
                </ProtectedRoute>
              }
            />
            <Route
              path="delete-uploads"
              element={
                <ProtectedRoute>
                  <DeleteExcelUploads />
                </ProtectedRoute>
              }
            />
            <Route
              path="count-admin"
              element={
                <ProtectedRoute>
                  <CountAdmin />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </React.StrictMode>
);
