import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MenuItem, Select } from "@mui/material";

import WorldMap from "components/situation/WorldMap";
import styles from "components/situation/map.module.css";

import Loader from "utils/Loader";
import PageHeader from "utils/PageHeader";
import { SituationIndicatorsGlobal } from "constants";

import API from "services/api";
import SelectYear from "utils/SelectYear";
import { colorScaleLegend } from "components/situation/colorScale";
import SituationTable from "components/situation/SituationTable";

const WorldSituation = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs("2019"));
  const [selectedIndicator, setSelectedIndicator] = useState("allWomen15_49");

  const handleIndicatorChange = (e) => {
    setSelectedIndicator(e.target.value);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        setLoadingStatus(true);
        const response = await API.fetchAnemiaSituationGlobal(
          selectedYear?.format("YYYY")
        );

        if (response.success && response.data) {
          let mapDataCalculated = {};

          setTableData(response.data);

          response.data?.forEach((data) => {
            mapDataCalculated[data.country ? data.country : "No Country"] =
              data[selectedIndicator];
          });

          setMapData(mapDataCalculated);
        } else {
          setMapData([]);
        }
      } catch (error) {
      } finally {
        setLoadingStatus(false);
      }
    };

    fetchMapData();
  }, [selectedYear, selectedIndicator]);

  const columnDefs = [
    {
      field: "country",
      headerName: "Country",
      width: 300,
      headerClassName: "table-header",
      renderCell: (params) => {
        return (
          <div className={params.field === "country" && styles.firstColumn}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "year",
      headerName: "Year",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "child6_59",
      headerName: "Children 6-59 Months",
      width: 220,
      headerClassName: "table-header",
    },

    {
      field: "nonPreg15_49",
      headerName: "Non-Pregnant Women",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "preg15_59",
      headerName: "Pregnant Women",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "allWomen15_49",
      headerName: "All Womens (15-49 Yrs)",
      width: 220,
      headerClassName: "table-header",
    },
  ];

  return (
    <>
      <PageHeader text="Anemia Situation - Global" />
      <div className={`container-fluid ${styles.situationContainer}`}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={styles.filters}>
            <SelectYear
              disabled={true}
              onYearChange={handleYearChange}
              defaultValue={selectedYear}
            />

            <Select
              sx={{ width: "100%" }}
              name="Select Indicator"
              id="select-indicator"
              value={selectedIndicator}
              onChange={handleIndicatorChange}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              required
            >
              <MenuItem disabled value="">
                Select Indicator
              </MenuItem>

              {SituationIndicatorsGlobal.map((indicator) => (
                <MenuItem
                  key={indicator.indicatorKey}
                  value={indicator.indicatorKey}
                >
                  {indicator.indicatorValue}
                </MenuItem>
              ))}
            </Select>
          </div>
        </LocalizationProvider>
        {loadingStatus ? (
          <Loader loading={loadingStatus} />
        ) : (
          <div className={`${styles.mapContainer} pt-5`}>
            <div className={styles.mapContentWorld}>
              <WorldMap mapDataCalculated={mapData} />
            </div>

            <div className={styles.mapRightContainer}>
              <div className={styles.colorScaleContainer}>
                {colorScaleLegend.map((item, index) => (
                  <div className={styles.colorScale} key={index}>
                    <div
                      className={styles.colorScaleDots}
                      style={{
                        border: "1px solid black",
                        backgroundColor: item.color,
                      }}
                    />
                    <span>{item.label}</span>
                  </div>
                ))}
              </div>

              <p>
                <b>Source:</b> World Health Organization (WHO)
                <br />
                <a
                  rel="noreferrer"
                  href="https://www.who.int/data/gho/data/indicators"
                  target="_blank"
                >
                  https://www.who.int/data/gho/data/indicators
                </a>
              </p>
            </div>
          </div>
        )}

        <SituationTable
          columns={columnDefs}
          loading={loadingStatus}
          tableData={tableData}
          getRowId={(row) => {
            return row.country;
          }}
        />
      </div>
    </>
  );
};

export default WorldSituation;
