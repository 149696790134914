import React from "react";
import TableHeader from "./TableHeader";
import "pages/stock/monthly/Cumulative.css";

function StockHeader({ text, image }) {
  return (
    <>
      <div className="table-body">
        <div className="table-heading-container">
          <TableHeader image={image} text={text} />
        </div>
      </div>
    </>
  );
}

export default StockHeader;
