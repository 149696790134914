import React from "react";
import "./footerNCEAR.css";

function FooterNCEAR() {
  return (
    <section id="contact">
      <footer id="footer-container">
        <div className="container ncear-footer">
          <h2 className="footer-header-text">
            Contact Us
            <div className="bottom-border"></div>
          </h2>

          <div className="logos-all-footer">
            <a href="https://anemiamuktbharat.info/">
              <img
                className="footer-ncer-logo"
                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691680981/Project_A/LogoAmb1_syna7x.png"
                alt="Anemia Mukt Bharat Logo"
              />
            </a>
            <a href="https://main.mohfw.gov.in/">
              <img
                className="footer-ncer-logo"
                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691682555/MOHFW_pmmtan.png"
                alt="Ministry of Health and Family Welfare, Government of India Logo"
              />
            </a>
            <a href="https://niti.gov.in/">
              <img
                className="footer-ncer-logo"
                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691682083/NITI_Aayog_logo.svg_cmmo69.png"
                alt="Niti Ayog Logo"
              />
            </a>
            <a href="https://www.aiims.edu/index.php?lang=en">
              <img
                className="footer-ncer-logo"
                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691680971/Project_A/AIIMS_New_Delhi_rttqlz.png"
                alt="AIIMS, New Delhi Logo"
              />
            </a>
            <a href="https://www.unicef.org/">
              <img
                className="footer-ncer-logo"
                style={{ width: "auto", height: "45px" }}
                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691680973/Project_A/UNICEFLogo_hf13f4.png"
                alt="UNICEF Logo"
              />
            </a>
            <a href="https://poshanabhiyaan.gov.in/">
              <img
                className="footer-ncer-logo"
                style={{ width: "auto", height: "90px" }}
                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691682278/poshan_gkolhm.png"
                alt="Poshan Abhiyan Logo"
              />
            </a>
          </div>

          <div className="social-footer">
            <p className="footer-text my-auto">Social Links:</p>
            <a href="https://twitter.com/a_ncear?s=20">
              <img
                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691682762/twitter_yyzo9b.png"
                alt=""
                className="social-icon-ncear"
              />
            </a>
            <a href="https://www.facebook.com/nceara.aiimsnewdelhi/">
              <img
                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691682762/facebook_vxhe46.png"
                alt=""
                className="social-icon-ncear"
              />
            </a>
          </div>

          <div className="address-container">
            <div className="address">
              <p className="footer-text">NCEAR-A Office Address:</p>
              <p className="footer-sub-text">
                National Centre of Excellence and Advanced Research on Anemia
                Control, Room no. 38, Centre for Community Medicine, All India
                Institute of Medical Sciences (AllMS), Ansari Nagar, New
                Delhi-110029
              </p>
            </div>
            <div style={{ width: "100%" }}>
              <iframe
                title="NCEAR-A Office Address"
                width="100%"
                height="300"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=National%20Centre%20of%20Excellence%20and%20Advanced%20Research%20on%20Anemia%20Control,%20Room%20no.%2038,%20Centre%20for%20Community%20Medicine,%20All%20India%20Institute%20of%20Medical%20Sciences%20(AllMS),%20Ansari%20Nagar,%20New%20Delhi-110029+(NCEAR-A%20Address)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>

          <div className="contact-container">
            <div className="contacts">
              <p className="footer-text my-auto">Email:</p>
              <a href="mailto:dr.kapilyadav@gmail.com">
                dr.kapilyadav@gmail.com
              </a>
            </div>

            <div className="contacts">
              <p className="footer-text my-auto">Phone:</p>
              <a href="tel:+91-1126593848">+91-1126593848</a>
            </div>
          </div>
        </div>

        <hr style={{ width: "100%", margin: "20px 0", color: "white" }} />

        <div className="copyright container">
          <p>
            &copy; Copyright @2023. National Centre of Excellence and Advanced
            Research on Anemia Control (NCEAR-A), AIIMS, New Delhi. All Rights
            Reserved.
          </p>
        </div>
      </footer>
    </section>
  );
}

export default FooterNCEAR;
