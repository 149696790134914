import React from "react";

import PageHeader from "utils/PageHeader";

import "./About.css";
import twitterIcon from "assets/twitter_red.jpeg";
import YouTubeIcon from "assets/youtube.jpeg";
import API from "services/api";
import { useEffect } from "react";

function About() {
  useEffect(() => {
    async function CountStatic() {
      try {
        await API.getCountStatic();
      } catch (error) {}
    }
    CountStatic();
  }, []);

  return (
    <>
      <div className="boxContainer">
        <PageHeader text="About Anemia Mukt Bharat" />
        <div className="about-container container">
          <div className="content">
            <div>
              <p>
                The reduction of anemia is a crucial objective of the POSHAN
                Abhiyaan launched in March 2018. Complying with the targets of
                POSHAN Abhiyaan and National Nutrition Strategy set by NITI
                Aayog, the Anemia Mukt Bharat was launched by the Ministry of
                Health and Family Welfare in 2018.
              </p>
              <br />
              <p>
                It uses the 6X6X6 strategy and aims to reduce the prevalence of
                anemia among various groups, including children (6-59 months),
                children (5-9 years), adolescents (10-19 years), pregnant women,
                lactating women, and women in reproductive age (15-49 years).
              </p>
              <br />

              <h4 className="sub-heading-aboutPage">
                Anemia Mukt Bharat Interventions:
              </h4>
              <ol>
                <li>Prophylactic Iron and Folic Acid supplementation</li>
                <li>Deworming</li>
                <li>
                  Intensified year-round Behaviour Change Communication Campaign
                  focusing on four key behaviors:
                </li>
                <ul>
                  <li>
                    Improving compliance to Iron Folic Acid supplementation and
                    deworming
                  </li>
                  <li>Appropriate infant and young child feeding practices</li>
                  <li>
                    Increase in intake of iron-rich food through diet
                    diversity/quantity/frequency and/or fortified foods with
                    focus on harnessing locally available resources
                  </li>
                  <li>
                    Ensuring delayed cord clamping after delivery (by 3 minutes)
                    in health facilities
                  </li>
                </ul>
                <li>
                  Testing and treatment of anemia, using digital methods and
                  point of care treatment, with special focus on pregnant women
                  and school-going adolescents
                </li>
                <li>
                  Mandatory provision of Iron and Folic Acid fortified foods in
                  government-funded public health programs
                </li>
                <li>
                  Intensifying awareness, screening, and treatment of
                  non-nutritional causes of anemia in endemic pockets, with
                  special focus on malaria, haemoglobinopathies, and fluorosis
                </li>
              </ol>

              <h4 className="sub-heading-aboutPage">
                Anemia Mukt Bharat Institutional Mechanisms:
              </h4>
              <ol>
                <li>Intra-Ministerial Coordination</li>
                <li>National Anemia Mukt Bharat Unit</li>
                <li>National Centre of Excellences</li>
                <li>Convergence with other Ministries</li>
                <li>Strengthening Supply Chain and Logistics</li>
                <li>
                  Anemia Mukt Bharat Dashboard and Digital Portal - One-Stop
                  Shop on Anemia
                </li>
              </ol>
            </div>
          </div>

          <div className="social-icons">
            <a
              href="https://twitter.com/amb_ieg"
              target="_blank"
              rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCBcME2WtmOXkaSOSZratYhw"
              target="_blank"
              rel="noopener noreferrer">
              <img src={YouTubeIcon} alt="Youtube" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
