import React from "react";
import FixedHeader from "./header/FixedHeader";
import Footer from "./footer/Footer";
import { Outlet } from "react-router-dom";

function HomeLayout(props) {
  return (
    <>
      <FixedHeader />
      <main>{props.children}</main>
      <Outlet />
      <Footer />
    </>
  );
}

function AdminLayout() {
  return <Outlet />;
}

export { HomeLayout, AdminLayout };
