export const linksArray = [
  {
    to: "/programme/about",
    icon: require("assets/icons/About.jpg"),
    alt: "AMB",
    cardTitle: "About AMB",
    cardBody: "Information about AMB Programme",
  },
  {
    to: "/reports/key-performance-indicators",
    icon: require("assets/icons/Coverage.jpg"),
    alt: "AMB",
    cardTitle: "IFA Coverage",
    cardBody: "IFA syrup and tablets (Red, Blue and Pink)",
  },
  {
    to: "/reports/stock",
    icon: require("assets/icons/Stock.png"),
    alt: "AMB",
    cardTitle: "Stock Status",
    cardBody: "IFA Stock Received, Distributed and Available",
  },
  {
    to: "/reports/denominators",
    icon: require("assets/SVGs/denominators.svg").default,
    alt: "AMB",
    cardTitle: "Denominators",
    cardBody: "State and District wise AMB denominators",
  },
  {
    to: "/reports/ranking",
    icon: require("assets/icons/Score Card.jpg"),
    alt: "AMB",
    cardTitle: "Score Card",
    cardBody: "State and District level AMB Scorecard",
  },
  {
    to: "/resources/home",
    icon: require("assets/SVGs/resources.svg").default,
    alt: "AMB",
    cardTitle: "Resources",
    cardBody: "Guideline, Training Toolkit, IEC/BCC, Printing Materials",
  },
];
