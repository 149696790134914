import { MenuItem, Select } from "@mui/material";
import styles from "components/reports/DataQueryForm.module.css";
import React from "react";
import { years } from "constants";

function SelectYearLabel({ selectedYearLabel, onYearLabelChange }) {
  return (
    <>
      <Select
        fullWidth
        type="text"
        className={styles["form-input"]}
        name="quarter"
        id="quarter"
        value={selectedYearLabel}
        onChange={onYearLabelChange}
        inputProps={{ "aria-label": "Without label" }}
        displayEmpty
        required
      >
        <MenuItem disabled value="">
          Select Year
        </MenuItem>
        {years.map((year) => (
          <MenuItem className="dropdown-item" key={year.id} value={year.value}>
            {year.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default SelectYearLabel;
