import React, { useState } from "react";

import SuccessPage from "components/auth/SuccessPage";
import ForgotPasswordForm from "components/ForgotPassword/ForgotPassword";
import VerifyForgotOTP from "components/ForgotPassword/VerifyForgotOTP";
import ChangePassword from "components/ForgotPassword/ChangePassword";

import SnackBar from "utils/SnackBar";

import API from "services/api";

function ForgotPassword() {
  const [activeStep, setActiveStep] = useState(0);

  const [email, setEmail] = useState("");
  const [verifyCodeID, setVerifyCodeID] = useState(null);

  const [isValid, setIsValid] = useState(true);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const text = "Password has been reset!";

  const handleClose = () => {
    setShow(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(email);

    setIsValid(isValidEmail);
  };

  const handleEmailChange = (e) => {
    validateEmail(e.target.value);
    setEmail(e.target.value);
  };

  const handleOTPGenerate = async () => {
    try {
      if (isValid) {
        const response = await API.generateOTP(email);
        if (response.statusCode === 200) {
          setVerifyCodeID(response.data.verifyCodeId);
          setActiveStep(1);
        } else {
          setShow(true);
          setMessage({
            text: "OTP Generation Failed! Please try again...",
            type: "error",
          });
        }
      }
    } catch (error) {}
  };

  switch (activeStep) {
    case 0:
      return (
        <>
          <div className="container mx-auto">
            <SnackBar
              message={message?.text}
              open={show}
              handleClose={handleClose}
              type={message?.type}
            />

            <ForgotPasswordForm
              activeStep={activeStep}
              email={email}
              onEmailChange={handleEmailChange}
              onOTPGenerate={handleOTPGenerate}
            />
          </div>
        </>
      );

    case 1:
      return (
        <VerifyForgotOTP
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          email={email}
          verifyCodeId={verifyCodeID}
        />
      );

    case 2:
      return (
        <ChangePassword
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          email={email}
        />
      );

    case 4:
      return <SuccessPage activeStep={activeStep} text={text} />;

    default:
  }
}

export default ForgotPassword;
