import React from "react";
import "./TableHeader.css";

function TableHeader({ image, text }) {
  return (
    <>
      <div className="table-heading">
        <img src={image} alt={text} className="table-heading-image" />
        <div className="table-heading-text">{text}</div>
      </div>
    </>
  );
}

export default TableHeader;
