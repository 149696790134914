import { createContext, useContext, useMemo, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState(localStorage.getItem("accessToken"));
  const [stateToken, setStateToken_] = useState(
    localStorage.getItem("stateUserToken")
  );

  // Function to set the authentication token
  const setToken = (newToken) => {
    localStorage.setItem("accessToken", newToken);
    setToken_(newToken);
  };

  // Function to set the authentication token
  const setStateToken = (newToken) => {
    localStorage.setItem("stateUserToken", newToken);
    setStateToken_(newToken);
  };

  const removeToken = () => {
    localStorage.removeItem("accessToken");
    setToken_(null);
  };

  const removeStateToken = () => {
    localStorage.removeItem("stateUserToken");
    setStateToken_(null);
  };

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      removeToken,
      stateToken,
      setStateToken,
      removeStateToken,
    }),
    [stateToken, token]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
