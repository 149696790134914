import { useAuth } from "context/AuthContext";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import API from "services/api";

import styles from "./auth.module.css";
import PasswordInput from "utils/PasswordInput";
import SnackBar from "utils/SnackBar";

const ChangePassword = ({ visible, showChangePassword }) => {
  const { stateToken, removeStateToken } = useAuth();

  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleOldPasswordChange = (e) => {
    setPasswords({ ...passwords, oldPassword: e.target.value });
  };

  const handleNewPasswordChange = (e) => {
    setPasswords({ ...passwords, newPassword: e.target.value });
  };

  const handleToggleOldPassword = () => setShowOldPassword((show) => !show);
  const handleToggleNewPassword = () => setShowNewPassword((show) => !show);

  const handleResetPassword = async () => {
    try {
      if (passwords.oldPassword && passwords.newPassword) {
        const response = await API.changeStateUserPassword({
          state: stateToken,
          password: passwords.oldPassword,
          updatedPassword: passwords.newPassword,
        });

        if (response?.success) {
          setShow(true);
          setMessage({
            text: "Password changed successfully, logging out, please login again",
            type: "success",
          });

          setTimeout(() => {
            removeStateToken();
          }, 1000);
        } else {
          setShow(true);
          setMessage({
            text: response?.message,
            type: "error",
          });
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <Modal show={visible} onHide={showChangePassword} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleResetPassword} className={styles.formContainer}>
            <div className={styles.formGroup}>
              <PasswordInput
                label="Old Password"
                name="oldPassword"
                placeholder="Enter old password"
                showPassword={showOldPassword}
                onShowPassword={handleToggleOldPassword}
                value={passwords.oldPassword}
                onChange={handleOldPasswordChange}
              />
            </div>

            <div className={styles.formGroup}>
              <PasswordInput
                label="New Password"
                name="newPassword"
                placeholder="Enter new password"
                showPassword={showNewPassword}
                onShowPassword={handleToggleNewPassword}
                value={passwords.newPassword}
                onChange={handleNewPasswordChange}
              />
            </div>

            <Button
              variant="primary"
              className={styles.button}
              onClick={handleResetPassword}
            >
              Submit
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={showChangePassword}
            style={{ backgroundColor: "#069edb", borderColor: "#069edb" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangePassword;
