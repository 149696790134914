import { useEffect } from "react";

import ContactInfoCard from "components/connect/ContactInfoCard";
import ContactQueryCard from "components/connect/ContactQueryCard";

import "./ContactUs.css";
import API from "services/api";

const ContactUs = () => {
  useEffect(() => {
    async function CountStatic() {
      try {
        await API.getCountStatic();
      } catch (error) {}
    }
    CountStatic();
  }, []);

  return (
    <div className="contact-main-container container-fluid">
      <ContactInfoCard />
      <ContactQueryCard />
    </div>
  );
};

export default ContactUs;
