import React from "react";

import API from "services/api";
import { useEffect } from "react";
import PageHeader from "utils/PageHeader";

import styles from "./strategy.module.css";
import { Tooltip } from "@mui/material";

const StrategyPage = () => {
  useEffect(() => {
    async function CountStatic() {
      try {
        await API.getCountStatic();
      } catch (error) {}
    }
    CountStatic();
  }, []);

  const interventionsData = [
    {
      title: "Prophylactic Iron Folic Acid Supplementation",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Interventions_1.jpg"),
      description:
        "A key intervention is to give IFA to children, adolescents and women of reproductive age and pregnant women irrespective of anemia, under Anemia Mukt Bharat.",
    },
    {
      title: "Deworming",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Interventions_2.jpg"),
      description:
        "Bi-annual mass deworming for children in the age groups between 1-19 years is carried out on designated dates every year under National Deworming Day (NDD) programme. The Anemia Mukt Bharat, also integrates deworming of women of reproductive age and for pregnant women as part of the NDD strategy.",
    },
    {
      title:
        "Intensified year-round Behaviour Change Communication Campaign (Solid Body, Smart Mind) including ensuring delayed cord clamping",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Interventions_3.jpg"),
      description:
        "AMB focus on four key behaviors – (a) Improving compliance to Iron Folic Acid supplementation and deworming, (b) Appropriate infant and young child feeding practices, (c) Increase in intake of iron-rich food through diet diversity/quantity/frequency and/or fortified foods with focus on harnessing locally available resources and (d) Ensuring delayed cord clamping after delivery (by 3 minutes) in health facilities. A comprehensive communication package for the strategy is available for use by the States which may be adapted as per requirement. These can be downloaded from the Anemia Mukt Bharat portal: www.anemiamuktbharat.info",
    },
    {
      title:
        "Testing of Anemia using digital methods and point of care treatment",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Interventions_4.jpg"),
      description:
        "Screening and testing of anemia are important in all age groups so that appropriate treatment may be initiated as per the hemoglobin level of the individual. AMB recommends use of the advanced technologies for the available haemoglobin estimation such as invasive digital haemoglobinometers.",
    },
    {
      title:
        "Mandatory Provision of Iron Folic Acid fortified foods in public health programmes",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Interventions_5.jpg"),
      description:
        "The Government of India has mandated the use of fortified salt, wheat flour and oil in foods served under Integrated Child Development Services (ICDS) and Mid-day Meal (MDM) schemes to address micronutrient deficiencies. In addition, all health facility-based programmes where food is being provided are mandated to provide fortified wheat, rice (with iron, folic acid and vitamin B12), double-fortified salt (with iodine and iron), and oil (with vitamin A and D) as per standards for fortification of staple foods (salt, wheat, rice, milk, and oil) prescribed and notified by the Food Safety and Standard Authority of India (FSSAI, 2016).",
    },
    {
      title:
        "Addressing non-nutritional causes of anemia in endemic pockets, with special focus on malaria, haemoglobinopathies, and fluorosis",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Interventions_6.jpg"),
      description:
        "The strategy attempts to intensify awareness and integrate screening and treatment for the following non-nutritional causes of anemia with a special focus on malaria, hemoglobinopathies, and fluorosis.",
    },
  ];

  const institutionalMechanisms = [
    {
      title: "National Anemia Mukt Bharat Unit",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Institutional Mechanism_1.png"),
      description:
        "At National Level AMB should be implemented through all health programs across life cycle.",
    },
    {
      title: "Intra Ministerial Coordinaion",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Institutional Mechanism_2.png"),
      description:
        "A National Unit will be established to support and for strategy implementation. The State/UT should establish a state unit and designate a nodal officer. The State Unit will ensure accountability and effective implementation of the strategy. At the district level, under the supervision of the CMO/CDHO, the District Programme Officer under the National Health Mission will be in charge of the day-to-day implementation of this strategy and the BMO/BHO will be the nodal officer at the block level.",
    },
    {
      title: "Strengthening Supply Chain and Logistics",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Institutional Mechanism_3.png"),
      description:
        "National Center of Excellence and Advanced Research for Anemia (NCEARA) housed at AIIMS, New Delhi, and AMB Technical Support Unit (AMB-TSU) at the Institute of Economic Growth (IEG) were established to provide support on research, training, information systems, supply chain, rapid assessments and monitoring of the program.",
    },
    {
      title: "Convergence with Other Ministries",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Institutional Mechanism_4.png"),
      description:
        "An Executive Committee under the Chairpersonship of Secretary, MoWCD, has been established via Government of India Letter No. NNM/50/2017-WBP8 dated 3 January 2018, wherein the Secretary, MoHFW, is a member. Therefore, all issues related to Anemia Mukt Bharat for which inter-ministerial convergence is needed should be discussed during this committee meeting.",
    },
    {
      title:
        "National Centre of Excellence and Advanced Research on Anemia Control",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Institutional Mechanism_5.png"),
      description:
        "The strategy plans to build upon the learning of implementing the National Iron Plus Initiative and Weekly Iron Folic Supplementation programs and focuses on strengthening the procurement and supply chain mechanisms as these are key to the effective implementation of these programs. Therefore, special emphasis is suggested for streamlining the supply chain mechanisms.",
    },
    {
      title:
        "Anemia Mukt Bharat Dashboard and Digital Portal-One Stop Shop for Anemia",
      imgSrc: require("assets/All 18 Icons for 6x6x6 Strategy/Institutional Mechanism_6.png"),
      description:
        "The Anemia Mukt Bharat portal (www.anemiamuktbharat.info) is a one-stop shop of materials developed under the Anemia Mukt Bharat strategy in one place, such as communication resource material, survey data, targets, state and district-wise denominators, and state and district-wise quarterly progress reports. All of these resources can be efficiently utilized by policymakers, program managers, and medical corporation/procurement agencies for planning, implementing, and monitoring the strategy.",
    },
  ];

  return (
    <>
      <PageHeader text="Anemia Mukt Bharat  6X6X6 Strategy" />
      <div className={`container ${styles.contentContainer}`}>
        <br />
        <div className={styles.topHeader}>
          <img
            src={require("assets/coverage.png")}
            alt="Target-AMB"
            className={styles.targetImage}
            style={{ width: "1000px", height: "560px" }} // Adjust the width and height as needed
          />
        </div>

        <div className={styles.image}>
          <h4
            className={styles.subHeading}
            style={{ backgroundColor: "#2D767D" }}
          >
            6 Beneficiaries
          </h4>
          <br />
          <img
            height={320}
            src={require("assets/All 18 Icons for 6x6x6 Strategy/6 Beneficiaries.png")}
            alt="6 Beneficiaries"
          />
        </div>

        <div className={styles.footer}>
          <div className={styles.image}>
            <h4
              className={styles.subHeading}
              style={{ backgroundColor: "#F8B03B" }}
            >
              6 Interventions
            </h4>

            <div className={styles.footerCardsLeft}>
              {interventionsData.map((data, rowIndex) => (
                <Tooltip title={data.description} arrow>
                  <div key={rowIndex} className={styles.footerCardLeft}>
                    <span>{data.title}</span>
                    <img
                      className={styles.icon}
                      src={data.imgSrc}
                      alt={data.title}
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>

          <div className={styles.image}>
            <h4
              className={styles.subHeading}
              style={{ backgroundColor: "#F0583A" }}
            >
              6 Institutional Mechanisms
            </h4>
            <div className={styles.footerCardsRight}>
              {institutionalMechanisms.map((data, rowIndex) => (
                <Tooltip title={data.description} arrow>
                  <div key={rowIndex} className={styles.footerCardRight}>
                    <span>{data.title}</span>
                    <img
                      className={styles.icon}
                      src={data.imgSrc}
                      alt={data.title}
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StrategyPage;
