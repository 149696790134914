import React, { useEffect, useState } from "react";
import ProgressSteps from "components/reports/auth/ProgressSteps";
import SnackBar from "utils/SnackBar";

import styles from "./Login.module.css";

import { Button } from "@mui/material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";

import API from "services/api";
import PinField from "react-pin-field";

function VerifyOTP({
  activeStep,
  setActiveStep,
  email,
  selectedState,
  verifyCodeId,
  registrationData,
  onResend,
}) {
  const [verificationCode, setVerificationCode] = useState("");

  const [counter, setCounter] = useState(30);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleVerificationCodeChange = (code) => {
    if (code.length <= 6) {
      setVerificationCode(code);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCounter((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }, 1000);
  }, [counter]);

  const handleVerifyOTP = async () => {
    try {
      const response = await API.verifyOTP(
        email,
        verificationCode,
        verifyCodeId
      );
      if (response.success) {
        const registrationResponse = await API.createStateUser({
          ...registrationData,
          state: selectedState,
        });

        if (registrationResponse.success) {
          setShow(true);
          setMessage({
            text: "State User created, please set password...",
            type: "success",
          });
          setActiveStep(3);
        }
      } else {
        setShow(true);
        setMessage({
          text: "OTP not verified, please enter correct OTP!",
          type: "error",
        });
      }
    } catch (error) {}
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <div className={styles.container}>
        <div className={styles.formCard}>
          <ProgressSteps step={activeStep} />

          <div className="d-flex gap-1  flex-wrap flex-row w-full">
            <b>OTP sent at: </b> <div>{registrationData?.email}</div>
          </div>

          <form onSubmit={handleVerifyOTP} className={styles.loginForm}>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Verification Code
                <span style={{ color: "red" }}> *</span>
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 5,
                  width: "100%",
                }}
              >
                <PinField
                  onChange={(code) => handleVerificationCodeChange(code)}
                  length={6}
                  className={styles.otpInput}
                />
              </div>
            </div>

            <Button
              variant="primary"
              style={{ backgroundColor: "#069edb", color: "white" }}
              startIcon={<MarkEmailReadRoundedIcon />}
              onClick={onResend}
              disabled={counter === 0}
              className={counter === 0 ? styles.disabledButton : styles.button}
            >
              Resend OTP {counter !== 0 && `(${counter})`}
            </Button>

            <Button
              variant="primary"
              style={{ backgroundColor: "#069edb", color: "white" }}
              startIcon={<VerifiedRoundedIcon />}
              onClick={handleVerifyOTP}
              disabled={!verificationCode}
              className={
                !verificationCode ? styles.disabledButton : styles.button
              }
            >
              Verify & Register
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default VerifyOTP;
