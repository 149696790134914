import React, { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import SnackBar from "utils/SnackBar";
import SelectLocation from "utils/SelectLocation";
import ViewButton from "utils/ViewButton";

import API from "services/api";

import styles from "./DataQueryForm.module.css";
import CheckBox from "utils/CheckBox";

const DataQueryFormMonthly = ({ setQueryData, setKpiDataFiltered }) => {
  const [checkedValue, setCheckedValue] = useState("month");
  const [selectedDate, setSelectedDate] = useState();

  const [stateList, setStateList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState({ text: "", type: "" });

  const handleClose = () => {
    setShow(false);
  };

  const handleReset = () => {
    setSelectedDate(null);
    setStartDate("");
    setEndDate("");
    setSelectedLocation("");
  };

  useEffect(() => {
    const getStateList = async () => {
      try {
        const states = await API.getAllStates(
          checkedValue === "month"
            ? selectedDate?.format("YYYY")
            : startDate.format("YYYY")
        );
        setStateList(states);
      } catch (error) {}
    };

    getStateList();
  }, [checkedValue, endDate, selectedDate, startDate]);

  const handleStateChange = (e) => {
    setSelectedLocation(e?.target.value);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked !== checkedValue) {
      setQueryData({
        stateCountry: "",
        startYear: "",
        startMonth: "",
        endYear: "",
        endMonth: "",
        normalized: "TRUE",
      });
    }

    const { checked, value } = event.target;
    setCheckedValue(checked ? value : "");
  };

  const submitFormHandler = (e) => {
    e.preventDefault();

    if (!selectedLocation) {
      setShow(true);
      setModalText({
        text: "Select a location to continue!",
        type: "warning",
      });

      return;
    }

    if (checkedValue === "range") {
      if (!startDate || !endDate) {
        setShow(true);
        setModalText({
          text: "Start date and End date cannot be empty!",
          type: "warning",
        });
        return;
      } else if (startDate.isAfter(endDate)) {
        setShow(true);
        setModalText({
          text: "Start date cannot be after end date.",
          type: "warning",
        });
        return;
      } else {
        setQueryData({
          stateCountry: selectedLocation.toUpperCase(),
          startYear: parseInt(startDate?.format("YYYY")),
          startMonth: parseInt(startDate?.format("M")),
          endYear: parseInt(endDate?.format("YYYY")),
          endMonth: parseInt(endDate?.format("M")),
          normalized: "TRUE",
        });

        setShow(true);
        setModalText({
          text: "Form Submitted! Loading the data...",
          type: "success",
        });
      }
    } else {
      if (!selectedDate) {
        setShow(true);
        setModalText({
          text: "Select year and month to see details!",
          type: "warning",
        });
      }

      setKpiDataFiltered([]);
      setQueryData({
        stateCountry: selectedLocation?.toUpperCase(),
        startYear: parseInt(selectedDate?.format("YYYY")),
        startMonth: parseInt(selectedDate?.format("M")),
        endYear: parseInt(selectedDate?.format("YYYY")),
        endMonth: parseInt(selectedDate?.format("M")),
        normalized: "TRUE",
      });

      setShow(true);
      setModalText({
        text: "Form Submitted! Loading the data...",
        type: "success",
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form className={styles.form} onSubmit={submitFormHandler}>
        <div className={styles.formGroupContainer}>
          <div className={styles.checkboxFormGroup}>
            <CheckBox
              option1="month"
              option2="range"
              label1="Select Month"
              label2="Select Range"
              checkedValue={checkedValue}
              onCheckBoxChange={handleCheckboxChange}
            />
          </div>

          {checkedValue === "month" && (
            <div className={styles.singleMonth}>
              <div className={styles.formGroup}>
                <label htmlFor="" className={styles.formLabel}>
                  Select Year and Month
                </label>
                <DatePicker
                  value={selectedDate}
                  defaultValue={dayjs()}
                  views={["month", "year"]}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  minDate={dayjs("2017")}
                  maxDate={dayjs()}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="location" className={styles.formLabel}>
                  Select Location
                </label>
                <SelectLocation
                  stateList={stateList}
                  selectedState={selectedLocation}
                  onLocationChange={handleStateChange}
                />
              </div>
            </div>
          )}

          {checkedValue === "range" && (
            <>
              <div className={styles.datesGroup}>
                <div className={styles.formGroup}>
                  <label htmlFor="" className={styles.formLabel}>
                    Start Date
                  </label>
                  <DatePicker
                    value={startDate}
                    views={["month", "year"]}
                    onChange={(newValue) => setStartDate(newValue)}
                    minDate={dayjs("2017-01")}
                    maxDate={dayjs()}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="month" className={styles.formLabel}>
                    End Date
                  </label>
                  <DatePicker
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    views={["month", "year"]}
                    minDate={dayjs("2017-01")}
                    maxDate={dayjs()}
                  />
                </div>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="location" className={styles.formLabel}>
                  Select Location
                </label>
                <SelectLocation
                  stateList={stateList}
                  selectedState={selectedLocation}
                  onLocationChange={handleStateChange}
                />
              </div>
            </>
          )}
        </div>

        <div className={`${styles.formGroup} ${styles.centerRow}`}>
          <ViewButton onView={submitFormHandler} onReset={handleReset} />
        </div>
      </form>
      <SnackBar
        message={modalText.text}
        open={show}
        handleClose={handleClose}
        type={modalText.type}
      />
    </LocalizationProvider>
  );
};

export default DataQueryFormMonthly;
