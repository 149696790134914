import React, { useState, useEffect } from "react";

import AdminPanelLayout from "components/admin/AdminPanelLayout";
import CountAdminGraphs from "components/admin/CountAdmin/CountAdminGraphs";
import CountAdminTables from "components/admin/CountAdmin/CountAdminTables";

import API from "services/api";

import styles from "./countAdmin.module.css";

import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function CountAdminPage() {
  const [selectedDate, setSelectedDate] = useState();
  const [indiaStates, setIndiaStates] = useState([]);
  const [otherCountries, setOtherCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCountryCount = async () => {
      try {
        setLoading(true);
        const countResponse = await API.getCountAdmin({
          year:
            new Date(selectedDate).getFullYear() ?? new Date().getFullYear(),
          month:
            new Date(selectedDate).getMonth() + 1 ?? new Date().getMonth() + 1,
        });

        const indiaStatesData = [];
        const otherCountriesData = [];

        countResponse?.forEach((item, index) => {
          const { country, state, ...counts } = item;
          if (country === "India") {
            indiaStatesData?.push({ id: `india-${index}`, state, ...counts });
          } else {
            otherCountriesData?.push({
              id: `other-${index}`,
              country,
              state,
              ...counts,
            });
          }
        });

        setIndiaStates(indiaStatesData);
        setOtherCountries(otherCountriesData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getCountryCount();
  }, [selectedDate]);

  return (
    <AdminPanelLayout>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Select Month and Year</label>
          <DatePicker
            value={selectedDate}
            defaultValue={dayjs()}
            views={["month", "year"]}
            onChange={(newValue) => setSelectedDate(newValue)}
            minDate={dayjs("2017")}
            maxDate={dayjs()}
          />
        </div>
      </LocalizationProvider>
      <CountAdminTables
        indiaStatesData={indiaStates}
        otherCountriesData={otherCountries}
        loading={loading}
      />
      <CountAdminGraphs
        indiaStatesData={indiaStates}
        otherCountriesData={otherCountries}
      />
    </AdminPanelLayout>
  );
}

export default CountAdminPage;
