import React from "react";
import ResourceAdmin from "./ResourceAdmin";

function AdminPanel() {
  return (
    <>
      <ResourceAdmin />
    </>
  );
}

export default AdminPanel;
