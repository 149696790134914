import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import HeaderPDF from "./HeaderPDF";
import ColorTable from "./ColorTable";
import TableHeader from "./TableHeader";
import Tables from "./Tables";
import AboutSection from "./AboutSection";
import Footer from "./FooterPDF";

import stockTableDataMonthly from "data/StocksTableData";

import DMSans from "assets/fonts/DMSans-Medium.ttf";

Font.register({
  family: "DMSans",
  src: DMSans,
});

const styles = StyleSheet.create({
  page: { flex: 1, fontFamily: "DMSans", padding: 20 },
  container: {
    marginBottom: 20,
  },
  subHeading: {
    backgroundColor: "#069edb",
    textAlign: "center",
    color: "white",
    fontSize: 14,
    fontWeight: "500",
    padding: 5,
    marginVertical: 12,
  },
  date: {
    fontSize: 8,
    textAlign: "right",
    alignSelf: "flex-end",
    fontFamily: "DMSans",
    fontWeight: "medium",
  },
});

export function PdfDocument({
  queryData,
  stocksDataMaternalCalcium,
  stocksDataIFARed,
  stocksDataIFABlue,
  stocksDataIFAPink,
  stocksDataSyrupPaediatric,
  stocksDataAlbendazole,
  component,
  dataAsOnDate,
}) {
  return (
    <Document>
      <Page style={styles.page} size="A4">
        <Text
          style={{
            width: "100%",
            backgroundColor: "#069edb",
            color: "white",

            fontSize: 18,
            fontFamily: "DMSans",
            textAlign: "center",
          }}
        >
          INDIA
        </Text>
        <View style={styles.container}>
          <HeaderPDF queryData={queryData} />

          <ColorTable />

          <Text style={styles.date}>Data as on date: {dataAsOnDate}</Text>

          <TableHeader
            text="Material Calcium"
            image="https://res.cloudinary.com/daxilgrvn/image/upload/v1692689736/Project_A/kpi-card-img-1_eaqmap.jpg"
          />

          <Tables
            stocksData={stocksDataMaternalCalcium}
            component={component}
          />

          <View style={styles.subHeading}>
            <Text>Stock KPI (AMB)</Text>
          </View>

          {stockTableDataMonthly.map((data) => {
            let stocksDataName;

            if (data.indicator === "HMIS 19.6") {
              stocksDataName = stocksDataIFARed;
            } else if (data.indicator === "HMIS 19.7") {
              stocksDataName = stocksDataIFABlue;
            } else if (data.indicator === "HMIS 19.8") {
              stocksDataName = stocksDataIFAPink;
            } else if (data.indicator === "HMIS 19.9") {
              stocksDataName = stocksDataSyrupPaediatric;
            } else if (data.indicator === "HMIS 19.16") {
              stocksDataName = stocksDataAlbendazole;
            }
            return (
              <React.Fragment key={data.indicator}>
                <TableHeader text={data.text} image={data.image} />
                <Tables stocksData={stocksDataName} component={component} />
              </React.Fragment>
            );
          })}
        </View>

        <AboutSection />

        <Footer />
      </Page>
    </Document>
  );
}
