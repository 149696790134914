import React, { useState, useEffect } from "react";

import { MenuItem, Select, TextField, Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import imageCompression from "browser-image-compression";

import { Categories } from "constants";
import API from "services/api";
import FileDrop from "./FileDrop";

import "./uploadResources.css";

import SnackBar from "utils/SnackBar";

function UploadResource() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [folderName, setFolderName] = useState("");

  const [newCategory, setNewCategory] = useState("");
  const [newFolderName, setNewFolderName] = useState("");

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [folderOptions, setFolderOptions] = useState([]);

  const [selectedFile, setSelectedFile] = useState("");
  const [isAudio, setIsAudio] = useState(false);
  const [isZip, setIsZip] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  function refreshPage() {
    window.location.reload(true);
  }

  const handleClose = () => {
    setShow(false);
    if (message.text === "File Uploaded Successfully!") {
      setButtonDisabled(true);
      setTimeout(() => {
        refreshPage();
      }, 1000);
    }
  };

  useEffect(() => {
    async function fetchCategories() {
      const categories = await API.getAllCategories();
      setCategoryOptions(categories);
    }

    fetchCategories();
  }, []);

  useEffect(() => {
    async function fetchCategories() {
      if (selectedCategory) {
        const decodedCategory = decodeURIComponent(selectedCategory);

        const folderNamesByCategory = await API.getFoldersByCategory(
          decodedCategory
        );

        let temp = [];
        for (let index = 0; index < folderNamesByCategory?.length; index++) {
          temp.push({
            id: index,
            name: folderNamesByCategory[index],
          });
        }
        setFolderOptions(temp);
      }
    }

    fetchCategories();
  }, [selectedCategory]);

  const handleFileChange = (file) => {
    if (file) {
      const fileName = file.name;
      const fileExtensionName = fileName.split(".").pop();

      if (
        fileExtensionName === "mp3" ||
        fileExtensionName === "aac" ||
        fileExtensionName === "wav" ||
        fileExtensionName === "m4a"
      ) {
        setIsAudio(true);
      } else {
        if (fileExtensionName === "zip") {
          setIsZip(true);
        } else {
          setIsZip(false);
        }
        setIsAudio(false);
      }
    }

    setSelectedFile(file);
  };

  function imageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Read the image file as a Data URL
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      // Read the image file
      reader.readAsDataURL(file);
    });
  }

  const handleIconChange = async (file) => {
    const options = {
      maxSizeMB: 0.098,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    let base64String = "";

    try {
      const compressedFile = await imageCompression(file, options);

      base64String = await imageToBase64(compressedFile);
      setSelectedIcon(base64String);
    } catch (error) {
      setSelectedIcon(base64String);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value.toString());
  };

  const handleFolderChange = (e) => {
    setFolderName(e.target.value.toString());
  };

  const handleNewCategory = (e) => {
    const newCategoryValue = e.target.value;
    setNewCategory(newCategoryValue);
  };

  const handleAddCategory = (e) => {
    if (newCategory.trim() !== "") {
      const newCategoryObject = {
        id: Categories.length + 1,
        category: { name: newCategory, extensions: "" },
      };
      Categories.push(newCategoryObject);
      if (categoryOptions) {
        setCategoryOptions([...categoryOptions, newCategory]);
      } else {
        setCategoryOptions([newCategory]);
      }
      setNewCategory("");
    }
  };

  const handleNewFolderName = (e) => {
    const newFolderNameValue = e.target.value;
    setNewFolderName(newFolderNameValue);
  };

  const handleAddFolderName = (e) => {
    if (newFolderName.trim() !== "") {
      const newFolderId = folderOptions.length + 1;
      const newFolder = { id: newFolderId, name: newFolderName };
      const updatedFolderOptions = [...folderOptions, newFolder];
      const sortedFolderOptions = updatedFolderOptions.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setFolderOptions(sortedFolderOptions);
      setNewFolderName("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const icon = selectedIcon.toString();

    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("icon", icon);

    if (
      !(
        selectedCategory &&
        folderName &&
        selectedFile &&
        (selectedIcon || isAudio || isZip)
      )
    ) {
      setShow(true);
      setMessage({
        text: "Please select a category, folder, upload file, and upload icon.",
        type: "warning",
      });
      return;
    } else {
      setIsUploading(true);

      const cleanedSelectedCategory = selectedCategory.trim();
      const cleanedFolderName = folderName.trim();

      try {
        setButtonDisabled(true);
        const response = await API.uploadResource(
          formData,
          cleanedSelectedCategory,
          cleanedFolderName
        );

        if (response.statusCode === 200) {
          setIsUploading(false);
          setShow(true);
          setMessage({ text: "File Uploaded Successfully!", type: "success" });
          setButtonDisabled(false);
        } else if (response.statusCode !== 200) {
          setIsUploading(false);
          setShow(true);
          setMessage({
            text: `Upload Failed! ${response.message}`,
            type: "error",
          });
        }
      } catch (error) {
        setIsUploading(false);
        setShow(true);
        setMessage({ text: "Some Error Occurred!", type: "error" });
      }
    }
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <div className="row-resourceUpload">
        <form
          method="post"
          className="form-resourceUpload"
          autoFocus
          onSubmit={handleSubmit}>
          <div className="category-container">
            <div className="resourceUpload-dropdown">
              <label>
                Select Category <span className="required-star">*</span>
              </label>
              <Select
                fullWidth
                size="small"
                type="text"
                name="All Categories"
                id="category"
                required
                value={selectedCategory}
                onChange={handleCategoryChange}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty>
                <MenuItem disabled value="">
                  Select Category
                </MenuItem>
                {categoryOptions?.map((option) => {
                  return (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <p>or</p>

            <div className="resourceUpload-input-new">
              <TextField
                size="small"
                label="New Category Name"
                variant="outlined"
                type="text"
                name="newCategory"
                id="new-category"
                value={newCategory}
                onChange={handleNewCategory}
                InputLabelProps={{ shrink: true }}
              />
              <button onClick={handleAddCategory}>
                <i className="bi bi-plus-lg"></i>Add Category
              </button>
            </div>
          </div>

          <div className="folder-container">
            {selectedCategory && (
              <>
                <div className="resourceUpload-dropdown">
                  <label>
                    Select Folder <span className="required-star">*</span>
                  </label>
                  <Select
                    fullWidth
                    size="small"
                    type="text"
                    name="folderName"
                    id="folder"
                    required
                    value={folderName}
                    onChange={handleFolderChange}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty>
                    <MenuItem disabled value="">
                      Select Folder
                    </MenuItem>
                    {folderOptions?.map((option) => {
                      return (
                        <MenuItem value={option.name} key={option.id}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <p>or</p>

                <div className="resourceUpload-input-new">
                  <TextField
                    size="small"
                    label="New Folder Name"
                    variant="outlined"
                    type="text"
                    name="newFolderName"
                    id="new-folder-name"
                    value={newFolderName}
                    onChange={handleNewFolderName}
                    InputLabelProps={{ shrink: true }}
                  />
                  <button onClick={handleAddFolderName}>
                    <i className="bi bi-plus-lg"></i> Add Folder
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="upload-resources-button-group">
            <div style={{ marginBottom: 10 }}>
              <label htmlFor="input-file" className="file-label">
                Upload File *
              </label>
              <FileDrop onFileChange={handleFileChange} required={true} />
            </div>

            <div>
              <label htmlFor="input-icon" className="file-label">
                Upload Icon *
              </label>
              <FileDrop
                disabled={isAudio || isZip}
                onFileChange={handleIconChange}
                fileTypes={["png", "jpg", "jpeg", "svg"]}
                required={false}
              />
              {(isAudio || isZip) && (
                <Typography
                  variant="body"
                  display="block"
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#069edb",
                    paddingTop: 1,
                  }}>
                  Audio or Zip File? Don't upload icon!
                </Typography>
              )}
            </div>
          </div>
        </form>

        <div className="text-center mx-auto upload-button-resources">
          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={handleSubmit}
            style={{ backgroundColor: "#069edb", color: "white" }}
            sx={{
              width: { xs: "100%" },
            }}
            disabled={
              !(
                selectedCategory &&
                folderName &&
                selectedFile &&
                (selectedIcon || isAudio || isZip)
              ) ||
              buttonDisabled ||
              isUploading
            }
            className={
              !(
                selectedCategory &&
                folderName &&
                selectedFile &&
                (selectedIcon || isAudio || isZip)
              ) ||
              buttonDisabled ||
              isUploading
                ? "resource-disabled-button"
                : "resource-upload-button"
            }>
            {isUploading ? "Uploading..." : "Upload to Server"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default UploadResource;
