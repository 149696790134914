import React, { useState } from "react";

import { ResourceContainer, ResourceDrawer } from "components";
import ScrollToTop from "utils/ScrollToTop";
import PageHeader from "utils/PageHeader";

import "./Resources.css";

function ResourcePage() {
  const [category, setCategory] = useState();
  return (
    <>
      <div className="resources-container">
        <PageHeader text="Resources" />

        <div className="content-resources container mx-auto">
          <div className="left-part">
            <ResourceDrawer setCategory={setCategory} />
          </div>

          <div className="right-container">
            <div className="right-part">
              <ResourceContainer category={category} />
            </div>
          </div>
        </div>

        <ScrollToTop />
      </div>
    </>
  );
}

export default ResourcePage;
