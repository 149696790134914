import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import SnackBar from "utils/SnackBar";
import LoginForm from "components/auth/LoginForm";

import API from "services/api";

import { useAuth } from "context/AuthContext";

function LoginAdmin() {
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const captchaRef = useRef(null);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const { setToken } = useAuth();

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleLogin = async () => {
    let token = captchaRef?.current?.getValue();
    captchaRef?.current?.reset();

    if (token) {
      try {
        let valid_token = await API.verifyCaptchaToken(token);

        if (valid_token === true) {
          try {
            const response = await API.login(loginData);

            if (response.success === true) {
              setShow(true);
              setMessage({
                text: "Login Success! Navigating to Admin Panel...",
                type: "success",
              });

              setToken(response.data.accessToken);
              localStorage.setItem("username", response.data.username);

              setTimeout(() => {
                navigate("/admin/home");
              }, 2000);
            } else if (response.success === false) {
              setShow(true);
              setMessage({
                text: "Login Failed! Wrong Credentials...",
                type: "error",
              });
            }
          } catch (error) {
            setShow(true);
            setMessage({
              text: "Login Failed! Something went wrong...",
              type: "error",
            });
          }
        } else {
          setMessage({
            text: "Login Failed! Captcha Verification Failed...",
            type: "error",
          });
        }
      } catch (error) {
        setShow(true);
        setMessage({
          text: "Login Failed! Captcha Verification Failed...",
          type: "error",
        });
      }
    }
  };

  const handleReset = () => {
    setLoginData({ username: "", password: "" });
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />
      <div className="container mx-auto">
        <LoginForm
          onSubmit={handleLogin}
          onReset={handleReset}
          onChange={handleInputChange}
          onShowPassword={handleClickShowPassword}
          showPassword={showPassword}
          loginData={loginData}
          captchaRef={captchaRef}
        />
      </div>
    </>
  );
}

export default LoginAdmin;
