import React, { useEffect, useState } from "react";
import ProgressSteps from "components/auth/ProgressSteps";
import SnackBar from "utils/SnackBar";

import styles from "./Login.module.css";

import { Button } from "@mui/material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";

import API from "services/api";
import TextInput from "utils/TextInput";

import PinField from "react-pin-field";

function VerifyOTP({
  activeStep,
  setActiveStep,
  email,
  verifyCodeId,
  registrationData,
  onResend,
}) {
  const [verificationCode, setVerificationCode] = useState("");
  const [secretCode, setSecretCode] = useState();

  const [counter, setCounter] = useState(30);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleVerificationCodeChange = (code) => {
    if (code.length <= 6) {
      setVerificationCode(code);
    }
  };

  const handleSecretCodeChange = (e) => {
    setSecretCode(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setCounter((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }, 1000);
  }, [counter]);

  const handleVerifyOTP = async () => {
    try {
      const response = await API.verifyOTP(
        email,
        verificationCode,
        verifyCodeId
      );
      if (response.statusCode === 200) {
        try {
          const registrationResponse = await API.register(registrationData);

          if (registrationResponse.statusCode === 200) {
            setShow(true);
            setMessage({
              text: "Registration Success!",
              type: "success",
            });
            setActiveStep(3);
          } else if (registrationResponse.statusCode === 400) {
            setShow(true);
            setMessage({
              text: `Registration Failed! ${registrationResponse.data}`,
              type: "error",
            });
          } else {
            setShow(true);
            setMessage({
              text: "Registration Failed! Something went wrong...",
              type: "error",
            });
          }
        } catch (error) {
          setShow(true);
          setMessage({
            text: "Registration Failed! Something went wrong...",
            type: "error",
          });
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <div className={styles.container}>
        <div className={styles.formCard}>
          <ProgressSteps step={activeStep} />

          <form onSubmit={handleVerifyOTP} className={styles.loginForm}>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Verification Code
                <span style={{ color: "red" }}> *</span>
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                  width: "100%",
                }}>
                <PinField
                  onChange={(code) => handleVerificationCodeChange(code)}
                  length={6}
                  className={styles.otpInput}
                />
              </div>
            </div>

            <Button
              variant="primary"
              style={{ backgroundColor: "#069edb", color: "white" }}
              startIcon={<MarkEmailReadRoundedIcon />}
              onClick={onResend}
              disabled={counter === 0}
              className={counter === 0 ? styles.disabledButton : styles.button}>
              Resend OTP {counter !== 0 && `(${counter})`}
            </Button>

            <div className={styles.formGroup}>
              <TextInput
                label="Secret AMB Code"
                name="secret-code"
                placeholder="Secret Code"
                icon={<KeyRoundedIcon style={{ color: "#069edb" }} />}
                value={secretCode}
                onChange={handleSecretCodeChange}
              />
            </div>

            <Button
              variant="primary"
              style={{ backgroundColor: "#069edb", color: "white" }}
              startIcon={<VerifiedRoundedIcon />}
              onClick={handleVerifyOTP}
              disabled={secretCode !== "AMB-UNICEF" || !verificationCode}
              className={
                secretCode !== "AMB-UNICEF" || !verificationCode
                  ? styles.disabledButton
                  : styles.button
              }>
              Verify & Register
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default VerifyOTP;
