import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import SelectLocation from "utils/SelectLocation";
import SelectYearLabel from "utils/SelectYearLabel";

import "pages/reports/raw-data/denominators/Denominators.css";
import { Search } from "@mui/icons-material";
import { useAuth } from "context/AuthContext";

function DenominatorsForm({
  onSubmit,
  onYearLabelChange,
  selectedYear,
  stateList,
  selectedLocation,
  onLocationChange,
  selectedType,
  onTypeChange,
}) {
  const { stateToken } = useAuth();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form className="form form-container" onSubmit={onSubmit}>
        <div className="form-group-container">
          <div className="form-group">
            <label htmlFor="" className="form-label">
              Select Year
            </label>
            <SelectYearLabel
              onYearLabelChange={onYearLabelChange}
              selectedYearLabel={selectedYear}
            />
          </div>

          {stateToken === "INDIA" ? (
            <div className="states-group">
              <label htmlFor="location" className="form-label">
                Select Location
              </label>
              <SelectLocation
                stateList={stateList}
                selectedState={selectedLocation}
                onLocationChange={onLocationChange}
              />
            </div>
          ) : (
            <div className="states-group">
              <label htmlFor="" className="form-label">
                Select Location
              </label>
              <Select
                fullWidth
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                name="location"
                id="location"
                onChange={onLocationChange}
                value={selectedLocation}
                required
              >
                <MenuItem disabled value="">
                  Select Location
                </MenuItem>

                <MenuItem value="INDIA">INDIA</MenuItem>
                <MenuItem value={stateToken}>{stateToken}</MenuItem>
              </Select>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="" className="form-label">
              Select Type
            </label>
            <div className="radio-container">
              <div className="radio-button">
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Target Beneficiary"
                  name="Type"
                  id="true"
                  checked={selectedType === "1"}
                  onChange={onTypeChange}
                />
              </div>

              <div className="radio-button">
                <FormControlLabel
                  control={<Radio />}
                  label="Drug Requirement"
                  type="radio"
                  name="Type"
                  id="false"
                  value="2"
                  checked={selectedType === "2"}
                  onChange={onTypeChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group center-row">
          <Button variant="contained" startIcon={<Search />} onClick={onSubmit}>
            Search Data
          </Button>
        </div>
      </form>
    </LocalizationProvider>
  );
}

export default DenominatorsForm;
