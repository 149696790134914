import React from "react";
import ProgressSteps from "./ProgressSteps";

import { Button } from "@mui/material";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";

import styles from "./Success.module.css";
import { useNavigate } from "react-router-dom";

function SuccessPage({ activeStep, text, steps }) {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.formCard}>
        <div style={{ padding: "20px" }}>
          <ProgressSteps step={activeStep} />
        </div>

        <div className={styles.successContainer}>
          <p className={styles.successText}>
            {text}
            <br />
            Login to access Admin Panel!
          </p>
        </div>

        <div style={{ padding: "20px" }}>
          <Button
            variant="primary"
            style={{ backgroundColor: "#069edb", color: "white" }}
            endIcon={<LoginRoundedIcon />}
            onClick={() => navigate("/auth/login")}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
