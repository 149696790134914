import React from "react";
import styles from "./DataTable.module.css";
import Loader from "utils/Loader";

import KPIHeader from "./KPIHeader";
import LegendTable from "./LegendTable";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import RankingMonthlyHeader from "./RankingHeader";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const quarters = [
  "April - June",
  "July - September",
  "October - December",
  "January - March",
];

const DataTable = ({
  kpiDataTable,
  queryData,
  dataName,
  currentComponent,
  dataAsOnDate,
  loadingStatus,
}) => {
  return (
    <div className={styles["table-main-container"]}>
      {(currentComponent === "Ranking_Monthly" ||
        currentComponent === "Ranking_Quarterly") && (
        <RankingMonthlyHeader
          months={months}
          dataAsOnDate={dataAsOnDate}
          kpiDataTable={kpiDataTable}
          dataName={dataName}
          queryData={queryData}
          currentComponent={currentComponent}
        />
      )}

      {(currentComponent === "KPI_Monthly" ||
        currentComponent === "KPI_Quarterly") && (
        <KPIHeader
          months={months}
          quarters={quarters}
          dataAsOnDate={dataAsOnDate}
          kpiDataTable={kpiDataTable}
          dataName={dataName}
          queryData={queryData}
        />
      )}

      <LegendTable queryData={queryData} />

      {loadingStatus ? (
        <Loader loading={loadingStatus} />
      ) : (
        <div className={styles["table-container"]}>
          {currentComponent === "KPI_Monthly" ||
          currentComponent === "KPI_Quarterly" ? (
            <p>*data quality issues</p>
          ) : (
            <p style={{ textAlign: "center" }}>
              <b>Normalized:</b> The percentage coverage above 95% are
              normalized to 95%. 95% is considered as the ceiling value for HMIS
              coverage for States and UTs.
            </p>
          )}
          <table className={styles["table"]}>
            <TableHeader dataName={dataName} queryData={queryData} />
            <TableBody
              kpiDataTable={kpiDataTable}
              dataName={dataName}
              queryData={queryData}
            />
          </table>
        </div>
      )}
    </div>
  );
};

export default DataTable;
