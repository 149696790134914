import { Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

import DMSans from "assets/fonts/DMSans-Medium.ttf";

Font.register({
  family: "DMSans",
  src: DMSans,
});

const styles = StyleSheet.create({
  tableHeading: {
    width: "100%",
    marginTop: "2%",
    marginHorizontal: "0.05%",
    flexDirection: "row",
    alignItems: "center",
  },
  tableHeadingImage: {
    width: 50,
    borderRadius: 25,
    height: 50,
    marginTop: -2,
    verticalAlign: "middle",
    borderWidth: 5,
    borderColor: "#069edb",
    alignSelf: "flex-end",
  },
  tableHeadingText: {
    fontWeight: "500",
    color: "#069edb",
    fontSize: 13,
    fontFamily: "DMSans",
  },
  textContainer: {
    flex: 1,
    borderBottomWidth: 2,
    borderBottomColor: "#069edb",
  },
});

function TableHeader({ text, image }) {
  return (
    <View style={styles.tableHeading}>
      <View style={styles.textContainer}>
        <Text style={styles.tableHeadingText}>{text}</Text>
        <View style={styles.borderLine}></View>
      </View>
      <Image source={{ uri: image }} style={styles.tableHeadingImage} />
    </View>
  );
}

export default TableHeader;
