export const getPDFColorClass = (normalized, percentValue) => {
  let colorClass = "colorVeryLowCell";
  if (percentValue >= 25 && percentValue < 50) colorClass = "colorLowCell";
  else if (percentValue >= 50 && percentValue < 75)
    colorClass = "colorMediumCell";
  else if (percentValue >= 75) colorClass = "colorHighCell";

  if (normalized === true && percentValue >= 95) {
    colorClass = "colorNormalizedCell";
    percentValue = 95;
  }
  return colorClass;
};
