import React, { useEffect, useState } from "react";

import "./KPI.css";

import { CardsSection, DataBarChart, DataTable } from "components/reports";
import PageHeader from "utils/PageHeader";
import Loader from "utils/Loader";

import { KPI_DATA_NAMES } from "constants";

import API from "services/api";
import DataQueryForm from "components/reports/common/DataQueryForm";

const KPI = () => {
  const [queryType, setQueryType] = useState("Monthly");

  const [kpiDataFiltered, setKpiDataFiltered] = useState([]);
  const [kpiDataBarTable, setKpiDataBarTable] = useState([]);
  // const [mapDataCalculated, setMapDataCalculated] = useState({});
  // const [mapDataCalculatedDistricts, setMapDataCalculatedDistricts] = useState(
  //   {}
  // );
  // const [kpiDataMap, setKpiDataMap] = useState([]);
  // const [dataIndexName, setDataIndexName] = useState("months6_59IfaPercentO");
  // const [dataLoadingMap, setDataLoadingMap] = useState(false);
  const [queryData, setQueryData] = useState({
    stateCountry: "INDIA",
    month: 1,
    quarter: 1,
    year: new Date().getFullYear() - 1,
    normalized: false,
  });

  const [dataLoadingTable, setDataLoadingTable] = useState(false);

  const [dataAsOnDate, setDataAsOnDate] = useState("");

  // Get the KpiData
  useEffect(() => {
    (async () => {
      try {
        setDataLoadingTable(true);
        let response = {};
        if (queryType === "Monthly") {
          response = await API.getKpiMonthlyData({
            ...queryData,
          });
        } else {
          response = await API.getKpiQuarterlyData({
            ...queryData,
          });
        }

        if (response.data !== null) {
          setDataAsOnDate(response.dataAsOnDate);
          setKpiDataFiltered([response.data[0]]);
          setKpiDataBarTable(response.data);
        } else if (response.data === null) {
          setKpiDataFiltered(null);
          setKpiDataBarTable([]);
        } else {
          setKpiDataFiltered([{ stateDistrict: queryData.stateCountry }]);
        }
        setDataLoadingTable(false);
      } catch (err) {
        setKpiDataBarTable([]);
        setDataLoadingTable(false);
      }
    })();
  }, [queryData, queryType]);

  return (
    <>
      <PageHeader text="AMB Performance Indicators" />

      <div className="container-fluid kpi-container">
        <DataQueryForm
          setQueryData={setQueryData}
          setKpiDataFiltered={setKpiDataFiltered}
          kpiDataFiltered={kpiDataFiltered}
          queryType={queryType}
          setQueryType={setQueryType}
        />

        <CardsSection queryData={queryData} kpiDataFiltered={kpiDataFiltered} />

        <DataTable
          dataName={KPI_DATA_NAMES}
          kpiDataTable={kpiDataBarTable}
          queryData={queryData}
          dataAsOnDate={dataAsOnDate}
          loadingStatus={dataLoadingTable}
          currentComponent={
            queryType === "Monthly" ? "KPI_Monthly" : "KPI_Quarterly"
          }
        />

        {/* <KPIIndexSelector
          dataName={KPI_DATA_NAMES}
          dataIndexName={dataIndexName}
          setDataIndexName={setDataIndexName}
        /> */}

        {/* <DataIndiaMap
          dataIndexName={dataIndexName}
          queryData={queryData}
          mapDataCalculated={mapDataCalculated}
          mapDataCalculatedDistricts={mapDataCalculatedDistricts}
          loadingStatus={dataLoadingMap}
        /> */}

        {dataLoadingTable ? (
          <Loader loading={dataLoadingTable} />
        ) : (
          <div className="bar-charts-kpi">
            {KPI_DATA_NAMES.map((data) => (
              <DataBarChart
                key={data.key}
                dataName={KPI_DATA_NAMES}
                kpiDataBar={kpiDataBarTable}
                dataIndexName={data.key}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default KPI;
