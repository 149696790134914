import React, { useEffect, useRef, useState } from "react";

import html2canvas from "html2canvas";
import { Bar } from "react-chartjs-2";
import { defaults } from "chart.js";
import styles from "./DataBarChart.module.css";

defaults.font.family = "Poppins";

const DataBarChart = ({ dataName, kpiDataBar, dataIndexName }) => {
  const [data, setData] = useState([]);
  const chartRef = useRef(null);

  // Function to download the chart as PNG
  const downloadChartAsPNG = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current)
        .then((canvas) => {
          const url = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = url;
          link.download = "chart.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error converting chart to PNG:", error);
        });
    }
  };

  useEffect(() => {
    let currData = [];
    if (kpiDataBar) {
      currData = kpiDataBar?.map((state) => {
        return {
          state: state?.stateDistrict,
          value: state[dataIndexName] ? +state[dataIndexName]?.toFixed(2) : 0,
        };
      });
    }

    setData([...currData]);
  }, [kpiDataBar, dataIndexName]);

  data.sort((a, b) => b.value - a.value);

  const chartLabels = [];
  const chartValues = [];
  const colorValues = [];
  const borderColorValues = [];

  if (data) {
    data.forEach((d) => {
      chartLabels.push(d.state);
      chartValues.push(d.value);

      let borderColor = "#0958ad";
      let color = "#105bab";

      if (d.value > 25 && d.value <= 50) {
        borderColor = "#105bab";
        color = "#40c8f4";
      } else if (d.value > 50 && d.value <= 75) {
        borderColor = "#fdd225";
        color = "#ffe06a";
      } else if (d.value > 75) {
        borderColor = "#228a05";
        color = "#228a05";
      }

      colorValues.push(color);
      borderColorValues.push(borderColor);
    });
  }

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        data: chartValues,
        borderWidth: 2,
        borderColor: borderColorValues,
        backgroundColor: colorValues,
      },
    ],
  };

  const chartOptions = {
    tooltips: {
      enabled: true,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: true },
      title: {
        display: true,
        text: dataName?.find((d) => d.key === dataIndexName).value,
        font: {
          size: 16,
        },
      },

      responsive: true,
      maintainAspectRatio: true,
    },
    scales: {
      x: {
        beginAtZero: true,
        labelWrap: true,
        labelMaxWidth: 100,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className={styles["main-chart-container"]}>
      <Bar ref={chartRef} data={chartData} options={chartOptions} />
    </div>
  );
};

export default DataBarChart;
