import React from "react";
import { Link } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

import styles from "./Login.module.css";
import "App.css";

import { Button } from "@mui/material";
import { AccountCircle, Autorenew, LoginRounded } from "@mui/icons-material";

import TextInput from "utils/TextInput";
import PasswordInput from "utils/PasswordInput";

function LoginForm({
  loginData,
  captchaRef,
  onChange,
  onSubmit,
  onReset,
  showPassword,
  onShowPassword,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.formCard}>
        <form onSubmit={onSubmit} className={styles.loginForm}>
          <div className={styles.formGroup}>
            <TextInput
              label="Employee ID"
              name="username"
              placeholder="Enter Employee ID"
              icon={<AccountCircle style={{ color: "#069edb" }} />}
              value={loginData.username}
              onChange={onChange}
            />
          </div>

          <div className={styles.formGroup}>
            <PasswordInput
              label="Password"
              name="password"
              placeholder="Enter password"
              showPassword={showPassword}
              onShowPassword={onShowPassword}
              value={loginData.password}
              onChange={onChange}
            />
          </div>

          <div className={styles.loginFooter}>
            <Link className={styles.forgotPassword} to="/auth/forgotPassword">
              <Button
                sx={{
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#f6eaea",
                  },
                }}>
                Forgot Password?
              </Button>
            </Link>

            <div className={styles.captcha}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                style={{ width: "100%", fontFamily: "Poppins" }}
              />
            </div>

            <div className={styles.buttonGroup}>
              <Button
                variant="primary"
                className={styles.button}
                endIcon={<LoginRounded />}
                onClick={onSubmit}>
                Login
              </Button>

              <Button
                variant="primary"
                className={styles.button}
                startIcon={<Autorenew />}
                onClick={onReset}>
                Reset
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
