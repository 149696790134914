import React, { useEffect, useState } from "react";
import ProgressSteps from "./ProgressSteps";
import { Button, MenuItem, Select } from "@mui/material";

import styles from "./stateform.module.css";

import API from "services/api";
import { useNavigate } from "react-router-dom";

const StateForm = ({
  activeStep,
  selectedState,
  onChange,
  setActiveStep,
  setShow,
  setMessage,
}) => {
  const [statesList, setStatesList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStatesList = async () => {
      try {
        const statesList = await API.getAllStates(2023);
        setStatesList(statesList);
      } catch (error) {}
    };
    fetchStatesList();
  }, []);

  const handleNext = async () => {
    try {
      const response = await API.checkIfStateUserExist(selectedState);

      if (response.data) {
        setActiveStep(1);
      } else if (response.data === false) {
        setShow(true);
        setMessage({
          text: response.message,
          type: "error",
        });

        setTimeout(() => {
          navigate("/reports/denominators/login");
        }, 2000);
      }
    } catch (error) {
      setShow(true);
      setMessage({
        text: error.message,
        type: "error",
      });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.formCard}>
          <div style={{ padding: "20px" }}>
            <ProgressSteps step={activeStep} />
          </div>

          <div className={styles.formGroup}>
            <Select
              fullWidth
              size="small"
              type="text"
              name="state"
              id="state"
              placeholder="Select State"
              value={selectedState}
              onChange={onChange}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              required
            >
              <MenuItem disabled value="">
                Select State
              </MenuItem>
              {statesList?.map((state) => (
                <MenuItem
                  className="dropdown-item"
                  key={state.id}
                  value={state.name}
                >
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <Button
            variant="contained"
            disabled={!selectedState}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default StateForm;
