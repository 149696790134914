export const colorScaleMap = [
  "#b5e8fd", //Mild: [5-20)
  "#20bbf9", //Moderate: [20-40)
  "#057dae", //Severe: >= 40
  "#fff", //Data not available
  "#d3d3d380", //Not Applicable
];

export const colorScaleLegend = [
  { color: "#b5e8fd", label: "Mild: [5-20]" },
  { color: "#20bbf9", label: "Moderate: [20-40]" },
  { color: "#057dae", label: "Severe: >= 40" },
  { color: "#fff", label: "Data not available" },
  { color: "#d3d3d380", label: "Not Applicable" },
];
