import React, { useEffect, useState } from "react";

import { Box, Button, LinearProgress, TextField } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import API from "services/api";

import styles from "./table.module.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import SnackBar from "utils/SnackBar";

function DenominatorsTable() {
  const [searchText, setSearchText] = useState("");

  const [resources, setResources] = useState([]);
  const [data, setData] = useState([]);

  const [dataLoading, setDataLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    async function fetchResources() {
      setDataLoading(true);
      const resourceResponse = await API.getAllDenominators();

      if (resourceResponse.statusCode === 200) {
        if (resourceResponse.data !== null) {
          const resourceList = resourceResponse.data;

          setResources(resourceList);
          setData(resourceList);

          setDataLoading(false);
        } else if (resourceResponse.data === null) {
          setMessage({
            type: "warning",
            text: `${resourceResponse.message}`,
          });
          setShow(true);
          setDataLoading(false);
        }
      }

      setDataLoading(false);
    }

    fetchResources();
  }, []);

  const handleSearch = () => {
    if (searchText === "") {
      setData(resources);
    } else {
      const filteredData = data?.filter((item) =>
        item?.fileName.toLowerCase().includes(searchText.toLowerCase())
      );
      setData(filteredData);
    }
  };

  const handleReset = () => {
    setData(resources);
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <div className={`${styles.resourceTableContainer} mt-2`}>
        <div className={styles.searchRow}>
          <TextField
            size="small"
            type="text"
            id="searchInput"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <div style={{ display: "flex", gap: 5 }}>
            <Button
              id="searchButton"
              variant="contained"
              onClick={handleSearch}
              style={{ backgroundColor: "#069edb" }}
              startIcon={<SearchIcon />}>
              Search
            </Button>
            <Button
              id="searchButton"
              variant="contained"
              onClick={handleReset}
              style={{ backgroundColor: "#069edb" }}
              startIcon={<AutorenewIcon />}>
              Reset
            </Button>
          </div>
        </div>

        {dataLoading ? (
          <>
            <Box sx={{ width: "100%", marginTop: 2 }}>
              <LinearProgress />
            </Box>
            <div>Loading Denominators...</div>
          </>
        ) : (
          <table
            className={`${styles.table} "table table-bordered table-hover table-responsive caption-top align-middle "`}>
            <caption>List of all available denominators</caption>
            <thead>
              <tr className="align-middle">
                <th scope="col">S.No.</th>
                <th scope="col">File Name</th>
                <th scope="col">Year</th>
                <th scope="col">Download</th>
                <th scope="col">Created By</th>
                <th scope="col">Created On</th>
              </tr>
            </thead>

            <tbody>
              {data ? (
                data.map(
                  (
                    { resId, fileName, year, url, createdBy, createdOn },
                    index
                  ) => {
                    const date = new Date(createdOn);

                    // Formatting options
                    const options = {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };

                    const formattedDate = date.toLocaleDateString(
                      "en-US",
                      options
                    );

                    const serialNumber = index + 1;
                    return (
                      <tr className={styles.resourceTableRow} key={resId}>
                        <th className="text-center">{serialNumber}</th>
                        <td className="text-capitalize">{fileName}</td>{" "}
                        <td className="text-capitalize">{year}</td>
                        <td className="text-capitalize">
                          <a href={url} style={{ fontWeight: 700 }}>
                            Download
                          </a>
                        </td>
                        <td className="text-capitalize">{createdBy}</td>
                        <td className="text-capitalize">{formattedDate}</td>
                      </tr>
                    );
                  }
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default DenominatorsTable;
