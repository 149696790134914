import React, { useEffect, useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styles from "./edit.module.css";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import {
  Autorenew,
  AccountCircle,
  AlternateEmailRounded,
  MarkEmailReadRounded,
  LocationOn,
  Badge,
  BusinessCenter,
} from "@mui/icons-material";
import TextInput from "utils/TextInput";
import InputMask from "react-input-mask";

import {
  parsePhoneNumberFromString,
  isValidNumberForRegion,
} from "libphonenumber-js";
import { useAuth } from "context/AuthContext";
import API from "services/api";

const EditUserSchema = Yup.object().shape({
  name: Yup.string().required("Full Name is required"),
  emailId: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .test("is-valid-phone", "Invalid phone number", function (value) {
      return isPhoneNumberValid(value);
    }),
  department: Yup.string().required("Department is required"),
  designation: Yup.string().required("Designation is required"),
  address: Yup.string().required("Address is required"),
  district: Yup.string().required("District is required"),
});

function isPhoneNumberValid(phoneNumber) {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, "IN");

  if (
    parsedPhoneNumber &&
    isValidNumberForRegion(parsedPhoneNumber.nationalNumber, "IN")
  ) {
    return true;
  }

  return false;
}

const EditForm = ({ userDetails, setEditedDetails, onSubmit }) => {
  const { stateToken } = useAuth();

  const [districtsList, setDistrictsList] = useState([]);

  useEffect(() => {
    const fetchDistrictsList = async () => {
      try {
        const districtsList = await API.getAllDistrictsByState(
          stateToken,
          2023
        );
        setDistrictsList(districtsList.data);
      } catch (error) {}
    };

    if (stateToken) {
      fetchDistrictsList();
    }
  }, [stateToken]);
  return (
    <Formik
      initialValues={{
        name: userDetails?.name,
        emailId: userDetails?.emailId,
        phoneNumber: userDetails?.phoneNumber,
        department: userDetails?.department,
        designation: userDetails?.designation,
        address: userDetails?.address,
        district: userDetails?.district,
      }}
      validationSchema={EditUserSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        setEditedDetails(values);
        onSubmit();
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleReset,
      }) => (
        <Form className={styles.editForm}>
          <div className={styles.subGroup}>
            <div className={styles.formGroup}>
              <TextInput
                name="name"
                placeholder="Enter Full Name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.name && touched.name}
                label="Full Name"
                icon={<AccountCircle style={{ color: "#069edb" }} />}
              />
              {errors.name && touched.name && (
                <small className={styles.errorMsg}>{errors.name}</small>
              )}
            </div>

            <div className={styles.formGroup}>
              <TextInput
                name="emailId"
                label="Email ID"
                placeholder="example@example.com"
                value={values.emailId}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.emailId && touched.emailId}
                icon={<AlternateEmailRounded style={{ color: "#069edb" }} />}
              />
              {errors.emailId && touched.emailId && (
                <small className={styles.errorMsg}>{errors.emailId}</small>
              )}
            </div>
          </div>

          <div className={styles.subGroup}>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Phone Number
                <span style={{ color: "red" }}> *</span>
              </label>
              <InputMask
                alwaysShowMask={false}
                mask="9999999999"
                maskPlaceholder={null}
                disabled={false}
                value={values.phoneNumber}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <TextField
                  fullWidth
                  size="small"
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="e.g. 9999999999"
                  InputProps={{
                    startAdornment: (
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/color/48/india.png"
                        alt="india"
                        style={{ marginRight: 8 }}
                      />
                    ),
                  }}
                />
              </InputMask>
              {errors.phoneNumber && touched.phoneNumber && (
                <small className={styles.errorMsg}>{errors.phoneNumber}</small>
              )}
            </div>

            <div className={styles.formGroup}>
              <TextInput
                name="department"
                placeholder="Enter Department"
                value={values.department}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.department && touched.department}
                label="Department"
                icon={<BusinessCenter style={{ color: "#069edb" }} />}
              />
              {errors.department && touched.department && (
                <small className={styles.errorMsg}>{errors.department}</small>
              )}
            </div>
          </div>
          <div className={styles.subGroup}>
            <div className={styles.formGroup}>
              <TextInput
                name="designation"
                placeholder="Enter Designation"
                value={values.designation}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.designation && touched.designation}
                label="Designation"
                icon={<Badge style={{ color: "#069edb" }} />}
              />
              {errors.designation && touched.designation && (
                <small className={styles.errorMsg}>{errors.designation}</small>
              )}
            </div>
            <div className={styles.formGroup}>
              <TextInput
                name="address"
                placeholder="Enter Address"
                value={values.address}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Address"
                icon={<LocationOn style={{ color: "#069edb" }} />}
              />
              {errors.address && touched.address && (
                <small className={styles.errorMsg}>{errors.address}</small>
              )}
            </div>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.label}>
              Select District
              <span style={{ color: "red" }}> *</span>
            </label>
            {stateToken === "INDIA" ? (
              <TextInput
                name="district"
                placeholder="Enter District"
                value={values.district}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.district && touched.district}
                label="District"
                icon={<LocationOn style={{ color: "#069edb" }} />}
              />
            ) : (
              <Select
                fullWidth
                size="small"
                name="district"
                id="district"
                required
                value={values.district}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <MenuItem disabled value="">
                  Select District
                </MenuItem>
                {districtsList?.map((district) => (
                  <MenuItem key={district.id} value={district.name}>
                    {district.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            {errors.district && touched.district && (
              <small className={styles.errorMsg}>{errors.district}</small>
            )}
          </div>

          <div className={styles.editFooter}>
            <div className={styles.buttonGroup}>
              <Button
                variant="primary"
                startIcon={<MarkEmailReadRounded />}
                type="submit"
                disabled={Object.keys(errors).length > 0 || isSubmitting}
                className={
                  Object.keys(errors).length > 0 || isSubmitting
                    ? styles.disabledButton
                    : styles.button
                }
              >
                Send OTP & Verify
              </Button>

              <Button
                variant="primary"
                className={styles.button}
                startIcon={<Autorenew />}
                onClick={handleReset}
              >
                Reset
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditForm;
