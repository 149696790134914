import React from "react";
import { Bar } from "react-chartjs-2";
import { defaults } from "chart.js";

import styles from "./countAdmin.module.css";

defaults.font.family = "Poppins";

function CountAdminOtherCountries({ otherCountriesData }) {
  if (!otherCountriesData || otherCountriesData.length === 0) {
    return <div>No data available</div>;
  }
  const countries = Array.from(
    new Set(otherCountriesData?.map((data) => data.country))
  );

  const countsPerCountry = {};
  countries?.forEach((country) => {
    countsPerCountry[country] = {};

    Object.keys(otherCountriesData[0])?.forEach((key) => {
      if (typeof otherCountriesData[0][key] === "number" && key !== "year") {
        countsPerCountry[country][key] = otherCountriesData
          .filter((data) => data.country === country)
          .reduce((total, data) => total + data[key], 0);
      }
    });
  });

  const labels = Object.keys(otherCountriesData[0]).filter(
    (key) => typeof otherCountriesData[0][key] === "number" && key !== "year"
  );
  const chartData = labels?.map((label) => ({
    label,
    data: countries.map((country) => countsPerCountry[country][label]),
  }));

  // Create chart dataset
  const datasets = chartData?.map((data, index) => ({
    label: data.label,
    data: data.data,
    backgroundColor: `rgba(6, 158, 219, ${0.2 * index})`,
    borderColor: `rgba(6, 158, 219, ${0.2 * index})`,
    borderWidth: 1,
  }));

  const chartConfig = {
    labels: countries,
    datasets: datasets,
  };

  const chartOptions = {
    tooltips: {
      enabled: true,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: true },
      title: {
        display: true,
        text: "Other Country-wise Data Visualization",
        font: {
          size: 16,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    },
    scales: {
      x: {
        beginAtZero: true,
        labelWrap: true,
        labelMaxWidth: 100,
        stacked: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className={styles.chartContainer}>
      <Bar data={chartConfig} options={chartOptions} />
    </div>
  );
}

export default CountAdminOtherCountries;
