import React, { useState } from "react";
import ProgressSteps from "./ProgressSteps";
import { Button } from "@mui/material";

import styles from "./Register.module.css";

import API from "services/api";

import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import PasswordInput from "utils/PasswordInput";

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const SetPasswordForm = ({
  activeStep,
  selectedState,
  setActiveStep,
  setShow,
  setMessage,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleRegister = async (values) => {
    try {
      if (values.password === values.confirmPassword) {
        const statePasswordResponse = await API.setStateUserPassword({
          state: selectedState,
          password: values.password,
        });

        if (statePasswordResponse.success) {
          setShow(true);
          setMessage({
            text: "Password set success!",
            type: "success",
          });
          setActiveStep(4);
        } else {
          setShow(true);
          setMessage({
            text: "Registration Failed! Something went wrong...",
            type: "error",
          });
        }
      } else {
        setShow(true);
        setMessage({
          text: "Password and Confirm Password not same...",
          type: "warn",
        });
      }
    } catch (error) {
      setShow(true);
      setMessage({
        text: error.message,
        type: "error",
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formCard}>
        <div style={{ padding: "20px" }}>
          <ProgressSteps step={activeStep} />
        </div>

        <h4>Set Password</h4>

        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={PasswordSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleRegister(values);
          }}
        >
          {({ handleChange, isSubmitting, values, errors, touched }) => (
            <Form className={styles.formContainer}>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <PasswordInput
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    showPassword={showPassword}
                    onShowPassword={handleClickShowPassword}
                    value={values.password}
                    onChange={handleChange}
                  />
                  {errors.password && touched.password && (
                    <ErrorMessage
                      name="password"
                      component="small"
                      className={styles.errorMsg}
                    />
                  )}
                </div>

                <div className={styles.formGroup}>
                  <PasswordInput
                    label="Confirm Password"
                    name="confirmPassword"
                    placeholder="Enter confirm password"
                    showPassword={showConfirmPassword}
                    onShowPassword={handleClickShowConfirmPassword}
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <ErrorMessage
                      name="confirmPassword"
                      component="small"
                      className={styles.errorMsg}
                    />
                  )}
                </div>
              </div>

              <Button variant="contained" type="submit">
                Submit Credentials
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SetPasswordForm;
