import * as React from "react";

import {
  Box,
  Toolbar,
  List,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { DeleteSweepRounded, CloudUploadRounded } from "@mui/icons-material";

import { Link, useLocation } from "react-router-dom";

import Emblem from "assets/img/emblem.png";
import AMBLogo from "assets/img/LogoAmb1.png";
import PALogo from "assets/img/poshan.png";
import NHMLogo from "assets/img/NHM-logo.png";

import { menuItems } from "constants";

function DrawerItems() {
  const { pathname } = useLocation();
  return (
    <div>
      <Toolbar
        style={{
          gap: 15,
          padding: 5,
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <img height={55} src={Emblem} alt="Indian Emblem" />
        <img
          height={55}
          src={AMBLogo}
          alt="Anemia Mukt Bharat Logo - English"
        />
        <img height={55} src={PALogo} alt="Poshan Abhiyan Logo" />
        <img height={55} src={NHMLogo} alt="National Health Mission Logo" />
      </Toolbar>
      <Divider />

      <Box sx={{ overflow: "auto" }}>
        <List>
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  disablePadding
                  component={Link}
                  to={item.path}
                  selected={item.path === pathname}>
                  <ListItemIcon>
                    {item.label !== "Delete Uploaded Excel Sheets" ? (
                      <CloudUploadRounded style={{ color: "#069edb" }} />
                    ) : (
                      <DeleteSweepRounded style={{ color: "#069edb" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    style={{ color: "black" }}
                  />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </List>
      </Box>
    </div>
  );
}

export default DrawerItems;
