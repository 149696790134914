import React from "react";
import { FileUploader } from "react-drag-drop-files";
import "styles/fileDrop.css";

const fileTypes = ["xls", "xlsx"];

function FileDrop({ onFileChange }) {
  return (
    <FileUploader
      handleChange={onFileChange}
      name="file"
      types={fileTypes}
      required={true}
      hoverTitle="Drop file here!"
      dropMessageStyle={{
        backgroundColor: "#105bab",
        opacity: 0.9,
        color: "white",
      }}
      classes="layout"
    />
  );
}

export default FileDrop;
