import React from "react";

import styles from "./DataTable.module.css";

const LegendTable = ({ queryData }) => {
  return (
    <>
      <div className={styles["legend-table-container"]}>
        {queryData && queryData.normalized && (
          <>
            <div className={styles["legend-table-row"]}>
              <div className={styles["no-data-legend"]}></div>
              <div>No data</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-very-low-legend"]}></div>
              <div>0-24.9%</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-low-legend"]}></div>
              <div>25-49.9%</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-medium-legend"]}></div>
              <div>50-74.9%</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-high-legend"]}></div>
              <div>75-94.9%</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-normalized-legend"]}></div>
              <div>Above 95% (Normalized)*</div>
            </div>
          </>
        )}
        {!queryData.normalized && (
          <>
            <div className={styles["legend-table-row"]}>
              <div className={styles["no-data-legend"]}></div>
              <div>No data</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-very-low-legend"]}></div>
              <div>0-24.9%</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-low-legend"]}></div>
              <div>25-49.9%</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-medium-legend"]}></div>
              <div>50-74.9%</div>
            </div>

            <div className={styles["legend-table-row"]}>
              <div className={styles["color-high-legend"]}></div>
              <div>Above 75%</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LegendTable;
