import { React } from "react";
import { Link } from "react-router-dom";

import { Nav, Navbar } from "react-bootstrap";

import logo from "assets/AIIMS_New_Delhi.png";
import emblem from "assets/img/emblem.png";

import "./NCEARHeader.css";

const NCEARHeader = () => {
  return (
    <>
      <header id="fixed-header">
        <div className="top-header">
          <a href="https://www.mohfw.gov.in/" target="_blank" rel="noreferrer">
            <img className="top-logo" src={emblem} alt="emblem" />
          </a>
          <Link to="/">
            <img className="top-logo" src={logo} alt="Logo" />
          </Link>
          <Link to="/contact/partners/ncear-a">
            <img
              className="top-logo"
              src="https://res.cloudinary.com/daxilgrvn/image/upload/v1691908461/NCEAR-A_Logo_jqvhcd.png"
              alt="NCEAR-A"
            />
          </Link>
        </div>

        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="navbar-ncear">
              <Nav.Link href="/" className="navbar-link">
                Anemia Mukt Bharat
              </Nav.Link>
              <Nav.Link href="#home" className="navbar-link">
                Home
              </Nav.Link>
              <Nav.Link href="#who-we-are" className="navbar-link">
                Who we are
              </Nav.Link>
              <Nav.Link href="#the-work" className="navbar-link">
                The Work
              </Nav.Link>
              <Nav.Link href="#gallery" className="navbar-link">
                Gallery
              </Nav.Link>
              <Nav.Link href="#team" className="navbar-link">
                NCEAR-A Team
              </Nav.Link>
              <Nav.Link href="#contact" className="navbar-link">
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  );
};

export default NCEARHeader;
