import React, { useEffect, useState } from "react";
import PageHeader from "utils/PageHeader";

import styles from "./styles.module.css";
import { FormControlLabel, Radio } from "@mui/material";
import QueryFormMonthly from "components/stock/QueryFormMonthly";
import StocksTableTopHeader from "components/reports/StocksTableTopHeader";
import API from "services/api";

import "./stock.css";
import QueryFormQuarterly from "components/stock/QueryFormQuarterly";
import Loader from "utils/Loader";
import stockTableDataMonthly from "data/StocksTableData";
import StockHeader from "components/stock/StockHeader";
import StockTableMonthly from "components/stock/StockTableMonthly";
import StockTableQuarterly from "components/stock/StockTableQuarterly";
import SnackBar from "utils/SnackBar";

const IFAStock = () => {
  const [queryType, setQueryType] = useState("Monthly");
  const [queryData, setQueryData] = useState({
    location: "INDIA",
    selectedState: "",
    selectedDistrict: "",
    startYear: 2022,
  });

  const [stocksDataIFARed, setStocksDataIFARed] = useState([]);
  const [stocksDataIFABlue, setStocksDataIFABlue] = useState([]);
  const [stocksDataIFAPink, setStocksDataIFAPink] = useState([]);
  const [stocksDataSyrupPaediatric, setStocksDataSyrupPaediatric] = useState(
    []
  );
  const [stocksDataAlbendazole, setStocksDataAlbendazole] = useState([]);

  const [dataAsOnDate, setDataAsOnDate] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const groupDataByMonths = (response) => {
    return response.map((item) => {
      const { indicator, description, year, ...months } = item;
      const indexedMonths = Object.entries(months).map(([month, value]) => ({
        month,
        value: value ? value : 0,
      }));
      return {
        indicator,
        description,
        year,
        months: indexedMonths,
      };
    });
  };

  const groupDataByQuarters = (response) => {
    return response.map((item) => {
      const { indicator, description, stockType, year, ...quarters } = item;
      const indexedQuarters = Object.entries(quarters).map(
        ([quarter, value]) => ({
          quarter,
          value,
        })
      );
      return {
        indicator,
        description,
        year,
        quarters: indexedQuarters,
      };
    });
  };

  const groupDataByIndicators = (data) => {
    let groupedData = [];

    data.forEach((item) => {
      const indicator = item?.indicator;

      if (indicator) {
        if (!groupedData[indicator]) {
          groupedData[indicator] = [];
        }
        groupedData[indicator].push(item);
      }
    });

    return groupedData;
  };

  const handleChange = (event) => {
    setQueryType(event.target.value);
  };

  useEffect(() => {
    (async () => {
      try {
        if (queryData) {
          setDataLoading(true);
          let response = {};
          if (queryType === "Monthly") {
            response = await API.getStockDataMonthly(queryData);

            if (response.data !== null) {
              setDataAsOnDate(response.dataAsOnDate);
              const restructuredData = groupDataByMonths(response.data);
              const groupedDataByIndicators =
                groupDataByIndicators(restructuredData);

              Object.keys(groupedDataByIndicators).forEach((category) => {
                const items = groupedDataByIndicators[category];

                if (items[0].indicator === "HMIS 19.6") {
                  setStocksDataIFARed(items);
                } else if (items[0].indicator === "HMIS 19.7") {
                  setStocksDataIFABlue(items);
                } else if (items[0].indicator === "HMIS 19.8") {
                  setStocksDataIFAPink(items);
                } else if (items[0].indicator === "HMIS 19.9") {
                  setStocksDataSyrupPaediatric(items);
                } else if (items[0].indicator === "HMIS 19.16") {
                  setStocksDataAlbendazole(items);
                }
              });

              setDataLoading(false);
            } else {
              setStocksDataIFARed([]);
              setStocksDataIFABlue([]);
              setStocksDataIFAPink([]);
              setStocksDataSyrupPaediatric([]);
              setStocksDataAlbendazole([]);
              setDataLoading(false);
            }
          } else {
            response = await API.getStockDataQuarterly(queryData);

            if (response.data !== null) {
              setDataAsOnDate(response.dataAsOnDate);
              const restructuredData = groupDataByQuarters(response.data);
              const groupedDataByIndicators =
                groupDataByIndicators(restructuredData);

              Object.keys(groupedDataByIndicators).forEach((category) => {
                const items = groupedDataByIndicators[category];

                if (items[0].indicator === "HMIS 19.6") {
                  setStocksDataIFARed(items);
                } else if (items[0].indicator === "HMIS 19.7") {
                  setStocksDataIFABlue(items);
                } else if (items[0].indicator === "HMIS 19.8") {
                  setStocksDataIFAPink(items);
                } else if (items[0].indicator === "HMIS 19.9") {
                  setStocksDataSyrupPaediatric(items);
                } else if (items[0].indicator === "HMIS 19.16") {
                  setStocksDataAlbendazole(items);
                }
              });

              setDataLoading(false);
            } else {
              setStocksDataIFARed([]);
              setStocksDataIFABlue([]);
              setStocksDataIFAPink([]);
              setStocksDataSyrupPaediatric([]);
              setStocksDataAlbendazole([]);

              setDataLoading(false);
            }
          }
        }
      } catch (error) {
        setStocksDataIFARed([]);
        setStocksDataIFABlue([]);
        setStocksDataIFAPink([]);
        setStocksDataSyrupPaediatric([]);
        setStocksDataAlbendazole([]);
        setDataLoading(false);
      }
    })();
  }, [queryData, queryType]);

  return (
    <>
      <PageHeader text="IFA Stock Status" />

      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />
      <div className={`container-fluid ${styles.stockContainer}`}>
        <div className={styles.formContainer}>
          <div className={styles.radioContainer}>
            <FormControlLabel
              value="Monthly"
              control={<Radio />}
              label="Monthly"
              name="QueryType"
              id="monthlyRadio"
              checked={queryType === "Monthly"}
              onChange={handleChange}
            />

            <FormControlLabel
              value="Quarterly"
              control={<Radio />}
              label="Quarterly"
              name="QueryType"
              id="quarterlyRadio"
              checked={queryType === "Quarterly"}
              onChange={handleChange}
            />
          </div>
          {queryType === "Monthly" ? (
            <QueryFormMonthly
              setQueryData={setQueryData}
              setMessage={setMessage}
              setShow={setShow}
            />
          ) : (
            <QueryFormQuarterly
              setMessage={setMessage}
              setShow={setShow}
              setQueryData={setQueryData}
            />
          )}
        </div>

        <div className="table-container">
          <StocksTableTopHeader
            queryData={queryData}
            stocksDataIFARed={stocksDataIFARed}
            stocksDataIFABlue={stocksDataIFABlue}
            stocksDataIFAPink={stocksDataIFAPink}
            stocksDataSyrupPaediatric={stocksDataSyrupPaediatric}
            stocksDataAlbendazole={stocksDataAlbendazole}
            dataAsOnDate={dataAsOnDate}
            component={queryType}
          />

          {dataLoading ? (
            <Loader loading={dataLoading} />
          ) : (
            stockTableDataMonthly.map((data) => {
              let stocksDataName;

              if (data.indicator === "HMIS 19.6") {
                stocksDataName = stocksDataIFARed;
              } else if (data.indicator === "HMIS 19.7") {
                stocksDataName = stocksDataIFABlue;
              } else if (data.indicator === "HMIS 19.8") {
                stocksDataName = stocksDataIFAPink;
              } else if (data.indicator === "HMIS 19.9") {
                stocksDataName = stocksDataSyrupPaediatric;
              } else if (data.indicator === "HMIS 19.16") {
                stocksDataName = stocksDataAlbendazole;
              }

              return (
                <React.Fragment key={data.indicator}>
                  <StockHeader image={data.image} text={data.text} />
                  {queryType === "Monthly" ? (
                    <StockTableMonthly stocksData={stocksDataName} />
                  ) : (
                    <StockTableQuarterly stocksData={stocksDataName} />
                  )}
                </React.Fragment>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default IFAStock;
