import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";

import PinField from "react-pin-field";

import SnackBar from "utils/SnackBar";
import ProgressSteps from "components/auth/ProgressSteps";

import styles from "./ForgotPassword.module.css";

import API from "services/api";

function VerifyForgotOTP({ activeStep, setActiveStep, email, verifyCodeId }) {
  const [verificationCode, setVerificationCode] = useState();

  const [counter, setCounter] = useState(30);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleVerificationCodeChange = (newValue) => {
    setVerificationCode(newValue);
  };

  useEffect(() => {
    setTimeout(() => {
      setCounter((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }, 1000);
  }, [counter]);

  const handleVerifyOTP = async () => {
    try {
      const response = await API.verifyOTP(
        email,
        verificationCode,
        verifyCodeId
      );
      if (response.statusCode === 200) {
        setActiveStep(2);
      } else {
        setShow(true);
        setMessage({
          text: "OTP Verification Failed! Please enter correct OTP...",
          type: "error",
        });
      }
    } catch (error) {
      setShow(true);
      setMessage({
        text: "OTP Verification Failed! Something went wrong...",
        type: "error",
      });
    }
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <div className={styles.container}>
        <div className={styles.formCard}>
          <ProgressSteps
            step={activeStep}
            steps={["Enter Email", "Verify OTP", "Reset Password", "Login"]}
          />

          <form onSubmit={handleVerifyOTP} className={styles.loginForm}>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Verification Code
                <span style={{ color: "red" }}> *</span>
              </label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                  width: "100%",
                }}
              >
                <PinField
                  onChange={(code) => handleVerificationCodeChange(code)}
                  length={6}
                  className={styles.otpInput}
                />
              </div>
            </div>

            <Button
              variant="contained"
              startIcon={<MarkEmailReadRoundedIcon />}
              disabled={counter === 0}
              className={counter === 0 ? styles.disabledButton : styles.button}
            >
              Resend OTP {counter !== 0 && `(${counter})`}
            </Button>

            <Button
              variant="contained"
              startIcon={<VerifiedRoundedIcon />}
              onClick={handleVerifyOTP}
            >
              Verify
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default VerifyForgotOTP;
