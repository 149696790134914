import { useState } from "react";

import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import SelectYear from "utils/SelectYear";
import FileDrop from "utils/FileDrop";
import SnackBar from "utils/SnackBar";

import AdminPanelLayout from "components/admin/AdminPanelLayout";

import styles from "./uploadSheet.module.css";

import API from "services/api";
import DenominatorsTable from "components/admin/DenominatorsTable";

function DenominatorSheet() {
  const [excelSheet, setExcelSheet] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const [isUploading, setIsUploading] = useState(false);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleExcelSheetUpload = (file) => {
    setExcelSheet(file);
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleReset = () => {
    setExcelSheet(null);
    setSelectedDate(null);
  };

  const handleUploadClick = async (e) => {
    e.preventDefault();

    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", excelSheet, excelSheet.name);

    const selectedYear = parseInt(selectedDate.format("YYYY"));

    try {
      const response = await API.uploadDenominator(selectedYear, formData);
      if (response.statusCode === 200) {
        setIsUploading(false);
        setShow(true);
        setMessage({
          text: `Denominator File for year: ${selectedYear} uploaded successfully!`,
          type: "success",
        });
      } else if (response.success === true) {
        setIsUploading(false);
        setShow(true);
        setMessage({
          text: `${response.message} File not uploaded!`,
          type: "warning",
        });
      } else {
        setIsUploading(false);
        setShow(true);
        setMessage({
          text: `File not uploaded!`,
          type: "error",
        });
      }
    } catch (error) {
      setIsUploading(false);
      setShow(true);
      setMessage({
        text: `File not uploaded! Something went wrong...`,
        type: "error",
      });
    }
  };

  return (
    <AdminPanelLayout>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.row}>
          <h6 className={styles.subHeading}>Upload Denominator Sheet</h6>
          <form onSubmit={handleUploadClick} className={styles.form}>
            <div className={styles.rowInputFile}>
              <FileDrop onFileChange={handleExcelSheetUpload} />
              <a
                className={styles.sampleLink}
                href="https://nceara.anemiamuktbharat.info/Sample+Excels/Denominator_District_20XX_20XX.xlsx">
                Sample Denominator File
              </a>
            </div>

            <div className={styles.calendarRow}>
              <label className={styles.label}>Select Year</label>
              <SelectYear
                value={selectedDate}
                onYearChange={handleDateChange}
              />
            </div>

            <div className="button-group">
              <Button
                style={{ backgroundColor: "#069edb" }}
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onClick={handleUploadClick}
                disabled={!selectedDate || !excelSheet || isUploading}
                className={
                  !selectedDate || !excelSheet || isUploading
                    ? styles.disabledButton
                    : styles.button
                }>
                {isUploading ? "Uploading..." : "Upload"}
              </Button>

              <Button
                variant="contained"
                onClick={handleReset}
                style={{ backgroundColor: "#069edb" }}
                startIcon={<AutorenewIcon />}>
                Reset
              </Button>
            </div>
          </form>
        </div>
      </LocalizationProvider>

      <DenominatorsTable />
    </AdminPanelLayout>
  );
}

export default DenominatorSheet;
