import React, { useEffect, useState } from "react";
import styles from "./CardsSection.module.css";
import { months } from "constants";
import { quarters } from "constants";
const CardsSection = ({ queryData, kpiDataFiltered }) => {
  const [cardsData, setCardsData] = useState({});
  useEffect(() => {
    if (kpiDataFiltered !== null) {
      const filteredStateData = kpiDataFiltered?.filter(
        (state) => state?.stateDistrict === queryData.stateCountry
      );
      const stateData = filteredStateData[0];
      if (stateData) {
        setCardsData({ ...stateData });
      }
    } else if (kpiDataFiltered === null) {
      setCardsData([]);
    }
  }, [queryData, kpiDataFiltered]);

  return (
    <div className={styles["cards-container"]}>
      <Card1 queryData={queryData} cardsData={cardsData} />
      <Card2 queryData={queryData} cardsData={cardsData} />
      <Card3 queryData={queryData} cardsData={cardsData} />
      <Card5 queryData={queryData} cardsData={cardsData} />
      <Card4 queryData={queryData} cardsData={cardsData} />
    </div>
  );
};

export default CardsSection;

const Card1 = ({ queryData, cardsData }) => (
  <CardWrapper
    headerText="Children 6-59 months"
    footerText={
      queryData?.year &&
      `${
        queryData?.month
          ? months[queryData.month - 1]
          : quarters[queryData.quarter - 1]
      } ${queryData.year}`
    }
    image="/kpi-monthly/kpi-card-img-1.jpg"
  >
    <div className={styles["percent-text-big"]}>
      {cardsData["months6_59IfaPercentO"]?.toFixed(2)}%
    </div>
    <div className={styles["card-body-small-text"]}>
      HMIS 9.9: Percentage of children 6-59 months provided 8-10 doses (1ml) of
      IFA syrup (Bi weekly)
    </div>
  </CardWrapper>
);
const Card2 = ({ queryData, cardsData }) => (
  <CardWrapper
    headerText="Children 5-9 years"
    footerText={
      queryData?.year &&
      `${
        queryData?.month
          ? months[queryData.month - 1]
          : quarters[queryData.quarter - 1]
      } ${queryData.year}`
    }
    image="/kpi-monthly/kpi-card-img-2.jpg"
  >
    <div className={styles["percent-text-big"]}>
      {cardsData["years6_9IfaPercentP"]?.toFixed(2)}%
    </div>
    <div className={styles["card-body-small-text"]}>
      HMIS 23.1+23.3: Percentage of children covered under WIFS JUNIOR (5-9
      years) provided 4-5 IFA tablets (In schools + out of school)
    </div>
  </CardWrapper>
);
const Card3 = ({ queryData, cardsData }) => (
  <CardWrapper
    headerText="Adolescents 10-19 yrs in schools"
    footerText={
      queryData?.year &&
      `${
        queryData?.month
          ? months[queryData.month - 1]
          : quarters[queryData.quarter - 1]
      } ${queryData.year}`
    }
    image="/kpi-monthly/kpi-card-img-3.jpg"
  >
    <div className={styles["percent-text-big"]}>
      {cardsData["adols10_15IfaPercentH"]?.toFixed(2)}%
    </div>

    <div className={styles["card-body-small-text"]}>
      HMIS 22.1.1: Percentage of (6-12 class) provided 4 IFA tablets in schools
    </div>
  </CardWrapper>
);
const Card4 = ({ queryData, cardsData }) => (
  <CardWrapper
    headerText="Lactating Mother"
    footerText={
      queryData?.year &&
      `${
        queryData?.month
          ? months[queryData.month - 1]
          : quarters[queryData.quarter - 1]
      } ${queryData.year}`
    }
    image="/kpi-monthly/Lactating Mother.jpeg"
  >
    <div className={styles["percent-text-big"]}>
      {cardsData["motherIfaPercentF"]?.toFixed(2)}%
    </div>
    <div className={styles["card-body-small-text"]}>
      (NEW) Percentage of mothers provided full course of 180 IFA tablets after
      delivery
    </div>
  </CardWrapper>
);
const Card5 = ({ queryData, cardsData }) => (
  <CardWrapper
    headerText="Pregnant Women"
    footerText={
      queryData?.year &&
      `${
        queryData?.month
          ? months[queryData.month - 1]
          : quarters[queryData.quarter - 1]
      } ${queryData.year}`
    }
    image="/kpi-monthly/kpi-card-img-5.jpg"
  >
    <div className={styles["percent-text-big"]}>
      {cardsData["pregWomenIfaPercentC"]?.toFixed(2)}%
    </div>
    <div className={styles["card-body-small-text"]}>
      HMIS 1.2.4: Percentage of pregnant women (PW) given 180 IFA tablets
    </div>
  </CardWrapper>
);

const CardWrapper = ({ headerText, image, footerText, children, color }) => {
  return (
    <div className={styles["card-wrapper"]}>
      <div className={styles["card-header-text"]}>{headerText}</div>

      <div className={styles["card-body"]}>
        <img src={image} alt="card" className={styles["card-image"]} />
        <div className={styles["card-body-text"]}>{children}</div>
      </div>

      <div className={styles["card-footer-text"]}>{footerText}</div>
    </div>
  );
};
