import React, { useState } from "react";

import { Button } from "react-bootstrap";

import StateChart from "./StateChart";
import styles from "./map.module.css";

const MapDialog = (props) => {
  const [DTName, setDTName] = useState("");

  const closeModal = () => {
    props?.closeModal();
  };

  const getTitle = () => {
    if (!DTName) {
      return "No district selected";
    } else {
      return `Selected District: ${DTName}`;
    }
  };

  return (
    <div
      className={`${
        props?.show ? styles.mapDialog + "Show" : styles.mapDialog
      }`}
    >
      <div className={styles.mapDialogHeader}>
        <div className={styles.mapDialogTitle}>{getTitle()}</div>
        <Button
          variant="danger"
          style={{ backgroundColor: "#105bab", borderColor: "#105bab" }}
          onClick={closeModal}
          className={styles.mapDialogClose}
        >
          Close
        </Button>
      </div>
      <div className={styles.mapDialogBody}>
        <StateChart
          setDistrictName={setDTName}
          selectedState={props?.StateName}
          queryData={props?.queryData}
          mapDataCalculatedDistricts={props?.mapDataCalculatedDistricts}
        />
      </div>
    </div>
  );
};

export default MapDialog;
