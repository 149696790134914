import React from "react";

import CountAdminIndiaGraph from "./CountAdminIndiaGraph";
import CountAdminOtherCountries from "./CountAdminOtherStates";

import styles from "./countAdmin.module.css";

const CountAdminGraphs = ({ indiaStatesData, otherCountriesData }) => {
  return (
    <div className={styles.chartsContainer}>
      <CountAdminIndiaGraph indiaStatesData={indiaStatesData} />
      <CountAdminOtherCountries otherCountriesData={otherCountriesData} />
    </div>
  );
};

export default CountAdminGraphs;
