import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

import World from "./topojsons/World.json";

import India from "./topojsons/india.json";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { colorScaleMap } from "./colorScale";

const WorldMap = ({ mapDataCalculated }) => {
  const [tooltipContent, setTooltipContent] = useState("");

  const colorComponent = (value) => {
    let colorIndex = 4;
    if (!value) colorIndex = 3;
    if (value >= 5 && value < 20) colorIndex = 1;
    else if (value >= 20 && value < 40) colorIndex = 1;
    else if (value >= 40) colorIndex = 2;

    return colorIndex;
  };

  const handleMouseEnter = (geo) => {
    const { WB_NAME } = geo.properties;

    if (WB_NAME) {
      const currCountry = WB_NAME.toUpperCase();

      if (mapDataCalculated) {
        const value = mapDataCalculated[currCountry];
        const countryData = value ? value : null;

        if (countryData) {
          setTooltipContent(`${WB_NAME} - ${countryData}`);
        } else {
          setTooltipContent(`${WB_NAME} - No Data`);
        }
      } else {
        alert("No Data Available!");
      }
    }
  };

  const handleMouseEnterIndia = () => {
    if (mapDataCalculated) {
      const value = mapDataCalculated["INDIA"];
      const countryData = value ? value : null;

      if (countryData) {
        setTooltipContent(`${"INDIA"} - ${countryData}`);
      } else {
        setTooltipContent(`${"INDIA"} - No Data`);
      }
    }
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
  };

  return (
    <>
      <ComposableMap>
        <Geographies geography={World}>
          {({ geographies }) =>
            geographies?.map((geo) => {
              const { WB_NAME } = geo.properties;
              const currCountry = WB_NAME?.toUpperCase();
              const value = parseFloat(mapDataCalculated[currCountry]);
              const countryData = value ? value.toFixed(2) : null;

              const fillColor = countryData
                ? colorScaleMap[colorComponent(countryData)]
                : "#d3d3d380";

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(event) => handleMouseEnter(geo, event)}
                  onMouseLeave={handleMouseLeave}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={tooltipContent}
                  style={{
                    default: {
                      fill: fillColor,
                      outline: "none",
                      stroke: "white",
                      strokeWidth: "0.1px",
                      strokeOpacity: "50%",
                    },
                    hover: {
                      fill: 0,
                      stroke: "#105bab",
                      strokeWidth: "1px",
                      strokeOpacity: "100%",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#105bab",
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>

        <Geographies geography={India}>
          {({ geographies }) =>
            geographies?.map((geo) => {
              const value = parseFloat(mapDataCalculated["INDIA"]);
              const countryData = value ? value.toFixed(2) : null;

              const fillColor = countryData
                ? colorScaleMap[colorComponent(countryData)]
                : "#d3d3d380";
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => handleMouseEnterIndia()}
                  onMouseLeave={handleMouseLeave}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={tooltipContent}
                  style={{
                    default: {
                      fill: fillColor,
                      outline: "none",
                      stroke: "white",
                      strokeWidth: "0.1px",
                      strokeOpacity: "50%",
                    },
                    hover: {
                      fill: 0,
                      stroke: "#105bab",
                      strokeWidth: "1px",
                      strokeOpacity: "100%",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#105bab",
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <Tooltip id="my-tooltip" style={{ background: "#105bab" }} />
    </>
  );
};

export default WorldMap;
