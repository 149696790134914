import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import EditForm from "./EditForm";
import API from "services/api";
import VerifyOTP from "./VerifyOTP";

import styles from "./edit.module.css";

const EditUserDetails = ({
  visible,
  showEditUserDetails,
  userDetails,
  activeStep,
  setActiveStep,
}) => {
  const [editedDetails, setEditedDetails] = useState();
  const [verifyCodeID, setVerifyCodeID] = useState(null);

  const handleVerify = async () => {
    try {
      if (editedDetails.emailId !== "") {
        const otpResponse = await API.generateOTP(editedDetails.emailId);
        if (otpResponse.statusCode === 200) {
          setVerifyCodeID(otpResponse.data.verifyCodeId);
          setActiveStep(2);
        }
      }
    } catch (error) {}
  };

  return (
    <Modal show={visible} onHide={showEditUserDetails} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {activeStep === 1 && (
          <EditForm
            userDetails={userDetails}
            setEditedDetails={setEditedDetails}
            setActiveStep={setActiveStep}
            onSubmit={handleVerify}
          />
        )}

        {activeStep === 2 && (
          <VerifyOTP
            verifyCodeID={verifyCodeID}
            editedUserDetails={editedDetails}
            onResend={handleVerify}
            setActiveStep={setActiveStep}
          />
        )}

        {activeStep === 3 && (
          <div className={styles.successContainer}>
            <p className={styles.successText}>
              User details updated successfully!
              <br /> <br />
              Logging out...
              <br /> <br />
              Please login to see the updated details!
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={showEditUserDetails}
          style={{
            backgroundColor: "#069edb",
            borderColor: "#069edb",
            color: "white",
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserDetails;
