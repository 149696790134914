import React from "react";
import { PdfDocument } from "components/stock/pdf/PDFTemplate";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "@mui/material";

import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";

function StocksTableTopHeader({
  queryData,
  stocksDataIFARed,
  stocksDataIFABlue,
  stocksDataIFAPink,
  stocksDataSyrupPaediatric,
  stocksDataAlbendazole,
  dataAsOnDate,
  component,
}) {
  return (
    <>
      <div className="table-header">
        <div
            style={{
            fontSize: 20
        }}>
          INDIA{" "}
          {queryData.startYear ? (
            queryData.startYear === queryData.endYear ? (
              `(${queryData?.startYear})`
            ) : (
              `(${queryData?.startYear} - ${queryData?.startYear + 1})`
            )
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            fontSize: 15,
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          Data as on {dataAsOnDate}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="table-location">
          {queryData && queryData?.location ? queryData?.location : <></>}
        </h4>

        {/* <div>
          {queryData.length !== 0 && (
            <PDFDownloadLink
              document={
                <PdfDocument
                  dataAsOnDate={dataAsOnDate}
                  component={component}
                  queryData={queryData}
                  stocksDataIFARed={stocksDataIFARed}
                  stocksDataIFABlue={stocksDataIFABlue}
                  stocksDataIFAPink={stocksDataIFAPink}
                  stocksDataSyrupPaediatric={stocksDataSyrupPaediatric}
                  stocksDataAlbendazole={stocksDataAlbendazole}
                />
              }
              fileName={`Maternal Nutrition in Percentage ${
                queryData.startYear ? (
                  queryData.startYear === queryData.endYear ? (
                    `(${queryData?.startYear})`
                  ) : (
                    `(${queryData?.startYear} - ${queryData?.endYear})`
                  )
                ) : (
                  <></>
                )
              }.pdf`}
              style={{
                textDecoration: "none",
                padding: 5,
                paddingRight: 15,
                fontSize: 16,
                textAlign: "right",
                color: "#069edb",
                fontWeight: "bold",
                backgroundColor: "transparent",
              }}
            >
              {({ loading }) =>
                loading ? (
                  <Button
                    style={{ borderColor: "#069edb", color: "#069edb" }}
                    startIcon={<PictureAsPdfRoundedIcon />}
                    variant="outlined"
                  >
                    Loading PDF...
                  </Button>
                ) : (
                  <Button
                    style={{ borderColor: "#069edb", color: "#069edb" }}
                    startIcon={<PictureAsPdfRoundedIcon />}
                    variant="outlined"
                  >
                    Download PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
        </div> */}
      </div>
    </>
  );
}

export default StocksTableTopHeader;
