import { React } from "react";

import { useLocation } from "react-router-dom";
import HomeHeader from "./HomeHeader";
import NCEARHeader from "./NCEARHeader";

const FixedHeader = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/contact/partners/ncear-a" ? (
        <HomeHeader />
      ) : (
        <NCEARHeader />
      )}
    </>
  );
};

export default FixedHeader;
