import React from "react";

const TableHeader = ({ dataName, queryData }) => {
  return (
    <thead>
      <tr>
        <th>Location</th>
        {dataName &&
          dataName?.map((name, index) =>
            queryData?.stateCountry === "INDIA" && name.key !== "rankDist" ? (
              <th key={index}>{name.value}</th>
            ) : queryData?.stateCountry !== "INDIA" &&
              name.key !== "rankState" ? (
              <th key={index}>{name.value}</th>
            ) : (
              <></>
            )
          )}
      </tr>
    </thead>
  );
};

export default TableHeader;
