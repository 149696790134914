/*=============================================
=               Stock Table Data             =
=============================================*/

const stockTableDataMonthly = [
  {
    image:
      "https://res.cloudinary.com/daxilgrvn/image/upload/v1692689736/Project_A/kpi-card-img-5_vig3ov.jpg",
    text: "IFA Red (Pregnant Women, Lactating Mother, WRA (20-49 years))",
    indicator: "HMIS 19.6",
    stocksDataName: "stocksDataIFARed",
  },
  {
    image:
      "https://res.cloudinary.com/daxilgrvn/image/upload/v1692689736/Project_A/kpi-card-img-3_g2ctul.png",
    text: "IFA Blue (Adolescent 10-19 years)",
    indicator: "HMIS 19.7",
    stocksDataName: "stocksDataIFABlue",
  },
  {
    image:
      "https://res.cloudinary.com/daxilgrvn/image/upload/v1692689736/Project_A/kpi-card-img-2_xnbfsw.jpg",
    text: "IFA Pink (Junior 5-9 years)",
    indicator: "HMIS 19.8",
    stocksDataName: "stocksDataIFAPink",
  },
  {
    image:
      "https://res.cloudinary.com/daxilgrvn/image/upload/v1692689736/Project_A/kpi-card-img-2_xnbfsw.jpg",
    text: "IFA Syrup – (Children 6-59 Months)",
    indicator: "HMIS 19.9",
    stocksDataName: "stocksDataSyrupPaediatric",
  },
  {
    image:
      "https://res.cloudinary.com/daxilgrvn/image/upload/v1692689737/Project_A/kpi-card-img-6_ujrume.png",
    text: "Albendazole (400mg)",
    indicator: "HMIS 19.16",
    stocksDataName: "stocksDataAlbendazole",
  },
];
export default stockTableDataMonthly;
