const getPDFHeading = (queryData, isMonthlyRanking, startMonth, endMonth) => {
  let heading;

  if (isMonthlyRanking) {
    if (
      queryData.startYear === queryData.endYear &&
      queryData.startMonth === queryData.endMonth
    ) {
      heading = `${queryData?.stateCountry}, ${startMonth} ${queryData?.startYear}`;
    } else if (queryData.startYear === queryData.endYear) {
      heading = `${queryData?.stateCountry}, ${startMonth} - ${endMonth} ${queryData?.startYear}`;
    } else if (queryData.startMonth === queryData.endMonth) {
      heading = `${queryData?.stateCountry}, ${startMonth} ${queryData?.startYear} - ${endMonth} ${queryData?.endYear}`;
    }
  } else {
    heading = `${queryData?.stateCountry},${startMonth} ${queryData?.startYear} - ${endMonth} ${queryData?.endYear}`;
  }
  return heading;
};

export default getPDFHeading;
