import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";

import India from "./topojsons/india.json";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { colorScaleMap } from "./colorScale";

const IndiaMap = ({ mapDataCalculated, setPosition, position }) => {
  const [tooltipContent, setTooltipContent] = useState("");

  const colorComponent = (value) => {
    let colorIndex = 4;
    if (!value) colorIndex = 3;
    if (value >= 5 && value < 20) colorIndex = 1;
    else if (value >= 20 && value < 40) colorIndex = 1;
    else if (value >= 40) colorIndex = 2;

    return colorIndex;
  };

  function handleMoveEnd(position) {
    setPosition(position);
  }

  const handleMouseEnter = (geo) => {
    const { ST_NM } = geo.properties;

    if (ST_NM) {
      const currState = ST_NM.toUpperCase();

      if (mapDataCalculated) {
        const value = mapDataCalculated[currState];
        const stateData = value ?? 0;

        if (stateData) {
          setTooltipContent(`${ST_NM} - ${stateData}`);
        } else {
          setTooltipContent(`${ST_NM} - No Data`);
        }
      } else {
        alert("No Data Available!");
      }
    }
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
  };

  return (
    <>
      <ComposableMap
        projection="geoMercator"
        width={150}
        height={150}
        projectionConfig={{ center: [78.9629, 20.5937], scale: 220 }}
      >
        <Geographies geography={India}>
          {({ geographies }) =>
            geographies?.map((geo) => {
              const { ST_NM } = geo.properties;
              const currState = ST_NM?.toUpperCase();
              const value = parseFloat(mapDataCalculated[currState]);
              const stateData = value ? value.toFixed(2) : null;

              const fillColor = stateData
                ? colorScaleMap[colorComponent(stateData)]
                : "#d3d3d380";

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(event) => handleMouseEnter(geo, event)}
                  onMouseLeave={handleMouseLeave}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={tooltipContent}
                  style={{
                    default: {
                      fill: fillColor,
                      outline: "none",
                      stroke: "white",
                      strokeWidth: "0.1px",
                      strokeOpacity: "50%",
                    },
                    hover: {
                      fill: 0,
                      stroke: "#105bab",
                      strokeWidth: "1px",
                      strokeOpacity: "100%",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#105bab",
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <Tooltip id="my-tooltip" style={{ background: "#105bab" }} />
    </>
  );
};

export default IndiaMap;
