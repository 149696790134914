import React from "react";

import styles from "./Eligiblebeneficiaries.module.css";
import PageHeader from "utils/PageHeader";
import { useEffect } from "react";
import API from "services/api";

function Beneficiaries() {
  useEffect(() => {
    async function CountStatic() {
      try {
        await API.getCountStatic();
      } catch (error) {}
    }
    CountStatic();
  }, []);

  return (
    <>
      <PageHeader text="Target Beneficiaries" />

      <div className={styles.contentContainer}>
        <img
          src={require("assets/target-01.png")}
          alt="Target-AMB"
          className={styles.targetImage}
        />

        <p>
          <b>Source:</b> AMB Target beneficiaries for FY 2023-24 as per the data
          available on AMB dashboard.
        </p>
      </div>
    </>
  );
}

export default Beneficiaries;
