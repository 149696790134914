import AdminPanelLayout from "components/admin/AdminPanelLayout";
import DrugRequirementSheet from "components/admin/DrugRequirementSheet";
import DrugRequirementTable from "components/admin/DrugRequirementTable";

function DrugRequirement() {
  return (
    <>
      <AdminPanelLayout>
        <DrugRequirementSheet />

        <DrugRequirementTable />
      </AdminPanelLayout>
    </>
  );
}

export default DrugRequirement;
