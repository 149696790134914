import React, { useEffect, useState } from "react";

import {
  parsePhoneNumberFromString,
  isValidNumberForRegion,
} from "libphonenumber-js";

import RegisterForm from "components/auth/RegisterForm";
import VerifyOTP from "components/auth/VerifyOTP";
import SuccessPage from "components/auth/SuccessPage";
import SnackBar from "utils/SnackBar";

import API from "services/api";

function RegisterAdmin() {
  const [activeStep, setActiveStep] = useState(0);
  const [registrationData, setRegistrationData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    empId: "",
    password: "",
    confirmPassword: "",
  });
  const [verifyCodeID, setVerifyCodeID] = useState(null);

  const [isValid, setIsValid] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [isEqual, setIsEqual] = useState(true);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const text = "Registration Successful!";

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(email);

    setIsValid(isValidEmail);
  };

  useEffect(() => {
    if (
      registrationData.name &&
      registrationData.empId &&
      registrationData.email &&
      registrationData.phoneNumber &&
      registrationData.password &&
      registrationData.confirmPassword &&
      registrationData.password === registrationData.confirmPassword
    ) {
      setIsComplete(true);
    }

    if (registrationData.password !== registrationData.confirmPassword) {
      setIsEqual(false);
    }
  }, [
    registrationData,
    registrationData.password,
    registrationData.confirmPassword,
  ]);

  const handleVerify = async () => {
    try {
      if (isValid && isComplete) {
        const response = await API.generateOTP(registrationData.email);
        if (response.statusCode === 200) {
          setVerifyCodeID(response.data.verifyCodeId);
          setActiveStep(1);
        }
      } else {
        setShow(true);
        setMessage({
          text: "Complete the form or Invalid Email...",
          type: "warning",
        });
      }
    } catch (error) {}
  };

  function isPhoneNumberValid(phoneNumber) {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, "IN");

    if (
      parsedPhoneNumber &&
      isValidNumberForRegion(parsedPhoneNumber.nationalNumber, "IN")
    ) {
      return true;
    }

    return false;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      validateEmail(value);
    } else if (name === "phoneNumber") {
      setIsValidPhone(isPhoneNumberValid(value));
    }
    setRegistrationData({ ...registrationData, [name]: value });
  };

  const handleReset = () => {
    setRegistrationData({ username: "", password: "" });
    setIsComplete(false);
    setIsValid(true);
  };

  switch (activeStep) {
    case 0:
      return (
        <>
          <SnackBar
            message={message?.text}
            open={show}
            handleClose={handleClose}
            type={message?.type}
          />
          <div className="container mx-auto">
            <RegisterForm
              activeStep={activeStep}
              isValid={isValid}
              isValidPhone={isValidPhone}
              isComplete={isComplete}
              isEqual={isEqual}
              onSubmit={handleVerify}
              onReset={handleReset}
              onChange={handleInputChange}
              registrationData={registrationData}
            />
          </div>
        </>
      );

    case 1:
      return (
        <VerifyOTP
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          email={registrationData.email}
          verifyCodeId={verifyCodeID}
          registrationData={registrationData}
          onResend={handleVerify}
        />
      );

    case 3:
      return <SuccessPage activeStep={activeStep} text={text} />;

    default:
  }
}

export default RegisterAdmin;
