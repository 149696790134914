import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import API from "services/api";

import styles from "./resourceTable.module.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import SnackBar from "utils/SnackBar";

function ResourcesTable() {
  const [searchText, setSearchText] = useState("");

  const [resources, setResources] = useState([]);
  const [data, setData] = useState([]);

  const [selectedFile, setSelectedFile] = useState({
    category: "",
    folderName: "",
    fileName: "",
  });

  const [dataLoading, setDataLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogFullFileName, setOpenDialogFullFileName] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });

  const handleClose = () => {
    setShow(false);
    if (message.text === "Deleted File Successfully!") {
      setTimeout(() => {
        refreshPage();
      }, 2000);
    }
  };

  useEffect(() => {
    async function fetchResources() {
      setDataLoading(true);
      const resourceList = await API.getAllResources();
      setResources(resourceList);
      setData(resourceList);

      setDataLoading(false);
    }

    fetchResources();
  }, []);

  function refreshPage() {
    window.location.reload(true);
  }

  const handleSearch = () => {
    if (searchText === "") {
      setData(resources);
    } else {
      const filteredData = data?.filter(
        (item) =>
          item?.category.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.folderName.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.fileName.toLowerCase().includes(searchText.toLowerCase())
      );
      setData(filteredData);
    }
  };

  const handleReset = () => {
    setData(resources);
  };

  const handleOpenDialog = (category, folderName, fileName) => {
    setOpenDialog(true);
    setSelectedFile({
      category: category,
      folderName: folderName,
      fileName: fileName,
    });
    setOpenDialogFullFileName(`${category}>${folderName}>${fileName}`);
  };

  const handleDelete = async (category, folderName, fileName) => {
    handleCloseDialog();

    const response = await API.deleteResource(
      category.toString(),
      folderName.toString(),
      fileName.toString()
    );

    if (response === true) {
      setShow(true);
      setMessage({ text: "Deleted File Successfully!", type: "success" });
    } else if (response === false) {
      setShow(true);
      setMessage({ text: "Some Error Occurred!", type: "error" });
    }
  };

  return (
    <>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Delete Item?"}</DialogTitle>
        <Divider sx={{ backgroundColor: "black" }} />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete <b>{openDialogFullFileName}?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleDelete(
                selectedFile.category,
                selectedFile.folderName,
                selectedFile.fileName
              )
            }
            style={{ backgroundColor: "#069edb", color: "white" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <div className={`${styles.resourceTableContainer}`}>
        <div className={styles.searchRow}>
          <TextField
            size="small"
            type="text"
            id="searchInput"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <div style={{ display: "flex", gap: 5 }}>
            <Button
              id="searchButton"
              variant="contained"
              onClick={handleSearch}
              style={{ backgroundColor: "#069edb" }}
              startIcon={<SearchIcon />}>
              Search
            </Button>
            <Button
              id="searchButton"
              variant="contained"
              onClick={handleReset}
              style={{ backgroundColor: "#069edb" }}
              startIcon={<AutorenewIcon />}>
              Reset
            </Button>
          </div>
        </div>

        {dataLoading ? (
          <>
            <Box sx={{ width: "100%", marginTop: 2 }}>
              <LinearProgress />
            </Box>
            <div>Loading Resources...</div>
          </>
        ) : (
          <table
            className={`${styles.table} "table table-bordered table-hover table-responsive caption-top align-middle "`}>
            <caption>List of all available resources</caption>
            <thead>
              <tr className="align-middle">
                <th scope="col">S.No.</th>
                <th scope="col">Options</th>
                <th scope="col">Category</th>
                <th scope="col">Folder Name</th>
                <th scope="col">File Name</th>
                <th scope="col">Created By</th>
                <th scope="col">Created On</th>
              </tr>
            </thead>

            <tbody>
              {data ? (
                data.map(
                  (
                    {
                      resId,
                      category,
                      folderName,
                      fileName,
                      createdBy,
                      creationDate,
                    },
                    index
                  ) => {
                    const date = new Date(creationDate);

                    // Formatting options
                    const options = {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };

                    const formattedDate = date.toLocaleDateString(
                      "en-US",
                      options
                    );

                    const serialNumber = index + 1;
                    return (
                      <tr className={styles.resourceTableRow} key={resId}>
                        <th className="text-center">{serialNumber}</th>
                        <td className="text-center">
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                            style={{
                              color: "#069edb",
                              borderColor: "#069edb",
                            }}
                            onClick={() =>
                              handleOpenDialog(category, folderName, fileName)
                            }>
                            Delete
                          </Button>
                        </td>
                        <td className="text-capitalize">{category}</td>
                        <td className="text-capitalize">{folderName}</td>
                        <td className="text-capitalize">{fileName}</td>
                        <td className="text-capitalize">{createdBy}</td>
                        <td className="text-capitalize">{formattedDate}</td>
                      </tr>
                    );
                  }
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default ResourcesTable;
