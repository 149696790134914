import React from "react";
import { Bar } from "react-chartjs-2";
import { defaults } from "chart.js";

import styles from "./countAdmin.module.css";

defaults.font.family = "Poppins";

function CountAdminIndiaGraph({ indiaStatesData }) {
  if (!indiaStatesData || indiaStatesData.length === 0) {
    return <div>No data available</div>;
  }
  const states = Array.from(
    new Set(indiaStatesData?.map((data) => data.state))
  );

  const countsPerState = {};
  states?.forEach((state) => {
    countsPerState[state] = {};

    Object.keys(indiaStatesData[0])?.forEach((key) => {
      if (typeof indiaStatesData[0][key] === "number" && key !== "year") {
        countsPerState[state][key] = indiaStatesData
          .filter((data) => data.state === state)
          .reduce((total, data) => total + data[key], 0);
      }
    });
  });

  const labels = Object.keys(indiaStatesData[0]).filter(
    (key) => typeof indiaStatesData[0][key] === "number" && key !== "year"
  );
  const chartData = labels?.map((label) => ({
    label,
    data: states.map((state) => countsPerState[state][label]),
  }));

  // Create chart dataset
  const datasets = chartData?.map((data, index) => ({
    label: data.label,
    data: data.data,
    backgroundColor: `rgba(6, 158, 219, ${0.2 * index})`,
    borderColor: `rgba(6, 158, 219, ${0.2 * index})`,
    borderWidth: 1,
  }));

  const chartConfig = {
    labels: states,
    datasets: datasets,
  };

  const chartOptions = {
    tooltips: {
      enabled: true,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: true },
      title: {
        display: true,
        text: "India State-wise Data Visualization",
        font: {
          size: 16,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    },
    scales: {
      x: {
        beginAtZero: true,
        labelWrap: true,
        labelMaxWidth: 100,
        stacked: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className={styles.chartContainer}>
      <Bar data={chartConfig} options={chartOptions} />
    </div>
  );
}

export default CountAdminIndiaGraph;
