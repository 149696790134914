import styles from "./StockTable.module.css";

function StockTableQuarterly({ stocksData }) {
  return (
    <div className="table-sub-container">
      <table className={`${styles.table} "table" "table-responsive"`}>
        <thead style={{ position: "sticky", top: "0" }}>
          <tr className="text-center">
            <th style={{ width: "600px " }} scope="row">
              Indicators
            </th>
            <th>Year</th>
            <th>Upto Q1</th>
            <th>Upto Q2</th>
            <th>Upto Q3</th>
            <th>Upto Q4</th>
          </tr>
        </thead>
        <tbody>
          {stocksData?.map((data) => {
            return (
              <tr
                key={`${data?.indicator}${data?.year}${data?.stockType}${
                  data?.jan
                }${Math.random()}`}
              >
                <th className={styles.stocksFirstColumn}>
                  <b>{data.indicator}</b> <br />
                  {data.description}
                </th>
                <td>{data.year}</td>
                {data?.quarters?.map((quarter) => {
                  return <td key={quarter.quarter}>{quarter?.value}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default StockTableQuarterly;
