import React from "react";

import styles from "./DataTable.module.css";

const TableBody = ({ kpiDataTable, dataName, queryData }) => {
  return (
    <tbody>
      {kpiDataTable &&
        kpiDataTable.map((state, index) => (
          <tr key={index}>
            <th scope="row">{state.stateDistrict}</th>
            {dataName &&
              dataName?.map((name, index) => {
                let percentValue =
                  name.key === "rankState" ||
                  name.key === "stateRank" ||
                  name.key === "rankDist"
                    ? 0
                    : state[name.key];

                if (
                  percentValue === 0 &&
                  !(
                    name.key === "rankState" ||
                    name.key === "stateRank" ||
                    name.key === "rankDist"
                  )
                ) {
                  return (
                    <td key={index} className={styles["no-data"]}>
                      0.0
                    </td>
                  );
                }

                if (
                  percentValue === 0 &&
                  (name.key === "rankState" ||
                    name.key === "stateRank" ||
                    name.key === "rankDist")
                ) {
                  return state[name.key] !== 0 ? (
                    <td key={index} className={styles["rank-data"]}>
                      {state[name.key]}
                    </td>
                  ) : (
                    <></>
                  );
                }
                let colorClass =
                  percentValue >= 95 && queryData.normalized
                    ? ((percentValue = 95), "color-normalized")
                    : percentValue >= 75
                    ? "color-high"
                    : percentValue >= 50
                    ? "color-medium"
                    : percentValue >= 25
                    ? "color-low"
                    : "color-very-low";

                return (
                  <td className={styles[colorClass]} key={index}>
                    <div className={styles.row}>
                      {percentValue?.toFixed(1)}{" "}
                      <p className={styles.info}>{percentValue >= 95 && "*"}</p>
                    </div>
                  </td>
                );
              })}
          </tr>
        ))}
    </tbody>
  );
};

export default TableBody;
