import React from "react";

import { Button } from "@mui/material";
import {
  AlternateEmailRounded,
  MarkEmailReadRounded,
} from "@mui/icons-material";

import styles from "./ForgotPassword.module.css";

import ProgressSteps from "components/auth/ProgressSteps";
import TextInput from "utils/TextInput";

function ForgotPasswordForm({
  email,
  activeStep,
  onOTPGenerate,
  onEmailChange,
}) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.formCard}>
          <ProgressSteps
            step={activeStep}
            steps={["Enter Email", "Verify OTP", "Reset Password", "Login"]}
          />

          <form onSubmit={onOTPGenerate} className={styles.loginForm}>
            <div className={styles.formGroup}>
              <TextInput
                label="Email ID"
                name="email"
                placeholder="example@example.com"
                icon={<AlternateEmailRounded style={{ color: "#069edb" }} />}
                value={email}
                onChange={onEmailChange}
              />
            </div>

            <Button
              variant="primary"
              style={{ backgroundColor: "#069edb", color: "white" }}
              startIcon={<MarkEmailReadRounded />}
              onClick={onOTPGenerate}>
              Send OTP
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordForm;
