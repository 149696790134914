import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import andamannicobar from "./topojsons/states/andamannicobar.json";
import andhrapradesh from "./topojsons/states/andhrapradesh.json";
import arunachalpradesh from "./topojsons/states/arunachalpradesh.json";
import assam from "./topojsons/states/assam.json";
import bihar from "./topojsons/states/bihar.json";
import chandigarh from "./topojsons/states/chandigarh.json";
import chhattisgarh from "./topojsons/states/chhattisgarh.json";
import delhi from "./topojsons/states/delhi.json";
import dnhdd from "./topojsons/states/dnhdd.json";
import goa from "./topojsons/states/goa.json";
import gujarat from "./topojsons/states/gujarat.json";
import haryana from "./topojsons/states/haryana.json";
import himachalpradesh from "./topojsons/states/himachalpradesh.json";
import jammukashmir from "./topojsons/states/jammukashmir.json";
import jharkhand from "./topojsons/states/jharkhand.json";
import karnataka from "./topojsons/states/karnataka.json";
import kerala from "./topojsons/states/kerala.json";
import lakshadweep from "./topojsons/states/lakshadweep.json";
import ladakh from "./topojsons/states/ladakh.json";
import madhyapradesh from "./topojsons/states/madhyapradesh.json";
import maharashtra from "./topojsons/states/maharashtra.json";
import manipur from "./topojsons/states/manipur.json";
import meghalaya from "./topojsons/states/meghalaya.json";
import mizoram from "./topojsons/states/mizoram.json";
import nagaland from "./topojsons/states/nagaland.json";
import odisha from "./topojsons/states/odisha.json";
import puducherry from "./topojsons/states/puducherry.json";
import punjab from "./topojsons/states/punjab.json";
import rajasthan from "./topojsons/states/rajasthan.json";
import sikkim from "./topojsons/states/sikkim.json";
import tamilnadu from "./topojsons/states/tamilnadu.json";
import telangana from "./topojsons/states/telangana.json";
import tripura from "./topojsons/states/tripura.json";
import uttarakhand from "./topojsons/states/uttarakhand.json";
import uttarpradesh from "./topojsons/states/uttarpradesh.json";
import westbengal from "./topojsons/states/westbengal.json";
import { colorScaleMap } from "./colorScale";

const StateMap = ({ selectedState, mapDataCalculated }) => {
  const [tooltipContent, setTooltipContent] = useState("");

  let geoURL;
  let centerMap = [80, 22];
  let scaleMap = 400;
  if (
    selectedState?.toLowerCase() === "Andaman and Nicobar Islands".toLowerCase()
  ) {
    geoURL = andamannicobar;
    scaleMap = 1000;
    centerMap = [93, 10];
  } else if (selectedState?.toLowerCase() === "Andhra Pradesh".toLowerCase()) {
    geoURL = andhrapradesh;
    scaleMap = 800;
    centerMap = [80, 17];
  } else if (
    selectedState?.toLowerCase() === "Arunachal Pradesh".toLowerCase()
  ) {
    geoURL = arunachalpradesh;
    scaleMap = 1200;
    centerMap = [94.5, 28];
  } else if (selectedState?.toLowerCase() === "Assam".toLowerCase()) {
    geoURL = assam;
    scaleMap = 1350;
    centerMap = [92.9, 26];
  } else if (selectedState?.toLowerCase() === "Bihar".toLowerCase()) {
    geoURL = bihar;
    scaleMap = 1300;
    centerMap = [85.5, 26];
  } else if (selectedState?.toLowerCase() === "Chandigarh".toLowerCase()) {
    geoURL = chandigarh;
    scaleMap = 7500;
    centerMap = [76.75, 30.65];
  } else if (selectedState?.toLowerCase() === "Chhattisgarh".toLowerCase()) {
    geoURL = chhattisgarh;
    scaleMap = 1100;
    centerMap = [82, 21];
  } else if (selectedState?.toLowerCase() === "Delhi".toLowerCase()) {
    geoURL = delhi;
    scaleMap = 11000;
    centerMap = [77.05, 28.6];
  } else if (selectedState?.toLowerCase() === "Goa".toLowerCase()) {
    geoURL = goa;
    scaleMap = 6000;
    centerMap = [74, 15.25];
  } else if (selectedState?.toLowerCase() === "Gujarat".toLowerCase()) {
    geoURL = gujarat;
    scaleMap = 1000;
    centerMap = [71.5, 22];
  } else if (selectedState?.toLowerCase() === "Haryana".toLowerCase()) {
    geoURL = haryana;
    scaleMap = 1700;
    centerMap = [76, 29];
  } else if (
    selectedState?.toLowerCase() === "Himachal Pradesh".toLowerCase()
  ) {
    geoURL = himachalpradesh;
    scaleMap = 2000;
    centerMap = [77.4, 31.8];
  } else if (
    selectedState?.toLowerCase() === "Jammu and Kashmir".toLowerCase()
  ) {
    geoURL = jammukashmir;
    scaleMap = 1800;
    centerMap = [75, 33.5];
  } else if (selectedState?.toLowerCase() === "Jharkhand".toLowerCase()) {
    geoURL = jharkhand;
    scaleMap = 1700;
    centerMap = [85.7, 23.6];
  } else if (selectedState?.toLowerCase() === "Karnataka".toLowerCase()) {
    geoURL = karnataka;
    scaleMap = 1100;
    centerMap = [76.5, 15];
  } else if (selectedState?.toLowerCase() === "Kerala".toLowerCase()) {
    geoURL = kerala;
    scaleMap = 1800;
    centerMap = [76, 10.5];
  } else if (selectedState?.toLowerCase() === "Lakshadweep".toLowerCase()) {
    geoURL = lakshadweep;
    scaleMap = 2300;
    centerMap = [73, 11];
  } else if (selectedState?.toLowerCase() === "Ladakh".toLowerCase()) {
    geoURL = ladakh;
    scaleMap = 900;
    centerMap = [76.3, 35];
  } else if (selectedState?.toLowerCase() === "Madhya Pradesh".toLowerCase()) {
    geoURL = madhyapradesh;
    scaleMap = 900;
    centerMap = [78.5, 24];
  } else if (selectedState?.toLowerCase() === "Maharashtra".toLowerCase()) {
    geoURL = maharashtra;
    scaleMap = 1000;
    centerMap = [76.8, 19.3];
  } else if (selectedState?.toLowerCase() === "Manipur".toLowerCase()) {
    geoURL = manipur;
    scaleMap = 3400;
    centerMap = [93.8, 24.7];
  } else if (selectedState?.toLowerCase() === "Meghalaya".toLowerCase()) {
    geoURL = meghalaya;
    scaleMap = 2500;
    centerMap = [91.3, 25.4];
  } else if (selectedState?.toLowerCase() === "Mizoram".toLowerCase()) {
    geoURL = mizoram;
    scaleMap = 2900;
    centerMap = [92.8, 23.25];
  } else if (selectedState?.toLowerCase() === "Nagaland".toLowerCase()) {
    geoURL = nagaland;
    scaleMap = 4000;
    centerMap = [94.3, 26.1];
  } else if (selectedState?.toLowerCase() === "Odisha".toLowerCase()) {
    geoURL = odisha;
    scaleMap = 1300;
    centerMap = [84.4, 20.25];
  } else if (selectedState?.toLowerCase() === "Puducherry".toLowerCase()) {
    geoURL = puducherry;
    scaleMap = 1300;
    centerMap = [78.25, 10.8];
  } else if (selectedState?.toLowerCase() === "Punjab".toLowerCase()) {
    geoURL = punjab;
    scaleMap = 2300;
    centerMap = [75.35, 31.1];
  } else if (selectedState?.toLowerCase() === "Rajasthan".toLowerCase()) {
    geoURL = rajasthan;
    scaleMap = 900;
    centerMap = [74, 26.3];
  } else if (selectedState?.toLowerCase() === "Sikkim".toLowerCase()) {
    geoURL = sikkim;
    scaleMap = 6000;
    centerMap = [88.45, 27.6];
  } else if (selectedState?.toLowerCase() === "Tamil Nadu".toLowerCase()) {
    geoURL = tamilnadu;
    scaleMap = 1300;
    centerMap = [78.25, 10.8];
  } else if (selectedState?.toLowerCase() === "Telangana".toLowerCase()) {
    geoURL = telangana;
    scaleMap = 1800;
    centerMap = [79.5, 17.9];
  } else if (
    selectedState?.toLowerCase() ===
    "The Dadra and Nagar Haveli and Daman and Diu".toLowerCase()
  ) {
    geoURL = dnhdd;
    scaleMap = 3200;
    centerMap = [72, 20.3];
  } else if (selectedState?.toLowerCase() === "Tripura".toLowerCase()) {
    geoURL = tripura;
    scaleMap = 4500;
    centerMap = [91.75, 23.75];
  } else if (selectedState?.toLowerCase() === "Uttarakhand".toLowerCase()) {
    geoURL = uttarakhand;
    scaleMap = 2000;
    centerMap = [79.3, 30];
  } else if (selectedState?.toLowerCase() === "Uttar Pradesh".toLowerCase()) {
    geoURL = uttarpradesh;
    scaleMap = 1000;
    centerMap = [80.8, 27];
  } else if (selectedState?.toLowerCase() === "West Bengal".toLowerCase()) {
    geoURL = westbengal;
    scaleMap = 1200;
    centerMap = [87.7, 24.2];
  }
  const colorComponent = (value) => {
    let colorIndex = 4;
    if (!value || value === "") colorIndex = 3;
    if (value >= 5 && value < 20) colorIndex = 1;
    else if (value >= 20 && value < 40) colorIndex = 1;
    else if (value >= 40) colorIndex = 2;

    return colorIndex;
  };

  const handleMouseEnter = (geo) => {
    const { district } = geo.properties;

    if (district) {
      const currDistrict = district.toUpperCase();

      if (mapDataCalculated) {
        const value = parseFloat(mapDataCalculated[currDistrict]).toFixed(1);
        const districtData = value ?? 0;

        if (districtData) {
          setTooltipContent(`${district} - ${districtData}`);
        } else {
          setTooltipContent(`${district} - No Data`);
        }
      } else {
        alert("No Data Available!");
      }
    }
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
  };

  return (
    <>
      <ComposableMap
        projection="geoMercator"
        width={150}
        height={150}
        projectionConfig={{ center: centerMap, scale: scaleMap }}
      >
        <Geographies geography={geoURL}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const { district } = geo.properties;
              const currDistrict = district?.toUpperCase();
              const value = parseFloat(mapDataCalculated[currDistrict]).toFixed(
                1
              );
              const districtData = value ? value : null;

              const fillColor = districtData
                ? colorScaleMap[colorComponent(districtData)]
                : "#d3d3d380";

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(event) => handleMouseEnter(geo, event)}
                  onMouseLeave={handleMouseLeave}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={tooltipContent}
                  style={{
                    default: {
                      fill: fillColor,
                      outline: "none",
                      stroke: "white",
                      strokeWidth: "0.1px",
                      strokeOpacity: "50%",
                    },
                    hover: {
                      fill: 0,
                      stroke: "#105bab",
                      strokeWidth: "1px",
                      strokeOpacity: "100%",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#105bab",
                      outline: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <Tooltip id="my-tooltip" />
    </>
  );
};

export default StateMap;
