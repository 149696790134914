import React, { useEffect, useState } from "react";

import styles from "./rankingStyles.module.css";

import { FormControlLabel, Radio } from "@mui/material";

import Loader from "utils/Loader";
import PageHeader from "utils/PageHeader";

import { DataTable, DataIndiaMap, DataBarChart } from "components/reports";
import DataQueryFormCumulative from "components/ranking/DataQueryFormCumulative";

import DataQueryFormMonthly from "components/ranking/DataQueryFormMonthly";

import {
  RANKING_DATA_NAMES_BAR,
  RANKING_DATA_NAMES_COUNTRY,
  RANKING_DATA_NAMES_STATE,
} from "constants";

import API from "services/api";

const AMBRanking = () => {
  const [queryType, setQueryType] = useState("Monthly");

  // eslint-disable-next-line
  const [kpiDataFiltered, setKpiDataFiltered] = useState([]);
  const [rankingDataTable, setRankingDataTable] = useState([]);
  const [mapDataCalculated, setMapDataCalculated] = useState({});
  const [mapDataCalculatedDistricts, setMapDataCalculatedDistricts] = useState(
    {}
  );

  const dataIndexName = "indexValue";

  const [rankingDataMap, setRankingDataMap] = useState([]);
  const [queryData, setQueryData] = useState({
    stateCountry: "INDIA",
    startYear: new Date().getFullYear() - 1,
    startMonth: 4,
    endYear: new Date().getFullYear() - 1,
    endMonth: 6,
    normalized: true,
  });

  const [dataLoadingTable, setDataLoadingTable] = useState(false);
  const [dataLoadingMap, setDataLoadingMap] = useState(false);

  const [dataAsOnDate, setDataAsOnDate] = useState("");

  const handleChange = (event) => {
    setQueryType(event.target.value);
  };

  // KPI Data for Table
  useEffect(() => {
    (async () => {
      try {
        if (
          queryData.endMonth &&
          queryData.endYear &&
          queryData.startMonth &&
          queryData.startYear &&
          queryData.stateCountry !== ""
        ) {
          let rankingResponse = {};
          if (queryType === "Monthly") {
            setDataLoadingTable(true);
            rankingResponse = await API.getRankingMonthlyData({
              ...queryData,
            });
          } else {
            setDataLoadingTable(true);
            rankingResponse = await API.getRankingCumulativeData({
              ...queryData,
            });
          }
          if (rankingResponse.data !== null) {
            setDataAsOnDate(rankingResponse.dataAsOnDate);
            setRankingDataTable(rankingResponse.data);
          } else {
            setRankingDataTable([]);
          }
          setDataLoadingTable(false);
        }
      } catch (err) {
        setRankingDataTable([]);
        setDataLoadingTable(false);
      }
    })();
  }, [queryData, queryType]);

  // KPI Data for Map
  useEffect(() => {
    (async () => {
      try {
        if (
          queryData.endMonth &&
          queryData.endYear &&
          queryData.startMonth &&
          queryData.startYear &&
          queryData.stateCountry !== ""
        ) {
          let mapResponse = {};
          if (queryType === "Monthly") {
            setDataLoadingMap(true);
            mapResponse = await API.getMonthlyRankingMapData({
              year: queryData.startYear,
              month: queryData.startMonth,
              normalized: queryData.normalized,
            });
          } else {
            setDataLoadingMap(true);
            mapResponse = await API.getCumulativeRankingMapData({
              ...queryData,
            });
          }

          if (mapResponse !== null) {
            setRankingDataMap(mapResponse);
          } else {
            setRankingDataMap([]);
          }
          setDataLoadingMap(false);
        }
      } catch (err) {
        setRankingDataMap([]);
        setDataLoadingMap(false);
      }
    })();
  }, [queryData, queryType]);

  //  Map Data State
  useEffect(() => {
    let tempObj = {};

    rankingDataMap?.forEach((data) => {
      const stateData = {
        value: data["indexValue"],
      };

      tempObj[data.stateName ? data.stateName : "No State"] = stateData;
    });

    setMapDataCalculated(tempObj);
  }, [rankingDataMap]);

  // Map Data District
  useEffect(() => {
    let tempObj = {};

    rankingDataMap?.forEach((data) => {
      data.districtDetails?.forEach((district) => {
        const districtData = {
          value: district["indexValue"],
        };

        const stateDistrict = district.stateDistrict || "No District";
        const state = data.stateName;

        if (!tempObj[state]) {
          tempObj[state] = {};
        }

        tempObj[state][stateDistrict] = districtData;
      });
    });

    setMapDataCalculatedDistricts(tempObj);
  }, [rankingDataMap]);

  return (
    <>
      <PageHeader text="AMB Ranking" />

      <div className="container-fluid kpi-container">
        <div className={styles.formContainer}>
          <div className={styles.radioContainer}>
            <FormControlLabel
              value="Monthly"
              control={<Radio />}
              label="Monthly"
              name="QueryType"
              id="monthlyRadio"
              checked={queryType === "Monthly"}
              onChange={handleChange}
            />

            <FormControlLabel
              value="Quarterly"
              control={<Radio />}
              label="Quarterly"
              name="QueryType"
              id="quarterlyRadio"
              checked={queryType === "Quarterly"}
              onChange={handleChange}
            />
          </div>

          {queryType === "Monthly" ? (
            <DataQueryFormMonthly
              setQueryData={setQueryData}
              currentComponent={"Ranking"}
              setKpiDataFiltered={setKpiDataFiltered}
            />
          ) : (
            <DataQueryFormCumulative
              queryData={queryData}
              setQueryData={setQueryData}
            />
          )}
        </div>

        <DataTable
          dataName={
            queryData.stateCountry === "INDIA"
              ? RANKING_DATA_NAMES_COUNTRY
              : RANKING_DATA_NAMES_STATE
          }
          kpiDataTable={rankingDataTable}
          queryData={queryData}
          mapDataCalculated={mapDataCalculated}
          dataAsOnDate={dataAsOnDate}
          currentComponent={
            queryType === "Monthly" ? "Ranking_Monthly" : "Ranking_Quarterly"
          }
          loadingStatus={dataLoadingTable}
        />

        <DataIndiaMap
          queryData={queryData}
          mapDataCalculated={mapDataCalculated}
          mapDataCalculatedDistricts={mapDataCalculatedDistricts}
          currentComponent={"Ranking"}
          loadingStatus={dataLoadingMap}
        />

        {dataLoadingTable ? (
          <Loader loading={dataLoadingTable} />
        ) : (
          <DataBarChart
            currentComponent={
              queryType === "Monthly" ? "Ranking_Monthly" : "Ranking_Quarterly"
            }
            dataName={RANKING_DATA_NAMES_BAR}
            kpiDataBar={rankingDataTable}
            dataIndexName={dataIndexName}
          />
        )}
      </div>
    </>
  );
};

export default AMBRanking;
