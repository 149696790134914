import React, { useEffect, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { FormControlLabel, Radio } from "@mui/material";
import SnackBar from "utils/SnackBar";
import SelectLocation from "utils/SelectLocation";
import ViewButton from "utils/ViewButton";
import SelectQuarter from "utils/SelectQuarter";
import SelectYearLabel from "utils/SelectYearLabel";

import API from "services/api";

import styles from "./styles.module.css";
import SelectMonthYear from "utils/SelectMonthYear";

const DataQueryForm = ({
  setQueryData,
  setKpiDataFiltered,
  queryType,
  setQueryType,
}) => {
  // For Quarter
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");

  // For Monthly
  const [selectedDate, setSelectedDate] = useState();

  // Common
  const [stateList, setStateList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState({ text: "", type: "" });

  const handleClose = () => {
    setShow(false);
  };

  const handleReset = () => {
    setSelectedDate(null);
    setSelectedLocation("");
  };

  const handleChange = (event) => {
    setQueryType(event.target.value);
  };

  const formattedSelectedYear = selectedDate?.format("YYYY");

  useEffect(() => {
    const getStateList = async () => {
      try {
        const states = await API.getAllStates(
          queryType === "Monthly" ? formattedSelectedYear : selectedYear
        );
        setStateList(states);
      } catch (error) {}
    };

    getStateList();
  }, [formattedSelectedYear, queryType, selectedYear]);

  const handleStateChange = (e) => {
    setSelectedLocation(e?.target.value);
  };

  const handleLabelYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleQuarterChange = (e) => {
    setSelectedQuarter(e.target.value);
  };

  const submitFormHandlerMonthly = (e) => {
    e.preventDefault();

    const selectedYear = selectedDate?.format("YYYY");
    const selectedMonth = selectedDate?.format("M");

    if (!selectedYear && !selectedMonth) {
      setShow(true);
      setModalText({
        text: "Select year and month to see details!",
        type: "warning",
      });

      return;
    }

    if (!selectedLocation) {
      setShow(true);
      setModalText({
        text: "Select a location to continue!",
        type: "warning",
      });

      return;
    }

    setKpiDataFiltered([]);
    setQueryData({
      stateCountry: selectedLocation,
      year: selectedYear,
      month: selectedMonth,
      normalized: false,
    });

    setShow(true);
    setModalText({
      text: "Form Submitted! Loading the data...",
      type: "success",
    });
  };

  const submitFormHandlerQuarterly = (e) => {
    e.preventDefault();

    if (!selectedYear && !selectedQuarter) {
      setShow(true);
      setModalText({
        text: "Select year and quarter to see details!",
        type: "warning",
      });

      return;
    }

    if (!selectedLocation) {
      setShow(true);
      setModalText({
        text: "Select a location to continue!",
        type: "warning",
      });

      return;
    }

    setKpiDataFiltered([]);
    setQueryData({
      stateCountry: selectedLocation,
      year: selectedYear,
      quarter: selectedQuarter,
      normalized: false,
    });

    setShow(true);
    setModalText({
      text: "Form Submitted! Loading the data...",
      type: "success",
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackBar
        message={modalText.text}
        open={show}
        handleClose={handleClose}
        type={modalText.type}
      />

      <div className={styles.formContainer}>
        <div className={styles.radioContainer}>
          <div className={styles["radio-button"]}>
            <FormControlLabel
              value="Monthly"
              control={<Radio />}
              label="Monthly"
              name="QueryType"
              id="monthlyRadio"
              checked={queryType === "Monthly"}
              onChange={handleChange}
            />
          </div>

          <div className={styles["radio-button"]}>
            <FormControlLabel
              value="Quarterly"
              control={<Radio />}
              label="Quarterly"
              name="QueryType"
              id="quarterlyRadio"
              checked={queryType === "Quarterly"}
              onChange={handleChange}
            />
          </div>
        </div>

        <form className={styles.form}>
          {queryType === "Monthly" ? (
            <>
              <div className={styles.formGroupContainer}>
                <div className={styles.formGroup}>
                  <label
                    htmlFor="Select Year and Month"
                    className={styles.formLabel}
                  >
                    Select Year and Month
                  </label>
                  <SelectMonthYear
                    value={selectedDate}
                    defaultValue={dayjs()}
                    onDateChange={(newValue) => setSelectedDate(newValue)}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="location" className={styles.formLabel}>
                    Select Location
                  </label>
                  <SelectLocation
                    stateList={stateList}
                    selectedState={selectedLocation}
                    onLocationChange={handleStateChange}
                  />
                </div>
              </div>

              <div className={`${styles.formGroup} ${styles.centerRow}`}>
                <ViewButton
                  onView={submitFormHandlerMonthly}
                  onReset={handleReset}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.quarterlyFormGroupContainer}>
                <div className={styles.formGroup}>
                  <label htmlFor="year" className={styles.formLabel}>
                    Select Year
                  </label>
                  <SelectYearLabel
                    onYearLabelChange={handleLabelYearChange}
                    selectedYearLabel={selectedYear}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="quarter" className={styles.formLabel}>
                    Select Quarter
                  </label>
                  <SelectQuarter
                    selectedQuarter={selectedQuarter}
                    onQuarterChange={handleQuarterChange}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="location" className={styles.formLabel}>
                    Select Location
                  </label>
                  <SelectLocation
                    stateList={stateList}
                    selectedState={selectedLocation}
                    onLocationChange={handleStateChange}
                  />
                </div>
              </div>

              <div className={`${styles.formGroup} ${styles.centerRow}`}>
                <ViewButton
                  onView={submitFormHandlerQuarterly}
                  onReset={handleReset}
                />
              </div>
            </>
          )}
        </form>
      </div>
    </LocalizationProvider>
  );
};

export default DataQueryForm;
