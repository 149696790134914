import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import React from "react";

const SituationTable = ({ tableData, loading, columns, getRowId }) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        className="m-2 rounded"
        sx={{
          backgroundColor: "#069edb",
          width: "max-content",
          color: "white",
          alignSelf: "flex-end",
        }}
      >
        <GridCsvExportMenuItem />
      </GridToolbarContainer>
    );
  }

  return (
    <DataGrid
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      getRowId={getRowId}
      sx={{
        width: "100%",
        "& .table-header": {
          height: "max-content",
          backgroundColor: "#069edb",
        },
      }}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      loading={loading}
      rows={tableData}
      columns={columns}
      pageSize={10}
      slots={{
        toolbar: CustomToolbar,
      }}
    />
  );
};

export default SituationTable;
