import React, { useEffect, useState } from "react";
import Loader from "utils/Loader";
import PageHeader from "utils/PageHeader";

import styles from "components/situation/map.module.css";
import API from "services/api";
import { MenuItem, Select } from "@mui/material";
import { SituationIndicatorsIndia, NFHSs } from "constants";
import StateMap from "components/situation/StateMap";
import { colorScaleLegend } from "components/situation/colorScale";
import SituationTable from "components/situation/SituationTable";

const StateSituation = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [selectedState, setSelectedState] = useState(
    "ANDAMAN AND NICOBAR ISLANDS"
  );
  const [selectedNFHS, setSelectedNFHS] = useState(5);
  const [selectedIndicator, setSelectedIndicator] = useState("allWomen15_19");

  const handleIndicatorChange = (e) => {
    setSelectedIndicator(e.target.value);
  };

  const handleNFHSChange = (e) => {
    setSelectedNFHS(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        setLoadingStatus(true);
        const response = await API.fetchAnemiaSituationState({
          nfhs: selectedNFHS,
          state: selectedState,
        });

        if (response.success) {
          let mapDataCalculated = {};

          setTableData(response.data);

          response.data?.forEach((data) => {
            mapDataCalculated[data.district ? data.district : "No District"] =
              data[selectedIndicator];
          });

          setMapData(mapDataCalculated);
        } else {
          setMapData([]);
        }
      } catch (error) {
      } finally {
        setLoadingStatus(false);
      }
    };

    if (selectedNFHS && selectedState) {
      fetchMapData();
    }
  }, [selectedNFHS, selectedIndicator, selectedState]);

  useEffect(() => {
    const fetchStateListByNFHS = async () => {
      const response = await API.fetchStateListByNFHS(selectedNFHS);

      if (response.success) {
        let states = response.data.map((state) => ({
          label: state.toString(),
          value: state.toString(),
        }));

        setStatesList(states);
      }
    };

    if (selectedNFHS) {
      fetchStateListByNFHS();
    }
  }, [selectedNFHS]);

  const columnDefs = [
    {
      field: "district",
      headerName: "District",
      width: 300,
      headerClassName: "table-header",
      renderCell: (params) => {
        return (
          <div className={params.field === "district" && styles.firstColumn}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "nfhs",
      headerName: "NFHS",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "child6_59",
      headerName: "Children 6-59 Months",
      width: 220,
      headerClassName: "table-header",
    },

    {
      field: "nonPreg15_49",
      headerName: "Non-Pregnant Women",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "preg15_59",
      headerName: "Pregnant Women",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "allWomen15_49",
      headerName: "All Womens (15-49 Yrs)",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "allWomen15_19",
      headerName: "All Womens (15-19 Yrs)",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "men15_49",
      headerName: "All Mens (15-49 Yrs)",
      width: 220,
      headerClassName: "table-header",
    },
    {
      field: "men15_19",
      headerName: "All Mens (15-19 Yrs)",
      width: 220,
      headerClassName: "table-header",
    },
  ];

  return (
    <>
      <PageHeader text="Anemia Situation - State" />

      <div className={`container-fluid ${styles.situationContainer}`}>
        <div className={styles.filters}>
          <Select
            size="small"
            sx={{ width: "100%" }}
            name="Select NFHS"
            id="select-nfhs"
            value={selectedNFHS}
            onChange={handleNFHSChange}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            required
          >
            <MenuItem disabled value="">
              Select NFHS
            </MenuItem>

            {NFHSs.map((indicator) => (
              <MenuItem key={indicator.key} value={indicator.key}>
                {indicator.value}
              </MenuItem>
            ))}
          </Select>

          <Select
            size="small"
            sx={{ width: "100%" }}
            name="Select Indicator"
            id="select-indicator"
            value={selectedState}
            onChange={handleStateChange}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            required
            disabled={!selectedNFHS}
          >
            <MenuItem disabled value="">
              Select State
            </MenuItem>

            {statesList.map((state) => (
              <MenuItem key={state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>

          <Select
            size="small"
            sx={{ width: "100%" }}
            name="Select Indicator"
            id="select-indicator"
            value={selectedIndicator}
            onChange={handleIndicatorChange}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            disabled={!selectedNFHS && !selectedState}
            required
          >
            <MenuItem disabled value="">
              Select Indicator
            </MenuItem>

            {SituationIndicatorsIndia.map((indicator) => (
              <MenuItem
                key={indicator.indicatorKey}
                value={indicator.indicatorKey}
              >
                {indicator.indicatorValue}
              </MenuItem>
            ))}
          </Select>
        </div>
        {loadingStatus ? (
          <Loader loading={loadingStatus} />
        ) : (
          <div className={styles.mapContainer} style={{ padding: "10px" }}>
            <div className={styles.mapContent}>
              <StateMap
                selectedState={selectedState}
                mapDataCalculated={mapData}
              />
            </div>

            <div className={styles.mapRightContainer}>
              <div className={styles.colorScaleContainer}>
                {colorScaleLegend.map((item, index) => (
                  <div className={styles.colorScale} key={index}>
                    <div
                      className={styles.colorScaleDots}
                      style={{
                        border: "1px solid black",
                        backgroundColor: item.color,
                      }}
                    />
                    <span>{item.label}</span>
                  </div>
                ))}
              </div>

              <p>
                <b>Source:</b> National Family Health Survey (NFHS)
              </p>
            </div>
          </div>
        )}

        <SituationTable
          columns={columnDefs}
          loading={loadingStatus}
          tableData={tableData}
          getRowId={(row) => {
            return row.district;
          }}
        />
      </div>
    </>
  );
};

export default StateSituation;
