import React, { useEffect, useState } from "react";

import styles from "./ForgotPassword.module.css";

import { Button } from "@mui/material";
import { Autorenew } from "@mui/icons-material";

import PasswordInput from "utils/PasswordInput";
import ProgressSteps from "components/auth/ProgressSteps";
import API from "services/api";

function ChangePassword({ activeStep, setActiveStep, email }) {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isEqual, setIsEqual] = useState(true);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  useEffect(() => {
    if (password !== confirmPassword) {
      setIsEqual(false);
    }
  }, [password, confirmPassword]);

  const handleResetPassword = async () => {
    try {
      if (password && isEqual) {
        const response = await API.resetPassword(email, password);

        if (response.status === 200) {
          setActiveStep(4);
        }
      }
    } catch (error) {}
  };

  return (
    <div className={styles.container}>
      <div className={styles.formCard}>
        <ProgressSteps
          step={activeStep}
          steps={["Enter Email", "Verify OTP", "Reset Password", "Login"]}
        />

        <form onSubmit={handleResetPassword} className={styles.loginForm}>
          <div className={styles.formGroup}>
            <PasswordInput
              label="Password"
              name="password"
              placeholder="Enter password"
              showPassword={showPassword}
              onShowPassword={handleClickShowPassword}
              value={password}
              onChange={handlePasswordChange}
            />
          </div>

          <div className={styles.formGroup}>
            <PasswordInput
              label="Confirm Password"
              name="confirmPassword"
              placeholder="Enter confirm password"
              showPassword={showConfirmPassword}
              onShowPassword={handleClickShowConfirmPassword}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!isEqual}
            />
          </div>

          <Button
            variant="primary"
            className={styles.button}
            startIcon={<Autorenew />}
            onClick={handleResetPassword}
          >
            Reset
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
