import { useEffect, useState, useMemo } from "react";

import API from "services/api";

import "./ResourceUpdated.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import FolderZipIcon from "@mui/icons-material/FolderZip";
import Loader from "utils/Loader";

function ResourceContainer({ category }) {
  const [resources, setResources] = useState([]);
  const [groupedResources, setGroupedResources] = useState({});

  const [loading, setLoading] = useState(false);

  const memoizedGroupedResources = useMemo(() => {
    if (resources?.length > 0) {
      return resources?.reduce((acc, resource) => {
        const category = resource.category;
        const folderName = resource.folderName;

        if (!acc[category]) {
          acc[category] = {};
        }

        if (!acc[category][folderName]) {
          acc[category][folderName] = [];
        }

        acc[category][folderName].push(resource);

        return acc;
      }, {});
    }
    return {};
  }, [resources]);

  useEffect(() => {
    async function fetchAllResources() {
      try {
        if (category) {
          setLoading(true);
          const resourcesList = await API.getAllResourcesByCategory(category);

          setResources(resourcesList);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    fetchAllResources();
  }, [category]);

  useEffect(() => {
    setGroupedResources(memoizedGroupedResources);
  }, [memoizedGroupedResources]);

  const handleIncreaseResourceDownload = async (
    category,
    folderName,
    fileName
  ) => {
    try {
      await API.increaseResourceDownloadCount(category, folderName, fileName);
    } catch (error) {}
  };

  return (
    <>
      <div className="mb-5 p-2">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div>
            {groupedResources &&
              Object?.entries(groupedResources)?.map(
                ([category, folderObj]) => (
                  <div key={category}>
                    <h5 className="tab-heading" id={category}>
                      {category}
                    </h5>

                    <div className="category-card-container">
                      {folderObj &&
                        Object?.entries(folderObj)?.map(
                          ([folderName, resources]) => (
                            <div key={folderName}>
                              <div className="tab-container-heading">
                                {folderName}
                              </div>
                              <div className="card-container-resources-v2">
                                {resources &&
                                  resources?.map((resource) => {
                                    const extension = resource?.fileType;
                                    const fileName = resource?.fileName;
                                    const fileUrl = resource?.fileUrl;

                                    const fileNameWithoutExtension =
                                      fileName.substring(
                                        0,
                                        fileName.lastIndexOf(".")
                                      );

                                    return (
                                      <div
                                        className="tab-container-card"
                                        key={resource?.resId}
                                      >
                                        <div className="tab-card-top">
                                          {fileNameWithoutExtension}
                                        </div>
                                        <div className="tab-card-body">
                                          <a
                                            download={fileName}
                                            href={fileUrl}
                                            title={fileNameWithoutExtension}
                                            onClick={() =>
                                              handleIncreaseResourceDownload(
                                                category,
                                                folderName,
                                                fileName
                                              )
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {extension === "mp3" ||
                                            extension === "aac" ||
                                            extension === "wav" ||
                                            extension === "m4a" ? (
                                              <img
                                                className="resources-file-icon"
                                                src="https://res.cloudinary.com/daxilgrvn/image/upload/v1695700005/audio-2127820_1920_mdilwx.png"
                                                alt="audio-file-icon"
                                              />
                                            ) : extension === "zip" ? (
                                              <FolderZipIcon className="resources-file-icon" />
                                            ) : (
                                              <img
                                                className="resources-file-icon"
                                                src={`data:image/png;base64,${resource.fileIcon}`}
                                                alt="file-icon"
                                              />
                                            )}
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                )
              )}
          </div>
        )}
      </div>
    </>
  );
}

export default ResourceContainer;
