import React, { useState } from "react";
import MapChart from "components/Map/MapChart";
import MapDialog from "components/Map/MapDialog";

import styles from "components/Map/map.module.css";
import Loader from "utils/Loader";

const DataIndiaMap = ({
  mapDataCalculated,
  mapDataCalculatedDistricts,
  queryData,
  loadingStatus,
}) => {
  const [STName, setSTName] = useState("India");
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ coordinates: [80, 22], zoom: 1 });

  const handleClose = () => setShow(!show);

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  const colorScale = [
    { color: "#d3d3d380", label: "NA" },
    { color: "#105bab", label: "0-24.9%" },
    { color: "#40c8f4", label: "25%-49.9%" },
    { color: "#ffe06a", label: "50%-74.9%" },
    { color: "#60bb46", label: "75%-94.9%" },
    { color: "#333333", label: ">= 95% (Normalized)" },
  ];

  return (
    <>
      <div className={styles.mapSuperContainer}>
        <div className={styles.visualDataTitle}>
          Visualization for All States (and their Districts) of India
        </div>

        {loadingStatus ? (
          <Loader loading={loadingStatus} />
        ) : (
          <div className={styles.mapContainer} style={{ padding: "10px" }}>
            <div className={styles.mapWithControl}>
              <div className={styles.controls}>
                <button onClick={handleZoomIn}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <line x1="12" y1="5" x2="12" y2="19" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                </button>
                <button onClick={handleZoomOut}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                </button>
              </div>

              <MapChart
                setStateName={setSTName}
                setShowDistrict={setShow}
                setPosition={setPosition}
                position={position}
                mapDataCalculated={mapDataCalculated}
                queryData={queryData}
              />
            </div>
            <div
              className={
                show ? styles.mapContainerRightShow : styles.mapContainerRight
              }
            >
              <div className={styles.districtMapContainer}>
                {show && (
                  <MapDialog
                    show={show}
                    StateName={STName}
                    closeModal={handleClose}
                    queryData={queryData}
                    mapDataCalculatedDistricts={mapDataCalculatedDistricts}
                  />
                )}
              </div>

              <div className={styles.colorScaleContainer}>
                {colorScale.map((item, index) => (
                  <div className={styles.colorScale} key={index}>
                    <div
                      className={styles.colorScaleDots}
                      style={{
                        backgroundColor: item.color,
                      }}
                    />
                    <span>{item.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DataIndiaMap;
