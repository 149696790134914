import React, { useState } from "react";

import dayjs from "dayjs";
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FolderDeleteRoundedIcon from "@mui/icons-material/FolderDeleteRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

import SnackBar from "utils/SnackBar";
import SelectYear from "utils/SelectYear";

import API from "services/api";

import AdminPanelLayout from "components/admin/AdminPanelLayout";
import styles from "./deleteUploads.module.css";

function DeleteExcelUploads() {
  const [selectedYear, setSelectedYear] = useState(dayjs().format("YYYY"));

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleDateChange = (newValue) => {
    const year = parseInt(newValue.format("YYYY"));
    setSelectedYear(year);
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      const response = await API.deleteUploads(selectedYear);
      if (response.statusCode === 200) {
        setShow(true);
        setMessage({
          text: `All uploaded data for year: ${selectedYear} are deleted successfully!`,
          type: "success",
        });
      } else {
        setShow(true);
        setMessage({
          text: "Couldn't delete file! Something went wrong...",
          type: "error",
        });
      }
    } catch (error) {
      setShow(true);
      setMessage({
        text: "Couldn't delete file! Something went wrong...",
        type: "error",
      });
    }
  };

  return (
    <AdminPanelLayout>
      <SnackBar
        message={message?.text}
        open={show}
        handleClose={handleClose}
        type={message?.type}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.row}>
          <h6 className={styles.subHeading}>Delete Uploaded Excel Sheets</h6>
          <form onSubmit={handleDelete} className="form-dashboard">
            <div className={styles.calendarRow}>
              <label> Select Year</label>
              <SelectYear onYearChange={handleDateChange} />
            </div>

            <div className={styles.button}>
              <Button
                variant="contained"
                startIcon={<FolderDeleteRoundedIcon />}
                onClick={handleDelete}
                style={{ backgroundColor: "#a52a2a" }}
                sx={{ width: { xs: "100%" } }}
                disabled={!selectedYear}
                className={
                  !selectedYear ? styles.disabledButton : styles.button
                }>
                Delete
              </Button>
            </div>

            <p className={styles.infoText}>
              <InfoRoundedIcon style={{ color: "#a52a2a" }} /> On deleting it
              will remove {selectedYear}'s Denominator, Numerator Excel and Drug
              Requirements Excel
            </p>
          </form>
        </div>
      </LocalizationProvider>
    </AdminPanelLayout>
  );
}

export default DeleteExcelUploads;
