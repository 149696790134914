import React from "react";
import styles from "./DataTable.module.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "components/ranking/pdf-generator/PDFTemplate";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";

const headers = [
  { label: "Location", key: "stateDistrict" },
  {
    label:
      "% of children 6-59 months provided 8-10 doses (1ml) of Iron and Folic Acid (IFA) syrup",
    key: "months6_59IfaPercentO",
  },
  {
    label:
      "% of children cove#069edb under WIFS JUNIOR (5-9 years) provided 4-5 Pink-Blue IFA tablets (In schools + out of school)",
    key: "years6_9IfaPercentP",
  },
  {
    label:
      "% of Adolescents (6-12 class) provided 4 Pink-Blue IFA tablets in schools",
    key: "adols10_15IfaPercentH",
  },
  {
    label:
      "% of pregnant women (PW) given 180 Pink-Blue IFA tablets",
    key: "pregWomenIfaPercentC",
  },
  {
    label: "% of mothers given Pink-Blue IFA tablets",
    key: "motherIfaPercentF",
  },
];

const KPIHeader = ({
  queryData,
  months,
  quarters,
  dataAsOnDate,
  kpiDataTable,
  dataName,
  currentComponent,
}) => (
  <>
    <div className={styles.headerTableSection}>
      AMB Performance Indicators - {queryData?.stateCountry}
      <br />
      {queryData?.year &&
        `(${
          queryData?.month
            ? months[queryData.month - 1]
            : quarters[queryData.quarter - 1]
        } ${queryData.year})`}
      <p className={styles.dataAsOnDate}>Data as on {dataAsOnDate}*</p>
    </div>

    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: 10,
      }}
    >
      {kpiDataTable && kpiDataTable?.length !== 0 && (
        <>
          <PDFDownloadLink
            document={
              <PdfDocument
                dataAsOnDate={dataAsOnDate}
                dataName={dataName}
                kpiDataTable={kpiDataTable}
                queryData={queryData}
              />
            }
            fileName={
              currentComponent === "KPI_Quarterly"
                ? `AMB KPI Score Card (Quarterly) - ${queryData?.stateCountry}_${queryData?.startYear}-${queryData?.endYear}.pdf`
                : `AMB KPI Score Card (Monthly) - ${queryData?.stateCountry}_${queryData?.year}.pdf`
            }
            style={{
              textDecoration: "none",
              padding: 5,
              fontSize: 16,
              textAlign: "right",
              alignSelf: "flex-end",
              color: "#069edb",
              fontWeight: "bold",
              backgroundColor: "transparent",
            }}
          >
            {({ loading }) => (
              <Button
                style={{ borderColor: "#105bab", color: "#069edb" }}
                startIcon={<PictureAsPdfRoundedIcon />}
                variant="outlined"
              >
                {loading ? "Loading PDF..." : "Download PDF"}
              </Button>
            )}
          </PDFDownloadLink>
          <CSVLink
            data={kpiDataTable}
            headers={headers}
            filename={
              currentComponent === "KPI_Quarterly"
                ? `AMB KPI Score Card (Quarterly) - ${queryData?.stateCountry}_${queryData?.startYear}-${queryData?.endYear}.csv`
                : `AMB KPI Score Card (Monthly) - ${queryData?.stateCountry}_${queryData?.year}.csv`
            }
          >
            <Button
              startIcon={<AttachFileRoundedIcon />}
              style={{ borderColor: "#105bab", color: "#069edb" }}
              variant="outlined"
            >
              Export CSV
            </Button>
          </CSVLink>
        </>
      )}
    </div>
  </>
);

export default KPIHeader;
