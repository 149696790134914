import * as React from "react";

import { Box, AppBar, Toolbar, Typography } from "@mui/material";

import {
  MenuRounded as MenuRoundedIcon,
  AccountBoxRounded as AccountBoxRoundedIcon,
} from "@mui/icons-material";

import { IconButton, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const drawerWidth = 260;

function CustomAppBar({ onDrawerToggle, onOpenMenu, onClose, anchorEl, open }) {
  const username = localStorage.getItem("username");

  const navigate = useNavigate();
  return (
    <AppBar
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: "#069edb",
      }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}>
          <MenuRoundedIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
          Anemia Mukt Bharat
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={onOpenMenu}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit">
            <AccountBoxRoundedIcon sx={{ fontSize: 28 }} />
          </IconButton>

          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              flexGrow: 1,
              display: "block",
              textTransform: "capitalize",
            }}>
            {username}
          </Typography>
        </Box>
      </Toolbar>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <MenuItem
          onClick={() => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("username");
            navigate("/auth/login");
          }}>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
}

export default CustomAppBar;
