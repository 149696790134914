import { Button } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import React from "react";

const DenominatorsTable = ({ tableData, downloadURL, loading, columnDefs }) => {
  function CustomToolbar() {
      return (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <small>*figures in lakhs</small>

              <Button
                  variant="contained"
                  className="mb-2"
                  style={{ textAlign: 'left' }} // Align the button text to the left
                  onClick={() => (window.location.href = `https://${downloadURL}`)}
              >
                  Download Data
              </Button>
          </div>
      );

  }

  return (
    <DataGrid
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      getRowId={(row) => {
        return row.location;
      }}
      sx={{
        width: "100%",
        "& .table-header": {
          height: "max-content",
          backgroundColor: "#069edb",
        },
      }}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      loading={loading}
      rows={tableData}
      columns={columnDefs}
      pageSize={10}
      slots={{
        toolbar: CustomToolbar,
      }}
      isRowSelectable={false}
    />
  );
};

export default DenominatorsTable;
